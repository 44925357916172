import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { withStyles, Card, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { login, guestUserLoggedOut } from 'actions/actions';
import TextInput from 'components/TextInput';
import Text from 'components/Text';
import Contained_Button from 'components/Contained_Button';
import Theme from 'resources/Theme';
import { closeLoginModal } from 'actions/loginModal_actions';
import IMAGES from 'resources/images';
const logo = '/assets/images/logo.svg';
const cover = '/assets/images/cover.png';

class LoginModal extends Component {
	state = {
		form_data: {
			username: '',
			password: ''
		}
	};

	handle_change = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		const { form_data } = this.state;

		// if (name === 'username') {
		// 	let format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// 	this.state.emailCondition = format.test(_.trim(value));
		// 	form_data[name] = _.trim(value);
		// }

		// if (name === 'password') {
		// 	form_data[name] = value;
		// 	this.state.passwordCondition = _.isEmpty(value) ? false : true;
		// }

		this.setState({
			form_data: { ...form_data, [name]: value }
		});
	};

	handle_login = () => {
		this.props.login({ ...this.state.form_data, modal: true });
	};

	render () {
		const { classes } = this.props;
		const { form_data } = this.state;
		return (
			<div className={classes.container}>
				<Card className={classes.card} elevation={0}>
					<div style={{ marginTop: 20, marginBottom: 30, paddingInline: 20 }}>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							{' '}
							To continue...{' '}
							<button
								style={{ height: 25, width: 25, border: 0, backgroundColor: 'transparent' }}
								onClick={() => this.props.closeModal()}>
								<img src={IMAGES.closes_icon} style={{ width: 18 }}></img>
							</button>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 4 }}>
							<span>
								<Text style={{ fontSize: 16 }}>Log In to</Text>
							</span>
							<img src={logo} style={{ height: Theme.font_size.H5, paddingInline: 6 }} loading='lazy' />
						</div>
					</div>
					<ValidatorForm onSubmit={this.handle_login}>
						<TextInput
							type='email'
							label='Email'
							value={form_data.username}
							onChange={this.handle_change}
							name='username'
							validators={['required']}
							style={{ paddingInline: 20 }}
						/>
						<TextInput
							type='password'
							label='Password'
							value={form_data.password}
							onChange={this.handle_change}
							name='password'
							validators={['required']}
							style={{ paddingInline: 20, marginTop: 20 }}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: 25,
								marginBottom: 15
							}}></div>

						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
							<Link
								to={'/signup'}
								onClick={() => {
									this.props.closeModal();
									this.props.logoutGuest();
								}}
								style={{
									display: 'flex',
									width: 120,
									marginLeft: 20,
									backgroundColor: 'black',
									color: 'white',
									borderRadius: 20,
									fontSize: 14,
									height: 40,
									alignItems: 'center',
									justifyContent: 'center'
								}}>
								Sign up
							</Link>
							<Contained_Button
								color='primary'
								type='submit'
								style={{ width: 120, marginRight: 20 }}
								onClick={() => {
									this.handle_login();
								}}>
								Log in
							</Contained_Button>
						</div>
					</ValidatorForm>
					<Link
						to='/policy'
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginBottom: 30,
							fontSize: 13,
							fontWeight: '400',
							color: 'grey'
						}}>
						Having trouble loggin in?{' '}
						<Link to={'/policy'} style={{ color: 'red', paddingInline: 5 }}>
							<u>Get help</u>
						</Link>
					</Link>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	login: (form_data) => dispatch(login(form_data)),
	closeModal: () => dispatch(closeLoginModal()),
	logoutGuest: () => dispatch(guestUserLoggedOut())
});

const STYLES = (theme) => ({
	container: {
		height: '100%',
		width: '100%',
		alignItems: 'center',
		position: 'fixed',
		justifyContent: 'space-between',
		flexDirection: 'column',
		background: 'rgba(0, 0, 0, 0.6)'
	},
	card: {
		width: 330,
		height: 440,
		borderRadius: 20,
		margin: '0 auto',
		[theme.breakpoints.down('xs')]: {
			width: '90%'
		},
		boxShadow: '2px 1px 14px',
		left: '50%',
		marginTop: '25%',
		zIndex: 1000
	}
});

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(LoginModal));
