import React from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter, Redirect, BrowserRouter, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import _ from 'lodash';
import utils from 'resources/utils';
import Theme from 'resources/Theme';
import Alert_Dialog from 'components/Alert_Dialog';
import Loader from 'components/Loader';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Qa_Env_Header from 'components/Qa_Env_Header';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/index';
import Feed from 'screens/Feed/Feed';
import App_With_Bottom_Menu from 'screens/App_With_Bottom_Menu';
import Signup from 'screens/Auth/Signup';
import Terms from 'screens/termsPolicy/terms';
import Policy from 'screens/termsPolicy/privacyPolicy';
import { get_user_details, user_info_received, guestUserLoggedOut, guestUserLoggedIn } from 'actions/actions';
import Auth from 'screens/Auth/Auth';
import Second_Tab from 'screens/Second_Tab/Second_Tab';
import LoginModal from 'screens/Auth/loginModalComponent/modal';
import SharePopup from 'components/sharePopup';
const env = utils.ENVIRONMENT;
const middleware = env === utils.env.PRODUCTION ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);
export const store = createStore(rootReducer, middleware);

if (env === utils.env.PRODUCTION) {
	console.log = () => {};
}

class PrimaryLayout extends React.Component {
	constructor (props) {
		super(props);
		if (utils.isAuthenticated()) {
			this.props.get_user_details();
		} else {
			this.props.user_info_received(null);
		}
	}
	componentDidMount () {
		const token = utils.isAuthenticated();
		console.log(token, 'token');
		if (token) {
			this.props.guestUserLoggedOut();
		} else {
			this.props.guestUserLoggedIn();
		}
	}
	render () {
		const { loader, user_info, modalState, guestUser } = this.props;

		return (
			<MuiThemeProvider theme={Theme}>
				<Alert_Dialog />

				<div className='page-container'>
					<Qa_Env_Header />
					<Switch>
						<Route path='/signup' exact component={Signup} />
						<Route path='/terms' exact component={Terms} />
						<Route path='/policy' exact component={Policy} />
						<Route path='/' component={user_info === null && guestUser === false ? Auth : App_With_Bottom_Menu} />
						<Redirect to='/' />
					</Switch>
				</div>
				{loader && <Loader />}
				{modalState && <LoginModal />}
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = (state) => {
	const modalState = state.LoginModal;
	return { ..._.pick(state.App, ['loader', 'user_info', 'guestUser']), modalState };
};
const mapDispatchToProps = {
	get_user_details,
	user_info_received,
	guestUserLoggedOut,
	guestUserLoggedIn
};

const PrimaryComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrimaryLayout));

class App extends React.Component {
	render () {
		return (
			<BrowserRouter>
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<PrimaryComponent {...this.props} />
					</MuiPickersUtilsProvider>
				</Provider>
			</BrowserRouter>
		);
	}
}
export default App;
