import ReactGA from 'react-ga';
ReactGA.initialize('UA-221444152-1', {
	titleCase: false
});

const PageTrackingWithPath = (path) => {
	const pathName = path;
	console.log('hitting the media');
	let pageView;
	if (pathName === '*') pageView = '/not-found';
	else pageView = pathName;
	console.log(pathName);
	ReactGA.pageview(pageView);
};

export default PageTrackingWithPath;
