import _ from 'lodash';
import api_requests from 'resources/api_requests';
import {
	CLEAR_RECOMMENDATION_DATA,
	EXPLORE_DATA_RECEIVED,
	FILTER_RESULTS_RECEIVED,
	LOADING_MOVIES,
	PREFERRED_PROVIDERS_UPDATED,
	RECOMMENDATION_DATA_RECEIVED,
	SET_MOVIES,
	LOADING_SHOWS,
	SET_SHOWS,
	UPDATE_EXPLORE_LIST
} from 'resources/redux_constants';
import { toggle_loader } from './actions';
import utils from 'resources/utils';

export const filterResultReceived = (filterResults) => ({
	type: FILTER_RESULTS_RECEIVED,
	filterResults
});

const providersUpdated = (providers) => ({
	type: PREFERRED_PROVIDERS_UPDATED,
	providers
});

const recommendation_data_received = ({ chef_special_shows, chef_special_movies, genres, providers, age_ratings }) => ({
	type: RECOMMENDATION_DATA_RECEIVED,
	chef_special_shows,
	chef_special_movies,
	providers,
	genres,
	age_ratings
});

const explore_date_received = (exploreList) => ({
	type: EXPLORE_DATA_RECEIVED,
	exploreList
});

const update_explore_list = (exploreList) => {
	return {
		type: UPDATE_EXPLORE_LIST,
		exploreList
	};
};

const getFilters = (state) => {
	const preferred_providers = _.get(state, 'App.user_info.preferred_providers');
	let filters;
	if (preferred_providers && preferred_providers.length) {
		filters = [
			{
				bool: {
					should: _.map(preferred_providers, (v) => {
						return {
							match_phrase: {
								'watch_providers._id': v._id
							}
						};
					})
				}
			}
		];
	}

	return filters;
};

export const getMovies = (from) => {
	return (dispatch, getState) => {
		dispatch({
			type: LOADING_MOVIES,
			value: true
		});
		const filters = getFilters(getState());

		api_requests
			.multiquery({
				queries: [
					{
						queryType: 'search',
						params: {
							_index: 'movies',
							joins: {
								poster_path: 1,
								title: 1,
								vote_average: 1,

								runtime: 1,
								genres: {
									name: 1
								}
							},
							_type: 'movie',
							size: 10,
							sort: {
								popularity: 'desc'
							},
							filters: filters,
							from
						}
					}
				]
			})
			.then((res) => {
				dispatch({
					type: SET_MOVIES,
					movies: _.get(res.data, 'response[0].hits.hits', []),
					total: _.get(res.data, 'response[0].hits.total.value')
				});

				dispatch({
					type: LOADING_MOVIES,
					value: false
				});
			});
	};
};

export const getShows = (from) => {
	return (dispatch, getState) => {
		dispatch({
			type: LOADING_SHOWS,
			value: true
		});
		const filters = getFilters(getState());

		api_requests
			.multiquery({
				queries: [
					{
						queryType: 'search',
						params: {
							_index: 'tv-shows',
							joins: {
								poster_path: 1,
								title: 1,
								vote_average: 1
							},
							_type: 'tv-show',
							size: 10,
							from,
							sort: {
								popularity: 'desc'
							},
							filters: filters
						}
					}
				]
			})
			.then((res) => {
				dispatch({
					type: SET_SHOWS,
					shows: _.get(res.data, 'response[0].hits.hits', []),
					total: _.get(res.data, 'response[0].hits.total.value')
				});

				dispatch({
					type: LOADING_SHOWS,
					value: false
				});
			});
	};
};

export const getRecommendationData = () => (dispatch, getState) => {
	dispatch(toggle_loader());

	const preferred_providers = _.get(getState(), 'App.user_info.preferred_providers');
	let filters;
	if (preferred_providers && preferred_providers.length) {
		filters = [
			{
				bool: {
					should: _.map(preferred_providers, (v) => {
						return {
							match_phrase: {
								'watch_providers._id': v._id
							}
						};
					})
				}
			}
		];
	}
	api_requests
		.multiquery({
			queries: [
				{
					queryType: 'search',
					params: {
						_index: 'tv-shows',
						joins: {
							poster_path: 1,
							title: 1,
							vote_average: 1
						},
						_type: 'tv-show',
						size: 10,
						sort: {
							popularity: 'desc'
						},
						filters: filters
					}
				},
				{
					queryType: 'search',
					params: {
						_index: 'movies',
						joins: {
							poster_path: 1,
							title: 1,
							vote_average: 1,

							runtime: 1,
							genres: {
								name: 1
							}
						},
						_type: 'movie',
						size: 10,
						sort: {
							popularity: 'desc'
						},
						filters: filters
					}
				},
				{
					queryType: 'search',
					params: {
						_index: 'providers',
						_type: 'provider',
						joins: {
							provider_name: 1,
							logo_path: 1
						},
						size: 50
					}
				},
				{
					queryType: 'search',
					params: {
						_type: 'genre',
						joins: null,
						size: 20
					}
				},
				{
					queryType: 'search',
					params: {
						_index: 'certifications',
						_type: 'certification',
						size: 50,
						query: {
							match_phrase: {
								countryISO: 'IN'
							}
						}
					}
				}
			]
		})
		.then((res) => {
			console.log(_.get(res.data, 'response[3].hits.hits', []), 'genressss');
			dispatch(
				recommendation_data_received({
					chef_special_shows: _.get(res.data, 'response[0].hits.hits', []),
					chef_special_movies: _.get(res.data, 'response[1].hits.hits', []),
					providers: _.get(res.data, 'response[2].hits.hits', []),
					genres: _.get(res.data, 'response[3].hits.hits', []),
					age_ratings: _.get(res.data, 'response[4].hits.hits', [])
				})
			);
			dispatch(toggle_loader());
		});
};

const getMediaType = (genre) => {
	const genreSplit = genre.split(' ');
	const genreName = genreSplit.slice(0, genreSplit.length - 1).join(' ');
	const mediaType = genreSplit.slice(genreSplit.length - 1)[0];
	const sanitizeMediaType = mediaType.slice(0, mediaType.length - 1).toLowerCase();

	return {
		type: sanitizeMediaType,
		name: genreName
	};
};

export const getGenreData = (sortBy, genre = '', collectionType) => {
	return (dispatch, getState) => {
		const state = getState();
		const preferredProviders = _.get(state, 'App.user_info.preferred_providers');
		const currentItems = _.get(state, `Recommendation.exploreList.${genre || collectionType}`);
		const currentItemsLength = currentItems.items.length;
		const { name, type } = getMediaType(genre || collectionType);

		let queries = null;
		if (genre) {
			const genreObject = state.Recommendation.genres.find((val) => {
				return _.get(val, '_source.name') === name;
			});

			queries = genreWiseQueriesForMediaType(
				type === 'movie' ? 'movie' : type,
				[genreObject],
				sortBy,
				preferredProviders,
				currentItemsLength
			);
		} else {
			queries = mediaQuery(
				[type === 'movie' ? 'movie' : type],
				name === 'Trending' ? 'trending-now' : 'now-playing',
				sortBy,
				preferredProviders,
				currentItemsLength
			);
		}

		api_requests
			.multiquery(
				{
					queries
				},
				false
			)
			.then((res) => {
				const exploreList = {};
				_.map(_.get(res.data, 'response', []), (v, i) => {
					if (v.hits.hits) {
						const mediaType = v.hits.hits[0]._type;
						exploreList[`${name} ${_.capitalize(mediaType === 'movie' ? 'movie' : mediaType)}s`] = {
							items: [...currentItems.items, ...v.hits.hits],
							total: v.hits.total.value
						};
					}
				});

				dispatch(update_explore_list(exploreList));
			})
			.catch((err) => {
				console.log(err, '===> Error');
			});
	};
};

export const getExploreData = (sortBy, mediaTypes, selectedLanguages) => (dispatch, getState) => {
	const genres = getState().Recommendation.genres;
	console.log(genres);
	const preferred_providers = _.get(getState(), 'App.user_info.preferred_providers');
	const genreWiseQueries = genreWiseMediaTypesQueries(mediaTypes, genres, sortBy, preferred_providers);
	const queries = [
		...mediaQuery(mediaTypes, 'trending-now', sortBy, preferred_providers),
		...mediaQuery(mediaTypes, 'now-playing', sortBy, preferred_providers),
		...genreWiseQueries
	];
	const nonGenreQueriesCount = queries.length - genreWiseQueries.length;

	const provider = preferred_providers?.map((val, index) => val._id);
	api_requests
		.get_recommendation({
			mediaTypes: ['movie', 'tv-show'],

			languages: selectedLanguages,
			preferred_providers: provider,
			genres: [28, 12, 16, 35, 80, 99, 18, 10751, 14, 36, 27, 10402, 10402, 9648, 10749, 878, 10770, 53, 10752, 37],
			sortBy: 'popularity',
			joins: {
				movie: {
					title: 1,
					vote_average: 1,
					poster_path: 1,
					runtime: 1,
					'watch-list': {
						_id: 1,
						_where: {
							'watched-movies._id': '*movie._id',
							_id: utils._retrieveData('id')
						}
					},

					genres: {
						name: 1
					}
				},
				'tv-show': {
					title: 1,
					vote_average: 1,
					poster_path: 1,
					runtime: 1,
					'watch-list': {
						_id: 1,
						_where: {
							'watched-tv-shows._id': '*tv-show._id',
							_id: utils._retrieveData('id')
						}
					},

					genres: {
						name: 1
					}
				}
			}
		})
		.then((res) => {
			const finalLists = [];
			const uniqueIds = {};
			const exploreList = {};
			//First process trending and now playing lists
			const nonGenreHits = res.data.response.splice(0, nonGenreQueriesCount);
			let i;
			for (i = 0; i < mediaTypes.length; i++) {
				const mediaType = mediaTypes[i] === 'movie' ? _.capitalize('movie') : _.capitalize(mediaTypes[i]);

				exploreList[`Trending ${mediaType}s`] = {
					items: filterResults(nonGenreHits[i].hits.hits, uniqueIds),
					total: nonGenreHits[i].hits.total.value
				};
			}
			//In theater has only movie
			if (mediaTypes.includes('movie')) {
				exploreList[`In theater Movies`] = {
					items: filterResults(nonGenreHits[i].hits.hits, uniqueIds),
					total: nonGenreHits[i].hits.total.value
				};
			}
			//Now handle responses for every genre
			//Don't include empty responses
			_.map(_.get(res.data, 'response', []), (v, i) => {
				const hits = filterResults(v?.hits?.hits, uniqueIds);
				if (hits?.length) {
					const mediaType = v.hits?.hits[0]._type;
					exploreList[
						`${_.get(genres, `[${i % genres.length}]._source.name`)} ${_.capitalize(
							mediaType === 'movie' ? 'movie' : mediaType
						)}s`
					] = {
						items: hits,
						total: v.hits?.total.value
					};
				}
			});

			dispatch(explore_date_received(exploreList));
		});
};
const filterResults = (hits, soFar) => {
	return hits?.filter((h) => {
		if (soFar[h._id]) {
			return false;
		} else {
			soFar[h._id] = 1;
			return true;
		}
	});
};
//A single query to return trending content for given mediaTypes
const mediaQuery = (mediaTypes, collectionType, sortBy, preferred_providers, from) => {
	const queries = [];
	for (let mediaType of mediaTypes) {
		if (mediaType !== 'movie' && collectionType === 'now-playing') {
			continue;
		}
		const query = {
			queryType: 'search',
			params: {
				_type: mediaType,
				size: 10,
				from: from || 0,
				query: {
					exists: {
						field: `${collectionType}._id`
					}
				},
				sort: {
					[sortBy]: 'desc'
				},
				joins: {
					title: 1,
					vote_average: 1,
					poster_path: 1,
					runtime: 1,
					genres: {
						name: 1
					}
				},
				filters: preferred_providers &&
					preferred_providers.length && [
						{
							bool: {
								should: _.map(preferred_providers, (v) => {
									return {
										match_phrase: {
											'watch_providers._id': v._id
										}
									};
								})
							}
						}
					]
			}
		};
		queries.push(query);
	}
	return queries;
};
const genreWiseMediaTypesQueries = (mediaTypes, genres, sortBy, preferred_providers) => {
	const queries = mediaTypes.map((mediaType) => genreWiseQueriesForMediaType(mediaType, genres, sortBy, preferred_providers));
	return _.flatten(queries);
};
const genreWiseQueriesForMediaType = (mediaType, genres = [], sortBy, preferred_providers, from) => {
	return genres.map((genre) => ({
		queryType: 'search',
		params: {
			_index: mediaType + 's',
			_type: mediaType,
			query: {
				match_phrase: {
					'genres._id': genre._id
				}
			},
			joins: {
				title: 1,
				vote_average: 1,
				poster_path: 1
			},
			sort: {
				[sortBy]: 'desc'
			},
			filters: preferred_providers &&
				preferred_providers.length && [
					{
						bool: {
							should: _.map(preferred_providers, (v) => {
								return {
									match_phrase: {
										'watch_providers._id': v._id
									}
								};
							})
						}
					}
				],
			size: 10,
			from: from || 0
		}
	}));
};
export const updateProviders = (providers) => (dispatch, getState) => {
	const state = getState();
	dispatch(toggle_loader());

	api_requests
		.updateQuery({
			_type: 'auth_user',
			_id: state.App.user_info.username,
			update: {
				set: {
					preferred_providers: _.map(providers, (v) => {
						return { _id: v._id };
					})
				}
			}
		})
		.then((res) => {
			dispatch(providersUpdated(_.get(res, 'data.response._source.preferred_providers', [])));
			dispatch(toggle_loader());
		});
};

export const filter_results = ({ releaseYear, rating, selectedGenres, selectedCertifications, selectedLanguages }) => (
	dispatch,
	getState
) => {
	dispatch(toggle_loader());

	const preferred_providers = _.get(getState(), 'App.user_info.preferred_providers');

	api_requests
		.multiquery({
			queries: [
				{
					queryType: 'search',
					params: {
						_index: 'movies',
						_type: 'movie',
						size: 10,
						sort: [
							{
								popularity: 'desc'
							}
						],
						joins: {
							title: 1,
							vote_average: 1,
							poster_path: 1,
							runtime: 1,
							genres: {
								name: 1
							}
						},
						filters: [
							{
								range: {
									release_date: {
										gte: `${releaseYear[0]}-01-01`,
										lte: `${releaseYear[1]}-12-12`
									}
								}
							},
							{
								range: {
									vote_average: {
										gte: rating
									}
								}
							},
							{
								bool: {
									should: _.map(selectedGenres, (v) => {
										return {
											match_phrase: {
												'genres._id': v
											}
										};
									})
								}
							},
							{
								bool: {
									should: _.map(selectedCertifications, (v) => {
										return {
											match_phrase: {
												'certification._id': v
											}
										};
									})
								}
							},
							{
								bool: {
									should: _.map(preferred_providers, (v) => {
										return {
											match_phrase: {
												'watch_providers._id': v._id
											}
										};
									})
								}
							},
							{
								bool: {
									should: _.map(selectedLanguages, (v) => {
										return {
											match_phrase: {
												original_language: v
											}
										};
									})
								}
							}
						]
					}
				}
			]
		})
		.then((res) => {
			dispatch(filterResultReceived(_.get(res.data, 'response[0].hits.hits')));
			dispatch(toggle_loader());
		});
};

export const clear_recommendation_data = () => ({
	type: CLEAR_RECOMMENDATION_DATA
});

// es.deep.link({
//     e1: {
//       _type: 'user',
//       _id: '0bd8146a5e33ab62590c52acc377f53ea026a197'
//     },
//     e2: {
//       _type: 'provider',
//       _id: '1'
//     },
//     isOwn: true,
//     e1ToE2Relation: 'preferred_providers',
//   })
//   .then((res) => {
//   	debug(JSON.stringify(res))
//     return es.dsl.execute('get user 0bd8146a5e33ab62590c52acc377f53ea026a197 as user')
//   })
