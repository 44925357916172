import React from 'react';
import Text from 'components/Text';
import { Drawer, ListItem, Slider } from '@material-ui/core';
import Theme from 'resources/Theme';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { filter_results } from 'actions/recommendation_actions';

const Filter = ({ anchor, handleClose,handleSelectLanguage }) => {
	const [releaseYear, setReleaseYear] = React.useState([1920, 2015]);
	const [rating, setRating] = React.useState(7);
	const [selectedGenres, setSelectedGenres] = React.useState([]);
	const [selectedCertifications, setSelectedCertifications] = React.useState([]);
    const [selectedLanguages,setSelectedLanguages] = React.useState([])
	const dispatch = useDispatch();

	const { genres, age_ratings } = useSelector((state) => ({
		..._.pick(state.Recommendation, ['genres', 'age_ratings'])
	}));

	const handleRatingChange = (event, newValue) => {
		setRating(newValue);
	};

	const handleReleaseYearChange = (event, newValue) => {
		setReleaseYear(newValue);
	};

	const handleSubmitFilter = () => {
		handleClose();
		dispatch(filter_results({ releaseYear,  rating,selectedGenres,selectedLanguages }));
	};

	const clear_filter = () => {
		setSelectedGenres([]);
		setSelectedCertifications([]);
		setRating(7);
		setReleaseYear([1920, 2015]);
		setSelectedLanguages([])
		handleSelectLanguage([])

	};

	const handleClickGenre = (id) => {
		const genres = [...selectedGenres];
		const index = _.indexOf(genres, id);

		if (index > -1) {
			genres.splice(index, 1);
			setSelectedGenres(genres);
		} else {
			setSelectedGenres([...genres, id]);
		}
	};

	const handleClickAgeRating = (id) => {
		const certification = [...selectedCertifications];
		const index = _.indexOf(certification, id);

		if (index > -1) {
			certification.splice(index, 1);
			setSelectedCertifications(certification);
		} else {
			setSelectedCertifications([...certification, id]);
		}
	};
	const handleClickLanguage =(language,selected) =>{
         if(selected){
			 const newSelected = selectedLanguages.filter((v) => language !== v)
			 
			 setSelectedLanguages(newSelected)
			 handleSelectLanguage(newSelected)
		 }else{
			 const newSelected = [...selectedLanguages,language]
			 setSelectedLanguages(newSelected)
			 handleSelectLanguage(newSelected)
		 }
	}

	return (
		<Drawer anchor={'left'} open={anchor} onClose={handleClose} PaperProps={{ style: { width: 250 } }}>
			<div
				style={{
					background: 'white',
					height: '100%',
					width: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					paddingTop: 10,
					overflow: 'scroll'
				}}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '90%',
						margin: '0 auto',
						marginTop: 10,
						marginBottom: 10
					}}>
					<Text semi>Filters</Text>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text
							semi
							style={{ fontSize: 12, color: Theme.palette.primary.main, cursor: 'pointer' }}
							onClick={clear_filter}>
							Clear All
						</Text>
						<div
							style={{
								marginLeft: 10,
								border: `1.2px solid ${Theme.palette.primary.main}`,
								background: 'white',
								borderRadius: 20,
								padding: '1px 15px'
							}}>
							<Text
								bold
								style={{ color: Theme.palette.primary.main, fontSize: 10, cursor: 'pointer' }}
								onClick={handleSubmitFilter}>
								Done
							</Text>
						</div>
					</div>
				</div>

				{_.map(
					[
						{ title: 'Release year', height: 50, type: 'range-slider' },
						{ title: 'Rating', height: 50, type: 'slider' },
						// { title: 'Types', height: 100 },
						// { title: 'Language', height: 100 },
						{title:'Language',
						type:"language",
						data:[{language:"hi",langName:"Hindi"},{language:"en",langName:"English"},{language:"ta",langName:"Tamil"},{language:"te",langName:"Telgu"},{language:"ml",langName:"Malayalam"},{language:"kn",langName:"Kannada"},{language:"mr",langName:"Marathi"},{language:"bn",langName:"Bengali"}],
						onClick: handleClickLanguage
					    },
						{
							title: 'Genres',
							list: genres,
							height: 300,
							selected: selectedGenres,
							show: 'name',
							onClick: handleClickGenre
						},
						{
							title: 'Age Rating',
							list: age_ratings,
							height: 100,
							selected: selectedCertifications,
							show: 'certification',
							onClick: handleClickAgeRating
						}
					],
					(v, i) => {
						let rowRenderer;

						switch (v.type) {
							case 'range-slider':
								rowRenderer = (
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<span style={{ fontSize: 12 }}>1900</span>
										<Slider
											style={{ width: 150 }}
											value={releaseYear}
											onChange={handleReleaseYearChange}
											valueLabelDisplay='auto'
											aria-labelledby='range-slider'
											getAriaValueText={(value) => {
												return value;
											}}
											min={1900}
											max={2021}
										/>
										<span style={{ fontSize: 12 }}>2021</span>
									</div>
								);
								break;
							case 'slider':
								rowRenderer = (
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<span>0</span>
										<Slider
											style={{ width: 150 }}
											getAriaValueText={(value) => {
												return value;
											}}
											aria-labelledby='discrete-slider'
											valueLabelDisplay='auto'
											value={rating}
											onChange={handleRatingChange}
											steps={10}
											min={0}
											max={10}
											track='inverted'
										/>
										<span>10</span>
									</div>
								);
								break;
							case 'language':
									rowRenderer = _.map(v.data || [], (v2, i2) => {
										const showTick = selectedLanguages.includes(v2.language)
					
										return (
											<ListItem
												button
												key={`item${i2}`}
												onClick={() => v.onClick(v2.language,showTick)}
												style={{
													display: 'flex',
													alignItems: 'center',
													paddingLeft: 0,
													width: '50%',
													paddingTop: 4,
													paddingBottom: 4
												}}>
												{showTick && <CheckIcon style={{ color: Theme.palette.primary.main, fontSize: 12 }} />}
	
												<Text style={{ fontSize: 12, marginLeft: 5 }}>{v2.langName}</Text>
											</ListItem>
										);
									});
									break;

							default:
								rowRenderer = _.map(v.list || [], (v2, i2) => {
									const showTick = _.indexOf(v.selected, v2._id) > -1;
									return (
										<ListItem
											button
											key={`item${i2}`}
											onClick={() => v.onClick(v2._id)}
											style={{
												display: 'flex',
												alignItems: 'center',
												paddingLeft: 0,
												width: '50%',
												paddingTop: 4,
												paddingBottom: 4
											}}>
											{showTick && <CheckIcon style={{ color: Theme.palette.primary.main, fontSize: 12 }} />}

											<Text style={{ fontSize: 12, marginLeft: 5 }}>{_.get(v2, `_source.${v.show}`, '')}</Text>
										</ListItem>
									);
								});
								break;
						}
						return (
							<div key={`index${i}`} style={{ borderBottom: '1px solid lightgrey' }}>
								<div style={{ width: '90%', margin: '0 auto', paddingTop: 10, paddingBottom: 10 }}>
									<Text semi>{v.title}</Text>
									<div
										style={{
											height: v.height,
											display: 'flex',
											flexDirection: 'column',
											flexWrap: 'wrap',
											marginTop: 10
										}}>
										{rowRenderer}
									</div>
								</div>
							</div>
						);
					}
				)}
			</div>
		</Drawer>
	);
};

export default Filter;
