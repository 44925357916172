import _ from 'lodash';
import {
	SEARCH_UPDATE
} from 'resources/redux_constants';


const Search = (state = null, action) => {
	switch (action.type) {
		case SEARCH_UPDATE:
			return action.payload;
		default:
			return state;
	}
};

export default Search;
