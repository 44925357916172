const IMAGES = {
	eye: '/assets/images/eye.svg',
	movie_dummy_poster: '/assets/images/movie-dummy-poster.png',
	prime_video: '/assets/images/prime-video.svg',
	netflix: '/assets/images/netflix.svg',
	hotstar: '/assets/images/hotstar.webp',
	menu: '/assets/images/menu.svg',
	share: '/assets/images/share.svg',
	heart: '/assets/images/heart.svg',
	heart_filled: '/assets/images/heart-filled.svg',
	comment: '/assets/images/comment.svg',
	wishlist: '/assets/images/add-to-wishlist.svg',
	wishlist_filled: '/assets/images/wishlist-filled.svg',
	like: '/assets/images/like.svg',
	dislike: '/assets/images/dislike.svg',
	tmdb: '/assets/images/tmdb.svg',
	add_provider: '/assets/images/add-provider.svg',
	poster: '/assets/images/poster.png',
	menuWhite: '/assets/images/menu-white.svg',
	cancel: '/assets/images/cancel.svg',
	play: '/assets/images/play.svg',
	playBtn: '/assets/images/playButtonn.png',
	filter: '/assets/images/filter.svg',
	delete: '/assets/images/delete.svg',
	default_poster: '/assets/images/default_poster.svg',
	star: '/assets/images/star.svg',
	star_filled: '/assets/images/star-filled.svg',
	arrow_icon: '/assets/images/arrow.png',
	closes_icon: '/assets/images/close.png'
};

export default IMAGES;
