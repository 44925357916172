import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core';
import { hide_alert } from 'actions/actions';
import _ from 'lodash';

class Alert_Dialog extends Component {
	render() {
		return (
			<Dialog open={this.props.open || false} onClose={this.props.hide_alert}>
				<DialogTitle>{this.props.title || ''}</DialogTitle>
				<DialogContent style={{ marginTop: -5, marginBottom: 10 }}>{this.props.message}</DialogContent>
				<DialogActions>
					<Button color='primary' variant='contained' onClick={this.props.hide_alert}>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App.alert, ['open', 'title', 'message'])
});
const mapDispatchToProps = (dispatch) => ({
	hide_alert: () => dispatch(hide_alert())
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert_Dialog);
