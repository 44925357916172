import _ from 'lodash';
import {
	OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL
} from 'resources/redux_constants';


const LoginModal = (state = false, action) => {
	switch (action.type) {
		case OPEN_LOGIN_MODAL:
			return true;
        case CLOSE_LOGIN_MODAL:
            return false;
		default:
			return false;
	}
};

export default LoginModal;
