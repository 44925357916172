import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, Card } from '@material-ui/core';
import Theme from 'resources/Theme';
import Contained_Button from 'components/Contained_Button';
import Text from 'components/Text';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextInput from 'components/TextInput';
import { welcome_invitation } from 'actions/actions';

const logo = '/assets/images/logo.svg';
const smiley = '/assets/images/smiley.png';

class WelcomeScreen extends Component {
	state = {
		welcome_data: {
			email: ''
		}
	};
	handle_change = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		const { welcome_data } = this.state;

		this.setState({
			welcome_data: { ...welcome_data, [name]: value }
		});
	};
	handle_welcome = (e) => {
		// e.preventDefault();
		const { welcome_data } = this.state;

		this.props.welcome_invitation(welcome_data);
		this.setState({
			welcome_data: { email: '' }
		});
	};

	render() {
		const { classes, goToLogin } = this.props;
		const { welcome_data } = this.state;

		return (
			<div className={classes.container}>
				<div className={classes.card_container}>
					<div
						className={classes.container_background}
						style={{ width: '100%', background: 'transparent', height: '100%' }}>
						<Card className={classes.card} elevation={0}>
							<div style={{ textAlign: 'left', marginBottom: '3em', marginTop: '-1em' }}>
								<img className={classes.logo} src={logo} loading='lazy' />
							</div>
							<div className={classes.cardBackground} style={{ marginTop: 30, marginBottom: 40 }}>
								<div className={classes.margin_bottom} style={{ textAlign: 'left' }}>
									<Text semi style={{ fontSize: Theme.font_size.H6 }}>
										Welcome!
									</Text>
								</div>
								<div style={{ textAlign: 'left' }}>
									<div className={classes.margin_bottom}>
										<p>
											We're working hard to get your Kunbah ready for launch. We are working on the finishing
											touches to make sure that things run smoothly.
										</p>
									</div>
									<div className={classes.margin_bottom}>
										<p>
											We're greateful for your visit and will send you a formal invite Email when your kunbah is
											ready, please provide your email id below.
										</p>
									</div>
									<div className={classes.margin_bottom}>
										<p>
											Waiting for you to join,
											<br />
											-Kunbah Team
											<span>
												<img
													style={{ width: '1.5em', position: 'relative', left: '7em', bottom: '1.5em' }}
													src={smiley}
												/>
											</span>
										</p>
									</div>
									<ValidatorForm className={classes.form} onSubmit={this.handle_welcome}>
										<div style={{ textAlign: 'left', marginLeft: '0.8em', marginTop: '2em' }}>
											<h4>Email</h4>
											<TextInput
												type='email'
												className={classes.text_field}
												value={welcome_data.email}
												onChange={this.handle_change}
												margin='dense'
												fullWidth='true'
												name='email'
												validators={['required']}
												variant='outlined'
												color='primary'
											/>
										</div>
										<div className={classes.welcome_button}>
											<Contained_Button
												className={classes.hover_effect}
												color='primary'
												type='submit'
												style={{ width: 150, margin: '1em auto' }}>
												Send invite{' '}
												<span style={{ paddingLeft: '0.3em', display: 'flex', justifyContent: 'center' }}>
													<ArrowForwardIcon fontSize='small' />
												</span>
											</Contained_Button>
										</div>
									</ValidatorForm>
								</div>
							</div>
							<div className={classes.welcome_footer}>
								<p style={{ marginBottom: '10px', marginTop: '-1em' }}>
									Are you Developers/Testers?{' '}
									<Text semi>
										<a style={{ color: '#297FFB' }} href='#' onClick={goToLogin}>
											Click Here
										</a>
									</Text>
								</p>
							</div>
						</Card>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	welcome_invitation: (welcome_data) => dispatch(welcome_invitation(welcome_data))
});

const STYLES = (theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%'
	},
	card: {
		textAlign: 'center',
		margin: '0 auto',
		background: '#F5F6FF',
		padding: '3em 1.5em',
		height: '100%'
	},

	card_container: {
		width: 500,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},

	margin_bottom: {
		marginBottom: '1.5em',
		marginLeft: '1em',
		lineHeight: '1.5rem',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0.4em'
		}
	},
	logo: {
		width: '45%',
		[theme.breakpoints.down('xs')]: {
			// width:"55%"
		},
		[theme.breakpoints.down('sm')]: {
			// width:"50%"
		}
	},
	welcome_button: {
		marginTop: '1em',
		marginLeft: '1em',
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center'
		},
		[theme.breakpoints.up('sm')]: {
			textAlign: 'center'
		}
	},
	welcome_footer: {
		textAlign: 'left',
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center'
		},
		[theme.breakpoints.up('sm')]: {
			textAlign: 'center',
			marginLeft: '1em'
		}
	},
	form: {
		[theme.breakpoints.down('xs')]: {
			marginLeft: '-0.3em'
		},
		[theme.breakpoints.up('sm')]: {
			marginLeft: '-0.3em'
		}
	}
});

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen));
