import _ from 'lodash';
import { TOGGLE_ADDED_TO_WATCHLIST, WATCHLIST_RECEIVED,REMOVE_FROM_WATCHLIST} from 'resources/redux_constants';
import api_requests from 'resources/api_requests';

export const toggle_add_to_watchlist = (movie, added_to_watchlist, type) => (dispatch, getState) => {
	const state = getState();
	const username = _.get(state, 'App.user_info.username', '');
	api_requests[added_to_watchlist ? 'post_unlink_activity' : 'post_link_activity'](
		{
			e1: {
				_type: 'auth_user',
				_id: username
			},
			e2: {
				_type: type,
				_id: movie._id
			},
			e1ToE2Relation: `watched-${type === 'movie' ? 'movies' : 'tv-shows'}`
		},
		false
	).then((res) => {
		dispatch(toggle_added_to_watchlist(added_to_watchlist ? { _id: movie._id } : movie));
	});
};

export const watchlist_received = (watchlist_movies) => ({
	type: WATCHLIST_RECEIVED,
	watchlist_movies
});

export const toggle_added_to_watchlist = (movie) => ({
	type: TOGGLE_ADDED_TO_WATCHLIST,
	movie
});
export const remove_item_from_watchlist = (id) =>({
	type: REMOVE_FROM_WATCHLIST,
	id
})
