import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { withStyles, Card } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { login, guestUserLoggedIn } from 'actions/actions';
import TextInput from 'components/TextInput';
import Text from 'components/Text';
import Contained_Button from 'components/Contained_Button';
import Theme from 'resources/Theme';
import IMAGES from 'resources/images';
const logo = '/assets/images/logo.svg';
const cover = '/assets/images/cover.png';
import PageTrackingWithPath from '../../components/pageViewTrackerWithPath';
class Login extends Component {
	state = {
		form_data: {
			username: '',
			password: ''
		}
	};
	componentDidMount () {
		PageTrackingWithPath('/login');
	}
	handle_change = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		const { form_data } = this.state;

		// if (name === 'username') {
		// 	let format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// 	this.state.emailCondition = format.test(_.trim(value));
		// 	form_data[name] = _.trim(value);
		// }

		// if (name === 'password') {
		// 	form_data[name] = value;
		// 	this.state.passwordCondition = _.isEmpty(value) ? false : true;
		// }

		this.setState({
			form_data: { ...form_data, [name]: value }
		});
	};

	handle_login = () => {
		this.props.login(this.state.form_data);
	};

	render () {
		const { classes } = this.props;
		const { form_data } = this.state;
		return (
			<div className={classes.container}>
				<div style={{ width: '100%', background: 'white' }}>
					<Card className={classes.card} elevation={0}>
						<div style={{ marginTop: 40, textAlign: 'center' }}>
							<img src={logo} loading='lazy' />
						</div>
						<div style={{ marginTop: 40, marginBottom: 30 }}>
							<Text semi style={{ fontSize: Theme.font_size.H5 }}>
								Log In
							</Text>
							<div
								style={{
									height: 3,
									marginTop: 4,
									borderRadius: 25,
									width: 40,
									background: Theme.palette.yellow
								}}></div>
						</div>
						<ValidatorForm onSubmit={this.handle_login}>
							<TextInput
								type='email'
								label='Email'
								value={form_data.username}
								onChange={this.handle_change}
								name='username'
								validators={['required']}
							/>
							<TextInput
								type='password'
								label='Password'
								value={form_data.password}
								onChange={this.handle_change}
								name='password'
								style={{ marginTop: 20 }}
								validators={['required']}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 25,
									marginBottom: 15
								}}>
								<Link to='/forgot-password'>
									<Text semi>FORGET PASSWORD?</Text>
								</Link>
							</div>
							<div style={{ marginBottom: 18, color: 'grey' }}>
								By continuing, I agree to the{' '}
								<Link to='/terms' style={{ color: 'red', fontWeight: 'bold' }}>
									Terms of Use
								</Link>{' '}
								&{' '}
								<Link to='/policy' style={{ color: 'red', fontWeight: 'bold' }}>
									Privacy Policy
								</Link>
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
								<Contained_Button href='/signup' color='black' type='submit' style={{ width: 130 }}>
									Sign up
								</Contained_Button>
								<Contained_Button color='primary' type='submit' style={{ width: 130 }}>
									Log in
								</Contained_Button>
							</div>
						</ValidatorForm>
						<Link
							to={'/'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: 30,
								fontSize: 16,
								fontWeight: 'bold',
								color: 'white',
								borderRadius: 20,

								height: 42,
								backgroundColor: '#f3a73b',
								alignItems: 'center'
							}}
							onClick={() => this.props.guestLoggedIn()}>
							Continue as Guest
							<div
								style={{
									height: 20,
									width: 20,
									borderRadius: 10,
									backgroundColor: '#006BFF',
									alignContent: 'center',
									paddingTop: 3,
									paddingInline: 3,
									marginInline: 6
								}}>
								<img src={IMAGES.arrow_icon} style={{ width: 12 }} />
							</div>
						</Link>
					</Card>
				</div>
				<div className={classes.cover_container}>
					<div className={classes.cover_overlay}></div>
					<div className={classes.cover_image}></div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	login: (form_data) => dispatch(login(form_data)),
	guestLoggedIn: () => dispatch(guestUserLoggedIn())
});

const STYLES = (theme) => ({
	container: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		justifyContent: 'space-between',
		flexDirection: 'column'
	},
	card: {
		width: 350,
		borderRadius: 0,
		margin: '0 auto',
		[theme.breakpoints.down('xs')]: {
			width: '90%'
		}
	},
	cover_container: {
		width: '100%',
		height: '30%',
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			height: '20%'
		}
	},
	cover_overlay: {
		position: 'absolute',
		opacity: 0.6,
		background: theme.palette.pink,
		width: '100%',
		height: '100%'
	},
	cover_image: {
		background: `url(${cover})`,
		backgroundSize: 'contain',
		width: '100%',
		height: '100%'
	}
});

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Login));
