import React, { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { connect } from 'react-redux';
import { getMovies } from '../../actions/recommendation_actions';
import Post from 'components/Post';
import { get_posts } from 'actions/feed_actions';
import { openLoginModal } from 'actions/loginModal_actions';
import RecommendedList from './recommended';
import PageTrackingWithPath from '../../components/pageViewTrackerWithPath';
import SharePopup from 'components/sharePopup';
const Feed = (props) => {
	const { posts, loading, total } = props;
	const hasMorePosts = posts.length !== total;
	const [mediaId, setMediaId] = useState(null);
	const [mediaType, setMediaType] = useState(null);
	const [popupState, setPopupState] = useState(false);
	const [sentryRef] = useInfiniteScroll({
		loading: !hasMorePosts ? false : loading,
		hasNextPage: hasMorePosts,
		onLoadMore: () => {
			props.get_posts(posts.length);
		},
		disabled: !hasMorePosts,
		rootMargin: '0px 0px 400px 0px'
	});
	useEffect(() => {
		PageTrackingWithPath('/feeds');
	}, []);

	const onClickHandlePosts = () => {
		props.openLoginModal();
	};

	const handlePopup = (data) => {
		setPopupState(!popupState);
		console.log(data);
		if (data) {
			setMediaType(data.media);
			setMediaId(data.id);
		}
	};

	return (
		<div style={{ flex: 1 }}>
			{popupState && (
				<div style={{ flex: 1, position: 'absolute', width: '100%' }}>
					<SharePopup popupState={popupState} mediaId={mediaId} mediaType={mediaType} handlePopup={() => handlePopup()} />
				</div>
			)}
			{
				<RecommendedList
					title='For You'
					feed={true}
					list={props.chef_special_movies}
					index={1}
					loading={props.loadingMovies}
					total={props.totalMovies}
					getMoreData={props.getMovies}
					guestUser={props.guestUser}
					handleLoginModal={() => props.openLoginModal()}
				/>
			}
			{_.map(posts, (v, i) => {
				return (
					<Post
						data={v}
						key={`post${v._id}`}
						onClickHandlePosts={() => onClickHandlePosts()}
						guestUser={props.guestUser}
						handleSharePopup={(data) => handlePopup(data)}
					/>
				);
			})}

			<div ref={sentryRef} className='feed-loader'>
				{hasMorePosts ? <span>Loading...</span> : <span>No More Posts</span>}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { chef_special_movies, loadingMovies, totalMovies } = state.Recommendation;
	const { guestUser } = state.App;
	return {
		..._.pick(state.Feed, ['posts', 'loading', 'total']),
		chef_special_movies,
		loadingMovies,
		totalMovies,
		guestUser
	};
};

const mapDispatchToProps = {
	get_posts,
	getMovies,
	openLoginModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
