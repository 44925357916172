import { Button, IconButton, InputBase, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import _ from 'lodash';
import { withRouter } from 'react-router';
import RateCapsule from './ratingCapsule';
import utils from 'resources/utils';
import IMAGES from 'resources/images';
import Theme from 'resources/Theme';
import moment from 'moment';
import Avatar from './Avatar';
import Text from './Text';

import { toggle_like_post, create_comment, deletePost } from 'actions/feed_actions';
import { toggle_add_to_watchlist } from 'actions/watchlist_actions';
import Contained_Button from './Contained_Button';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Post = (props) => {
	const [current_slide_index, set_current_slide_index] = React.useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [slideIndex, setSlideIndex] = React.useState(0);
	const [commentText, setCommentText] = useState('');
	const [showAllComments, setShowAllComments] = useState(false);
	const [showFullCommentText, setShowFullCommentText] = useState(null);
	const [seeMore, setSeeMore] = useState(true);
	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleSeeMore = () => {};

	const { data, isMyProfile } = props;

	const all_media = [
		..._.map(data._source.movies, (v) => {
			v._type = 'movie';
			return v;
		}),
		..._.map(data._source['tv-shows'], (v) => {
			v._type = 'tv-show';
			return v;
		})
	];

	const classes = useStyles();
	const dispatch = useDispatch();
	const { user_info, watchlist_movies } = useSelector((state) => ({
		..._.pick(state.App, ['user_info']),
		..._.pick(state.Watchlist, ['watchlist_movies'])
	}));

	const liked = _.findIndex(data._source.likedBy, { _id: user_info?.username }) !== -1;

	const handle_like_post = () => {
		dispatch(toggle_like_post(data._id, liked));
	};

	const handle_add_to_wishlist = () => {
		const added_to_wishlist = _.findIndex(watchlist_movies, { _id: all_media[current_slide_index]._id }) > -1;
		dispatch(
			toggle_add_to_watchlist(
				all_media[current_slide_index],
				added_to_wishlist,
				all_media[current_slide_index]._type === 'movie' ? 'movie' : 'tv-show'
			)
		);
	};

	const settings = {
		dots: true,
		infinite: true,
		arrows: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: (
			<div>
				<div style={{ zIndex: 0 }}>
					<ChevronLeftIcon style={{ color: Theme.palette.primary.main, fontSize: 40 }} />
				</div>
			</div>
		),
		nextArrow: (
			<div>
				<div style={{ zIndex: 0 }}>
					<ChevronRightIcon style={{ color: Theme.palette.primary.main, fontSize: 40 }} />
				</div>
			</div>
		),
		beforeChange: (current, next) => set_current_slide_index(next)
	};

	const createComment = () => {
		dispatch(create_comment(data._id, commentText));
		setCommentText('');
	};

	return (
		<div className={classes.post_container}>
			<div style={{ width: '92%', margin: '0 auto', marginTop: 20 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div
						style={{ display: 'flex', cursor: 'pointer' }}
						onClick={() => {
							if (props.guestUser) {
								props.onClickHandlePosts();
							} else {
								props.history.push(`/profile/${data._source.user._id}`);
							}
						}}>
						<Avatar fullname={_.get(data, '_source.user.fields.name', '')} />
						<div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
							<Text bold component='p'>
								{_.capitalize(_.get(data, '_source.user.fields.name', ''))}
							</Text>
							{/* <Text semi>Matrix fan</Text> */}
						</div>
					</div>
					<div className={classes.menu_container}>
						{_.map(_.get(data, 'movies.watch_providers') || [], (provider, i) => {
							return (
								<img
									src={utils.get_poster_base_url() + provider.fields.logo_path}
									style={{ width: 50, marginLeft: i && 15 }}
								/>
							);
						})}
						{/* <img src={IMAGES.netflix} />
						<img src={IMAGES.prime_video} style={{ marginLeft: 5 }} /> */}
						{isMyProfile && (
							<div style={{ marginLeft: 5 }}>
								<IconButton onClick={handleOpenMenu} style={{ width: 35, height: 35, marginTop: -2 }}>
									<img src={IMAGES.menu} loading='lazy' />
								</IconButton>
								<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
									{_.map(
										[{ name: 'Delete Post', icon: IMAGES.delete, onClick: () => dispatch(deletePost(data._id)) }],
										(v, i) => {
											return (
												<MenuItem
													onClick={() => {
														v.onClick();
														handleCloseMenu();
													}}
													style={{ width: 150 }}
													key={`item${i}`}>
													<img src={v.icon} width='15' loading='lazy' />
													<Text style={{ marginLeft: 20 }}>{v.name}</Text>
												</MenuItem>
											);
										}
									)}
								</Menu>
							</div>
						)}
					</div>
				</div>

				<Text component='p' style={{ marginTop: 10, marginBottom: 10, fontSize: 20, whiteSpace: 'pre-line' }}>
					{_.get(data, '_source.text', '').length > 180
						? seeMore
							? `${_.get(data, '_source.text', '')?.substr(0, 180)}...`
							: _.get(data, '_source.text', '')
						: _.get(data, '_source.text', '')}
					{_.get(data, '_source.text', '').length > 180 &&
						(seeMore ? (
							<Button onClick={() => setSeeMore(false)}>
								<u>See more</u>
							</Button>
						) : (
							<Button onClick={() => setSeeMore(true)}>See less</Button>
						))}
				</Text>

				{!_.isEmpty(all_media) && (
					<div id={`slider${data._id}`} style={{ ...(all_media.length > 1 && { marginBottom: 20 }) }}>
						{_.get(data, `_source.movies[${slideIndex}].fields.vote_average`, '') ? (
							<RateCapsule
								ratingMetaData={{
									myRatingStatus: false,
									voteAverage: _.get(data, `_source.movies[${slideIndex}].fields.vote_average`, '') ?? ''
								}}
							/>
						) : _.get(data, `_source.tv-shows[${slideIndex}].fields.vote_average`, '') ? (
							<RateCapsule
								ratingMetaData={{
									myRatingStatus: false,
									voteAverage: _.get(data, `_source.tv-shows[${slideIndex}].fields.vote_average`, '') ?? ''
								}}
							/>
						) : (
							<></>
						)}
						<Slider {...settings} afterChange={(index) => setSlideIndex(index)}>
							{_.map(all_media, (v, i) => {
								const poster = _.get(v, 'fields.poster_path')
									? utils.get_poster_base_url() + _.get(v, 'fields.poster_path')
									: IMAGES.default_poster;

								return (
									<div key={`item${i}`} style={{ position: 'relative' }}>
										<div style={{ position: 'relative' }}>
											<div
												style={{
													backgroundImage: `url(${poster}`,
													backgroundRepeat: 'no-repeat',
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													height: 300,
													filter: 'blur(4px)'
												}}
											/>
											<img
												src={poster}
												key={`poster${i}`}
												style={{
													height: 300,
													position: 'absolute',
													top: 0,
													right: 0,
													left: 0,
													margin: '0 auto',
													cursor: 'pointer'
												}}
												onClick={() => {
													props.history.push(
														v._type === 'movie' ? `/movie/${v._id}` : `/${v._type}/${v._id}`
													);
												}}
											/>
										</div>
									</div>
								);
							})}
						</Slider>
					</div>
				)}
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex' }}>
						<IconButton
							onClick={() =>
								props.handleSharePopup({
									id: all_media[0]._id,
									media: _.get(data, `_source.movies[0]`, '') ? 'movie' : 'tv-show'
								})
							}>
							<img src={IMAGES.share} style={{ height: 25, width: 25 }} loading='lazy' />
						</IconButton>
						<IconButton
							onClick={() => {
								if (props.guestUser) {
									props.onClickHandlePosts();
								} else {
									handle_like_post();
								}
							}}>
							<img src={liked ? IMAGES.heart_filled : IMAGES.heart} style={{ height: 25, width: 25 }} loading='lazy' />
						</IconButton>
						<IconButton>
							<img src={IMAGES.comment} style={{ height: 25, width: 25 }} loading='lazy' />
						</IconButton>
					</div>
					{!_.isEmpty(all_media) && (
						<IconButton
							onClick={() => {
								if (props.guestUser) {
									props.onClickHandlePosts();
								} else {
									handle_add_to_wishlist();
								}
							}}>
							<img
								src={
									_.findIndex(watchlist_movies, { _id: _.get(all_media, `[${current_slide_index}]._id`) }) > -1
										? IMAGES.wishlist_filled
										: IMAGES.wishlist
								}
								style={{ height: 25, width: 25 }}
							/>
						</IconButton>
					)}
				</div>
				{!_.isEmpty(data._source.likedBy) && (
					<Text component='p'>
						Liked by{' '}
						<Text component='span' bold>
							{_.get(data, '_source.likedBy[0].fields.name', '') + ' '}
						</Text>
						{data._source.likedBy.length > 1 && (
							<Text component='span'>
								and{' '}
								<Text component='span' bold>
									{data._source.likedBy.length - 1} others
								</Text>
							</Text>
						)}
					</Text>
				)}
				{!_.isEmpty(data._source.comments) && (
					<>
						{_.map(data._source.comments, (v, i) => {
							if (!showAllComments) {
								if (i > 1) {
									return null;
								}
							}
							const getDuration = (time) => {
								const diff = moment(time).fromNow();
								return diff;
							};

							let name = v.new_comment ? user_info.name : v.fields.commentBy?.fields?.name;
							let commentText = v.new_comment ? v.commentText : v.fields.text;
							const now = moment(new Date());
							const commentAt = v.fields?._createdAt ? getDuration(v.fields?._createdAt, now) : 'just now';

							return (
								<div key={`item${i}`} style={{ marginTop: 5 }}>
									<div style={{ display: 'flex' }}>
										<Text bold style={{ whiteSpace: 'nowrap' }}>
											{name}
										</Text>
										<div style={{ marginLeft: 5 }}>
											<Text style={{ wordBreak: 'break-word' }}>
												{showFullCommentText === i ? commentText : _.truncate(commentText, { length: 50 })}
											</Text>
											{commentText.length > 50 && showFullCommentText !== i && (
												<Text className={classes.showMore} onClick={() => setShowFullCommentText(i)}>
													more
												</Text>
											)}
										</div>
									</div>
									{/* <Text component='p' className={classes.commentTime}>
									{commentAt}
									</Text> */}
								</div>
							);
						})}
						{data._source.comments.length > 2 && !showAllComments && (
							<Text component='p' className={classes.showMore} onClick={() => setShowAllComments(true)}>
								View all {data._source.comments.length} comments
							</Text>
						)}
					</>
				)}
			</div>

			<div className={classes.comment_container}>
				<InputBase
					placeholder='Add a comment'
					onChange={(e) => setCommentText(e.target.value)}
					value={commentText}
					style={{ width: '100%', height: 35 }}
				/>
				{commentText.length > 0 && (
					<Contained_Button
						container={classes.postBtnContainer}
						onClick={() => {
							if (props.guestUser) {
								props.onClickHandlePosts();
							} else {
								createComment();
							}
						}}>
						Post
					</Contained_Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	post_container: {
		borderTop: `1px solid ${theme.palette.light_grey}`,
		borderBottom: `1px solid ${theme.palette.light_grey}`,
		background: theme.palette.white
	},
	menu_container: {
		display: 'flex',
		justifyContent: 'space-between',
		'& > img': {
			width: 30,
			height: 30
		}
	},
	comment_container: {
		borderTop: `1px solid ${theme.palette.light_grey}`,
		padding: '6px 5%',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '5px',
		width: '100%'
	},
	commentBy: {
		fontWeight: 'bold'
	},
	showMore: {
		color: theme.palette.light_grey3,
		cursor: 'pointer'
	},
	commentTime: {
		fontSize: 12,
		color: theme.palette.light_grey3
	},
	postBtnContainer: {
		width: 85,
		height: 35
	}
}));

export default withRouter(Post);
