import _ from 'lodash';
import { LOGOUT_USER, TOGGLE_ADDED_TO_WATCHLIST, WATCHLIST_RECEIVED,REMOVE_FROM_WATCHLIST } from 'resources/redux_constants';

const initialState = {
	watchlist_movies: []
};

const Watchlist = (state = { ...initialState }, action) => {
	switch (action.type) {
		case WATCHLIST_RECEIVED:
			return { ...state, watchlist_movies: action.watchlist_movies };

		case TOGGLE_ADDED_TO_WATCHLIST: {
			let watchlist_movies = JSON.parse(JSON.stringify(state.watchlist_movies));
			const movie_index = _.findIndex(watchlist_movies, { _id: action.movie._id });

			if (movie_index === -1) {
				watchlist_movies = [...watchlist_movies, action.movie];
			} else {
				watchlist_movies.splice(movie_index, 1);
			}
			return { ...state, watchlist_movies };
		}

		case REMOVE_FROM_WATCHLIST:
			const updatedWatchList = JSON.parse(JSON.stringify(state.watchlist_movies)).filter((item,index) =>
			{   if(item._id != action.id){
				
				return item
			    }
			}
		    )
			
			return { ...state, watchlist_movies:updatedWatchList };

		case LOGOUT_USER: {
			return { ...initialState };
		}

		default:
			return state;
	}
};

export default Watchlist;
