import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import utils from 'resources/utils';
import Text from 'components/Text';
import { ListItem } from '@material-ui/core';
import IMAGES from 'resources/images';
import PageTracking from 'components/pageViewTracker';

const SearchScreen = (props) => {
	const [searchedMedia, setSearchedMedia] = useState([]);
	useEffect(() => {
		PageTracking(props);
	}, []);
	return (
		<div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
			{!_.isEmpty(props.searchData) && (
				<div
					style={{
						width: '100%',

						marginTop: 20,
						borderRadius: 10,
						overflow: 'scroll'
					}}>
					{_.map(props.searchData, (v, i) => {
						const poster = _.get(v, '_source.poster_path')
							? utils?.get_poster_base_url() + _.get(v, '_source.poster_path')
							: IMAGES?.default_poster;
						const resDate = new Date(
							v?._source?.release_date
								? v?._source?.release_date
								: v?._source?.first_air_date
								? v?._source?.first_air_date
								: ''
						);

						const getYear = resDate?.getFullYear();
						return (
							<ListItem
								button
								key={`search_post${i}`}
								onClick={() => {
									props.history.push(v._type === 'movie' ? `/movie/${v._id}` : `/${v._type}/${v._id}`);
								}}
								style={{
									paddingBottom: 10,
									paddingTop: 10,
									...(i !== 0 && { borderTop: '1px solid #A8A8A8' }),
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									cursor: 'pointer'
								}}>
								<div style={{ display: 'flex' }}>
									<img
										src={poster}
										style={{
											height: 100,
											width: 60,
											alignSelf: 'center'
										}}
									/>
									<div
										style={{
											marginLeft: 14,
											paddingBottom: 8,
											paddingTop: 8,
											justifyContent: 'space-between',
											display: 'flex',
											justifyContent: 'space-between',
											flexDirection: 'column'
										}}>
										<Text component='p' semi style={{ fontSize: 16 }}>
											{_.get(v?._source, 'title', '')}
										</Text>
										<div>
											<Text style={{ color: 'grey' }}>
												{v?._type === 'tv-show' ? 'TV Show' : 'Movie'} {getYear ? <span>&#183;</span> : ''}{' '}
												{getYear ? ` ${getYear}` : ''}
											</Text>
											<Text style={{ color: 'grey' }} component='p'>
												{v?._source?.spoken_languages
													? v?._source?.spoken_languages[0]?.english_name ?? ''
													: ''}
											</Text>
										</div>
									</div>
								</div>
							</ListItem>
						);
					})}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		searchData: state.Search
	};
};

const Search = connect(mapStateToProps, null)(SearchScreen);

export default Search;
