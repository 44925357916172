import {  makeStyles } from '@material-ui/core';
import Text from 'components/Text';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Avatar from 'components/Avatar';

const Activities = (props) => {
	const classes = useStyles();

	const { media_comments } = useSelector((state) => ({
		..._.pick(state.App, ['media_comments']),
	}));


	return (
          <>
          <div>
              {props?.params?.title}
          </div>
          Activities
				{!_.isEmpty(media_comments) && (
					<>
					<div style={{ marginTop: 30 }}>
					<Text bold >
						Activities
					</Text>
				</div>
						{_.map(media_comments?.hits, (v, i) => {
							
							const getDuration = (time) =>{
								const diff = moment(time).fromNow()
								return diff
							}

							let name = v._source?.user?.fields?.name
							let commentText = v?._source?.text;
							const now = moment(new Date())
							const commentAt = v?._source?._createdAt?getDuration(v?._source?._createdAt,now):''
							
							return (
								<div key={`item${i}`} style={{ marginTop: 5 }}>
									<div style={{ display: 'flex',alignItems:'center' }}>
									<Avatar
							         fullname={name}
							         style={{ height: 28, width: 28, borderRadius: 14 }}
							         textStyles={{ fontSize: 20 }}
						             />
										<Text bold style={{ whiteSpace: 'nowrap',paddingLeft:5 }}>
											{name}:
										</Text>
									</div>
									<div style={{ marginLeft: 5,paddingLeft:32 }}>
											<Text style={{ wordBreak: 'break-word' }}>
												{commentText??''}
											</Text>
											{/* {commentText.length > 50 && showFullCommentText !== i && (
												<Text className={classes.showMore} onClick={() => setShowFullCommentText(i)}>
													more
												</Text>
											)} */}
										</div>
									<div style={{display:'flex',justifyContent:'flex-end',paddingTop:8}}><Text component='p' style={{color:'grey',paddingRight:28}} className={classes.commentTime}>
									{commentAt}
									</Text>
									</div>
								</div>
							);
						})}
						{/* {media_comments.comments.length > 2 && !showAllComments && (
							<Text component='p' className={classes.showMore} onClick={() => setShowAllComments(true)}>
								View all {data._source.comments.length} comments
							</Text>
						)} */}
					</>
				)}
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		background: theme.palette.white,
	}
}));



export default Activities;
