import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';

export default class Loader extends Component {
	render() {
		return (
			<div
				style={{
					position: 'fixed',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					background: '#44414124',
					zIndex: 10000
				}}>
				<CircularProgress />
			</div>
		);
	}
}
