import _ from 'lodash';
import { POSTS_RECEIVED, POST_CREATED, TOGGLE_POST_LIKED, COMMENT_CREATED, POST_DELETED, TOGGLE_FEED_LOADER } from 'resources/redux_constants';
import api_requests from 'resources/api_requests';
import { toggle_loader } from './actions';

export const create_post = (form_data, history) => (dispatch) => {
	dispatch(toggle_loader());

	api_requests.create_post(form_data).then((res) => {
		dispatch(post_created(res.data.post));
		history.push('/');
		dispatch(toggle_loader());
	});
};
export const create_comment = (post_id, commentText) => (dispatch, getState) => {
	const user_info = getState().App.user_info;

	api_requests
		.create_comment({
			_type: 'comment',
			body: {
				text: commentText,
				commentBy: {
					_id: user_info.username, // user._id
					_type: 'auth_user'
				},
				post: {
					_id: post_id
				}
			}
		})
		.then((res) => {
			dispatch(comment_created(post_id, commentText));
		});
};
export const get_posts = (from) => (dispatch, getState) => {
	const state = getState();
	dispatch({
		type: TOGGLE_FEED_LOADER,
		value: true,
	});

	Promise.all([api_requests.get_posts(from)]).then((res) => {
		dispatch(posts_received([..._.get(state, 'Feed.posts', []), ..._.get(res[0].data, 'response.hits.hits', [])], _.get(res, '[0].data.response.hits.total.value')));

		dispatch({
			type: TOGGLE_FEED_LOADER,
			value: false,
		});
	});
};

export const toggle_like_post = (post_id, liked) => (dispatch, getState) => {
	const state = getState();
	const username = _.get(state, 'App.user_info.username', '');
	const name = _.get(state, 'App.user_info.name', '');

	dispatch(toggle_post_liked(post_id, username, name));

	api_requests[liked ? 'post_unlink_activity' : 'post_link_activity']({
		e1: {
			_type: 'userpost',
			_id: post_id
		},
		e2: {
			_type: 'auth_user',
			_id: username
		},
		e1ToE2Relation: 'likedBy'
	});
};

export const deletePost = (post_id) => (dispatch) => {
	dispatch(toggle_loader());

	api_requests
		.deleteQuery({
			_type: 'userpost',
			_id: post_id
		})
		.then((res) => {
			dispatch(delete_post(post_id));
			dispatch(toggle_loader());
		});
};

const delete_post = (post_id) => ({
	type: POST_DELETED,
	post_id
});

const post_created = (post) => ({
	type: POST_CREATED,
	post
});

const posts_received = (posts, total) => ({
	type: POSTS_RECEIVED,
	posts,
	total
});

const toggle_post_liked = (post_id, username, name) => ({
	type: TOGGLE_POST_LIKED,
	post_id,
	name,
	username
});

const comment_created = (post_id, commentText) => ({
	type: COMMENT_CREATED,
	post_id,
	commentText
});
