import React, { useEffect, useState } from 'react';
import Text from 'components/Text';
import { Menu, MenuItem } from '@material-ui/core';
import IMAGES from 'resources/images';
import _ from 'lodash';
import MovieList from './MovieList';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './Filter';
import { filterResultReceived, getExploreData, getGenreData } from 'actions/recommendation_actions';

import SingleItemList from './SingleItemList';

const filterChoices = [
	{ name: 'All', values: ['movie', 'tv-show'] },
	{ name: 'Movies', values: ['movie'] },
	{ name: 'Shows', values: ['tv-show'] }
];
const sortTypes = [
	{ name: 'Popularity', value: 'popularity' },
	{ name: 'Rating', value: 'vote_average' }
];

const RenderList = (props) => {
	const { exploreList, expandList } = props;

	if (!props.expandList) {
		return (
			<>
				{_.map(Object.keys(exploreList), (key, i) => {
					const list = exploreList[key].items;

					if (list?.length === 0) {
						return true;
					}
					return (
						<MovieList
							key={`item${i}`}
							title={`Top ${key}`}
							list={list}
							viewAll={expandList}
							setViewAll={() => {
								props.scrollToTop();
								props.setSelectedCollection(i < 2);

								props.setExpandList(key);
							}}
							index={i + 3}
						/>
					);
				})}
			</>
		);
	}

	return (
		<SingleItemList
			title={`Top ${expandList}`}
			list={exploreList[expandList].items}
			viewAll={expandList}
			setViewAll={props.setExpandList}
			index={1}
			loading={false}
			total={exploreList[expandList].total}
			getMoreData={props.getGenreData}
		/>
	);
};

const Explore = (props) => {
	const [anchorEl1, setAnchorEl1] = React.useState(null);
	const [anchorEl2, setAnchorEl2] = React.useState(null);
	const [anchorEl3, setAnchorEl3] = React.useState(false);
	const [selectedLanguage, setSelectedLanguage] = React.useState([]);
	const [viewAll, setViewAll] = React.useState(null);
	const [expandList, setExpandList] = useState(null);
	const [selectedCollection, setSelectedCollection] = useState(false);

	const [typeFilter, setTypeFilter] = React.useState(filterChoices[0]); //Use All by default
	const [sortBy, setSortBy] = React.useState('popularity');

	const { exploreList, genres, filterResults } = useSelector((state) => ({
		..._.pick(state.Recommendation, ['genres', 'exploreList', 'filterResults'])
	}));

	const dispatch = useDispatch();

	const handleOpenMenu1 = (event) => {
		setAnchorEl1(event.currentTarget);
	};

	const handleCloseMenu1 = () => {
		setAnchorEl1(null);
	};

	const handleOpenMenu2 = (event) => {
		setAnchorEl2(event.currentTarget);
	};

	const handleCloseMenu2 = () => {
		setAnchorEl2(null);
	};

	const handleClearSearchResults = () => {
		dispatch(filterResultReceived([]));
	};

	useEffect(() => {
		dispatch(getExploreData(sortBy, typeFilter.values, selectedLanguage));
	}, [sortBy, typeFilter, genres, selectedLanguage]);

	const custom_view_all = !_.isEmpty(filterResults) ? -1 : viewAll;

	let totalTitles = !_.isEmpty(filterResults) ? filterResults.length : 0;
	if (totalTitles === 0) {
		_.map(Object.values(exploreList), (v) => {
			totalTitles += v.items.length;
		});
	}

	return (
		<div style={{ marginTop: 10 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div>
					<Text>{totalTitles} titles</Text>
					<Text style={{ marginLeft: 10 }}>Sorted by</Text>
					<Text semi onClick={handleOpenMenu1} style={{ marginLeft: 5, color: '#313131', cursor: 'pointer' }}>
						{_.find(sortTypes, { value: sortBy }).name}
					</Text>

					<Menu anchorEl={anchorEl1} keepMounted open={Boolean(anchorEl1)} onClose={handleCloseMenu1}>
						{_.map(sortTypes, (v, i) => {
							return (
								<MenuItem
									onClick={() => {
										setSortBy(v.value);
										handleCloseMenu1();
									}}
									key={`item${i}`}>
									<Text>{v.name}</Text>
								</MenuItem>
							);
						})}
					</Menu>
				</div>

				<div style={{ display: 'flex' }}>
					<div
						style={{
							display: 'flex',
							background: 'white',
							border: '0.3px solid red',
							padding: '2px 10px',
							alignItems: 'center',
							borderRadius: 5,
							cursor: 'pointer',
							backgroundColor: '#f3e9f4',
							height: 28
						}}>
						<img
							src={IMAGES.filter}
							style={{ width: 20, marginLeft: 2, marginRight: 2, cursor: 'pointer' }}
							onClick={() => {
								setAnchorEl3(true);
							}}
						/>
						Filter
					</div>
				</div>
			</div>

			<Filter
				anchor={anchorEl3}
				handleClose={() => setAnchorEl3(false)}
				handleSelectLanguage={(langs) => setSelectedLanguage(langs)}
			/>
			{/* {!_.isEmpty(filterResults) && (
				<MovieList
					title='Search Results'
					list={filterResults}
					viewAll={custom_view_all}
					setViewAll={handleClearSearchResults}
					index={-1}
				/>
			)} */}
			{/* <ChefSpecial /> */}
			<RenderList
				scrollToTop={props.scrollToTop}
				exploreList={exploreList}
				setExpandList={setExpandList}
				expandList={expandList}
				setSelectedCollection={setSelectedCollection}
				getGenreData={() => {
					if (selectedCollection) {
						dispatch(getGenreData(sortBy, '', expandList));
					} else {
						dispatch(getGenreData(sortBy, expandList));
					}
				}}
			/>
		</div>
	);
};

export default Explore;
