import { Button, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Theme from 'resources/Theme';

class Link_Button extends Component {
	render() {
		const { classes, container = '', label = '', color, style, children, href, ...rest } = this.props;

		return (
			<Button
				style={{ borderRadius: 20, ...style }}
				classes={{
					root: `${classes.container} ${container}`,
					label: `${classes.label} ${label}`
				}}
				component={Link}
				to={href}
				{...rest}
				fullWidth>
				{children}
			</Button>
		);
	}
}

const STYLES = (theme) => ({
	container: {
		width: 100,
		height: 40,
		borderRadius: 100,
		color: Theme.palette.white
	},
	label: {
		fontSize: 14,
		fontWeight: 'bold',
		letterSpacing: 0
	}
});

export default withStyles(STYLES)(Link_Button);
