import React from 'react';
import Avatar from 'components/Avatar';
import Contained_Button from 'components/Contained_Button';
import Text from 'components/Text';
import { connect } from 'react-redux';
import { IconButton, ListItem, withStyles } from '@material-ui/core';
import { create_post } from 'actions/feed_actions';
import api_requests from 'resources/api_requests';
import utils from 'resources/utils';
import IMAGES from 'resources/images';
import Star_Rating_Toggle from 'components/Star_Rating_Toggle';
import './createPost.css';
const globe = '/assets/images/globe.svg';
const search = '/assets/images/search.svg';
const deleteIcon = '/assets/images/delete.svg';
import PageTracking from '../../components/pageViewTracker';
let debounced;

class CreatePost extends React.Component {
	state = {
		search_input: '',
		post_text: '',
		titles: [],
		search_movies: []
	};

	componentDidMount () {
		PageTracking(this.props);
	}
	handle_search_movies = (e) => {
		let search_text = e.target.value;
		if (debounced) {
			debounced.cancel();
		}

		debounced = _.debounce(() => {
			api_requests
				.search({
					_type: ['movie', 'tv-show'],
					joins: {
						title: 1,
						name: 1,
						original_title: 1,
						vote_average: 1,
						poster_path: 1,
						release_date: 1,
						original_language: 1,
						first_air_date: 1,
						spoken_languages: 1
					},
					q: search_text,
					suggest: true,
					scoring_script:
						"Math.log(2 + _score + (doc['vote_average'].size() != 0 ? doc['vote_average'].value : 0) +  (doc['popularity'].size() !=  0 ? doc['popularity'].value : 0))",
					size: 10
				})
				.then((res) => {
					this.setState({ search_movies: _.get(res.data, 'response.hits.hits', []) });
				});
		}, 500);

		this.setState({ search_input: search_text }, debounced);
	};

	handle_delete = (index) => {
		let titles = [...this.state.titles];
		titles.splice(index, 1);
		this.setState({
			titles
		});
	};

	create_post = () => {
		const { post_text, titles } = this.state;

		const tvShows = _.filter(titles, (v) => {
			return v._type === 'tv-show';
		});

		const movies = _.filter(titles, (v) => {
			return v._type === 'movie';
		});

		this.props.create_post(
			{
				_type: 'userpost',
				body: {
					text: post_text,
					user: {
						//_id: this.props.user_info.username, // user._id
						_id: utils._retrieveData('id'),
						_type: 'auth_user'
					},
					'tv-shows': _.map(tvShows, (v) => {
						return { _id: v._id, _type: v._type };
					}),
					movies: _.map(movies, (v) => {
						return { _id: v._id, _type: v._type };
					})
				}
			},
			this.props.history
		);
	};
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.setState({ post_text: `${this.state.post_text}` + `{"\n"}` });
		}
	};
	render () {
		const { search_input, post_text, titles, search_movies: searchedMedia } = this.state;
		const { classes } = this.props;

		return (
			<div style={{ background: 'white', position: 'absolute', width: '100%', height: '100%', overflow: 'auto' }}>
				<div style={{ width: '90%', margin: '0 auto', paddingTop: 20 }}>
					<div style={{ paddingBottom: 10, borderBottom: '1px solid #A8A8A8' }}>
						<div style={{ display: 'flex', width: '100%' }}>
							<div>
								<Avatar />
							</div>
							<textarea
								KeyDown={this._handleKeyDown}
								style={{
									marginLeft: 14,
									width: '100%',
									height: 100,
									borderColor: 'transparent',
									resize: 'none',
									paddingTop: 4
								}}
								placeholder='Type your post here...'
								onChange={(e) => this.setState({ post_text: e.target.value })}
								value={post_text}
								required={true}></textarea>
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<div
								style={{
									border: '1px solid #DE0418',
									padding: '5px 8px',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									borderRadius: 23,
									width: 110,
									cursor: 'pointer'
								}}>
								<img src={globe} style={{ width: 20, height: 20 }} />
								<Text style={{ color: '#DE0418', fontSize: 16 }}>Everyone</Text>
								{/* <img src={downArrow} /> */}
							</div>
						</div>
					</div>
					<div style={{ position: 'relative' }}>
						<div
							style={{
								border: '1px solid black',
								padding: '5px 8px',
								display: 'flex',
								alignItems: 'center',
								borderRadius: 50,
								width: '100%',
								cursor: 'pointer',
								marginTop: 20,
								padding: 10
							}}>
							<img src={search} style={{ width: 20, height: 20 }} />
							<input
								style={{ width: '100%', marginLeft: 10, fontSize: 18, borderColor: 'transparent' }}
								placeholder='Add Movies / TV Shows'
								onChange={this.handle_search_movies}
								onBlur={_.debounce(() => this.setState({ search_input: '', search_movies: [] }), 500)}
								value={search_input}
							/>
						</div>

						{!_.isEmpty(searchedMedia) && (
							<div
								style={{
									position: 'absolute',
									zIndex: 1000,
									width: '100%',
									border: '1px solid black',
									marginTop: 20,
									borderRadius: 10,
									background: 'white',
									maxHeight: 200,
									overflow: 'scroll'
								}}>
								{_.map(searchedMedia, (v, i) => {
									const poster = _.get(v, '_source.poster_path')
										? utils.get_poster_base_url() + _.get(v, '_source.poster_path')
										: IMAGES.default_poster;
									const resDate = new Date(
										v?._source?.release_date
											? v?._source?.release_date
											: v?._source?.first_air_date
											? v?._source?.first_air_date
											: ''
									);

									const getYear = resDate?.getFullYear();
									return (
										<ListItem
											button
											key={`search_post${i}`}
											onClick={() => {
												this.setState({
													titles: [...titles, v],
													search_movies: [],
													search_input: ''
												});
											}}
											style={{
												paddingBottom: 10,
												paddingTop: 10,
												...(i !== 0 && { borderTop: '1px solid #A8A8A8' }),
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												cursor: 'pointer'
											}}>
											<div style={{ display: 'flex' }}>
												<img
													src={poster}
													style={{
														height: 100,
														width: 60,
														alignSelf: 'center'
													}}
												/>
												<div
													style={{
														marginLeft: 14,
														paddingBottom: 8,
														paddingTop: 8,
														justifyContent: 'space-between',
														display: 'flex',
														justifyContent: 'space-between',
														flexDirection: 'column'
													}}>
													<Text component='p' semi style={{ fontSize: 16 }}>
														{_.get(v?._source, 'title', '')}
													</Text>
													<div>
														<Text style={{ color: 'grey' }}>
															{v?._type === 'tv-show' ? 'TV Show' : 'Movie'}{' '}
															{getYear ? <span>&#183;</span> : ''} {getYear ? ` ${getYear}` : ''}
														</Text>
														<Text style={{ color: 'grey' }} component='p'>
															{v?._source?.spoken_languages
																? v?._source?.spoken_languages[0]?.english_name ?? ''
																: ''}
														</Text>
													</div>
												</div>
											</div>
										</ListItem>
									);
								})}
							</div>
						)}
						{_.map(titles, (v, i) => {
							const poster = _.get(v, '_source.poster_path')
								? utils.get_poster_base_url() + _.get(v, '_source.poster_path')
								: IMAGES.default_poster;
							const resDate = new Date(
								v?._source?.release_date
									? v?._source?.release_date
									: v?._source?.first_air_date
									? v?._source?.first_air_date
									: ''
							);

							const getYear = resDate?.getFullYear();
							return (
								<div
									key={`titles${i}`}
									style={{
										paddingBottom: 20,
										paddingTop: 20,
										...(i !== 0 && { borderTop: '1px solid #A8A8A8' }),
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center'
									}}>
									<div style={{ display: 'flex', width: 250 }}>
										<div
											style={{
												background: `url(${poster})`,
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'contain',
												backgroundPosition: 'center',
												height: 130,
												width: 100,
												alignSelf: 'center'
											}}
										/>
										<div
											style={{
												marginLeft: 14,
												paddingBottom: 8,
												paddingTop: 8,
												justifyContent: 'space-between',
												display: 'flex',
												justifyContent: 'space-between',
												flexDirection: 'column'
											}}>
											<Text component='p' semi>
												{v?._source?.title}
											</Text>
											<div>
												<Text style={{ color: 'grey' }}>
													{v?._type === 'tv-show' ? 'TV Show' : 'Movie'}{' '}
													{getYear ? <span>&#183;</span> : ''} {getYear ? `${getYear}` : ''}
												</Text>
												<Text style={{ color: 'grey' }} component='p'>
													{v?._source?.spoken_languages
														? v?._source?.spoken_languages[0]?.english_name ?? ''
														: ''}
												</Text>
											</div>
										</div>
									</div>
									<div style={{ display: 'flex', width: 100, justifyContent: 'space-between' }}>
										<Star_Rating_Toggle
											mediaName={v._source.title}
											mediaId={v._id}
											mediaType={v._type === 'movie' ? 'movie' : 'tv-show'}
											media_detail={v}
										/>
										<IconButton onClick={() => this.handle_delete(i)}>
											<img src={deleteIcon} />
										</IconButton>
									</div>
								</div>
							);
						})}
						<div style={{ textAlign: 'center', marginTop: 20, zIndex: 0 }}>
							<Contained_Button onClick={this.create_post} container={classes.btn_container}>
								Tell Kunbah
							</Contained_Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['user_info'])
});

const mapDispatchToProps = {
	create_post
};

const STYLES = (theme) => ({
	btn_container: {
		width: 150
	}
});

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(CreatePost));
