import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Card, Button } from '@material-ui/core';
import IMAGES from 'resources/images';
import { EmailShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { EmailIcon, FacebookIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { getFacebookUrl, getWhatsAppUrl } from '@phntms/react-share';
function SharePopup (props) {
	const { classes } = props;
	const url = `www.kunbah.com/${props.mediaType}/${props.mediaId}`;
	const text = 'Welcome to kunbah';
	return (
		<div className={classes.container}>
			<Card className={classes.card} elevation={0}>
				<div style={{ padding: 10, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
					<button
						style={{ height: 18, width: 18, border: 0, backgroundColor: 'transparent' }}
						onClick={() => props.handlePopup()}>
						<img src={IMAGES.closes_icon} style={{ width: 18 }}></img>
					</button>
					<span style={{ marginLeft: 20 }}>Share it on social networks</span>
				</div>
				<div style={{ display: 'flex', padding: 20, flexDirection: 'row', justifyContent: 'space-evenly' }}>
					<FacebookShareButton url={url} quote={''} className='Demo__some-network__share-button'>
						<FacebookIcon size={32} round />
					</FacebookShareButton>

					<TelegramShareButton url={url} quote={''} className='Demo__some-network__share-button'>
						<TelegramIcon size={32} round />
					</TelegramShareButton>
					<TwitterShareButton url={url} quote={''} className='Demo__some-network__share-button'>
						<TwitterIcon size={32} round />
					</TwitterShareButton>
					<WhatsappShareButton url={url} quote={''} className='Demo__some-network__share-button'>
						<WhatsappIcon size={32} round />
					</WhatsappShareButton>
				</div>
			</Card>
		</div>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const STYLES = (theme) => ({
	container: {
		position: 'fixed',
		alignSelf: 'center',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'column',
		zIndex: 100,
		top: '45%',
		left: '30%'
	},
	card: {
		width: 230,
		height: 120,
		borderRadius: 20,
		margin: '0 auto',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		},
		boxShadow: '2px 1px 14px'
	}
});

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(SharePopup));
