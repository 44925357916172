import React, { Component } from 'react';
import _ from 'lodash';
import IMAGES from 'resources/images';
import Text from 'components/Text';
import Theme from 'resources/Theme';
const RateCapsule = ({ ratingMetaData, style = {}, textStyles = {} }) => {

	
    const rating = Math.round(ratingMetaData?.voteAverage * 10) / 10
	return (
	<>	{ratingMetaData?.myRatingStatus === true ?<div
			style={{
				background: Theme.palette.primary.main,
				width: 180,
				height: 40,
				borderRadius: 8,
				display: 'flex',
                flexDirection:'row',
				justifyContent: 'center',
				alignItems: 'center',
				...style
			}}>
			<div>my rating</div>
            <div>tmdb</div>
		</div>:<div style={{
				background: '#0c253f',
				width: 60,
				height: 20,
				borderRadius:10,
				display: 'flex',
                flexDirection:'row',
				justifyContent: 'space-between',
				alignItems: 'center',
                position:'absolute',
                right:0,
                marginRight:'4%',
                marginTop:-9,
                zIndex:10,
                paddingRight:8,
                paddingLeft:8,
				...style
			}}>
            <img src={IMAGES?.tmdb} width='18' height='20' />
            <Text style={{color:'white',fontSize:11,fontWeight:'100'}}>{rating.toFixed(1)}</Text>
        </div>}
         </>
    
	);
};

export default RateCapsule;
