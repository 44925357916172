import _ from 'lodash';
import {
	ALERT_CLOSE,
	ALERT_OPEN,
	LOGOUT_USER,
	MEDIA_DETAIL_RECIEVED,
	PROFILE_DETAIL_RECEIVED,
	TOGGLE_LOADER,
	MEDIA_LIST_RECIEVED,
	USER_INFO_RECEIVED,
	USER_INFO_UPDATED,
	SET_SELECTED_MEDIA,
	USER_POSTS_RECEIVED,
	MEDIA_COMMENTS_RECIEVED,
	GUEST_USER_LOGGED_IN,
	GUEST_USER_LOGGED_OUT
} from 'resources/redux_constants';
import utils from 'resources/utils';
import api_requests from 'resources/api_requests';
import { watchlist_received } from 'actions/watchlist_actions';
import { closeLoginModal, openLoginModal } from './loginModal_actions';
import PageTrackingWithPath from '../components/pageViewTrackerWithPath';
export const login = (form_data) => (dispatch) => {
	dispatch(toggle_loader());

	api_requests.request_login(form_data).then((res) => {
		utils.saveCookies(`Bearer ${res.data.token}`, res.data._id);
		console.log('modal', form_data.modal, 'asdasdsad');
		if (form_data.modal) {
			dispatch(closeLoginModal());
			dispatch(guestUserLoggedOut());
		}
		dispatch(get_user_details(false));
	});
};

export const guestUserLoggedIn = () => ({
	type: GUEST_USER_LOGGED_IN
});

export const guestUserLoggedOut = () => ({
	type: GUEST_USER_LOGGED_OUT
});

export const signup = (form_data, navigation) => (dispatch) => {
	dispatch(toggle_loader());

	api_requests.request_signup(form_data).then((res) => {
		utils.saveCookies(`Bearer ${res.data.token}`, res.data._id);
		dispatch(user_info_received(res.data));
		navigation.push('/');
		dispatch(toggle_loader());
	});
};
export const welcome_invitation = (welcome_data) => (dispatch) => {
	api_requests.request_welcome_info(welcome_data).then((res) => {
		dispatch(show_alert('Success!', 'You have been subscribed for updates.'));
	});
};

export const get_user_details = (showLoader = true) => (dispatch) => {
	if (showLoader) {
		dispatch(toggle_loader());
	}
	api_requests
		.read_data({
			_type: 'auth_user',
			_id: utils._retrieveData('id'),
			joins: {
				username: 1,
				name: 1,
				email: 1,
				'watched-movies': {
					original_title: 1,
					title: 1,
					genres: {
						name: 1
					},
					poster_path: 1,
					vote_average: 1,
					runtime: 1
				},
				'watched-tv-shows': {
					original_title: 1,
					title: 1,
					genres: {
						name: 1
					},
					poster_path: 1,
					vote_average: 1,
					runtime: 1
				},
				preferred_providers: {
					logo_path: 1
				}
			}
		})
		.then(
			(res) => {
				dispatch(user_info_received(res.data.response._source));
				dispatch(
					watchlist_received([
						...(res.data.response._source['watched-movies'] || []),
						...(res.data.response._source['watched-tv-shows'] || [])
					])
				);
				dispatch(toggle_loader());
			},
			(err) => {
				dispatch(toggle_loader());
				dispatch(user_info_received(null));
				utils.clearCookies();
			}
		);
};

export const get_media_details = (mediaType, id, relationshipFromUserMediaRating) => (dispatch) => {
	dispatch(toggle_loader());
	const searchObj =
		mediaType === 'tv-show'
			? {
					poster_path: 1,
					overview: 1,
					cast: { name: 1 },
					vote_average: 1,
					'similar-tv-shows': {
						poster_path: 1,
						vote_average: 1
					},
					videos: 1,
					runtime: 1,
					original_title: 1,
					genres: {
						name: 1
					},
					title: 1
			  }
			: {
					poster_path: 1,
					overview: 1,
					cast: { name: 1, character: 1, profile_path: 1 },
					vote_average: 1,
					'similar-movies': {
						poster_path: 1,
						vote_average: 1
					},
					videos: 1,
					runtime: 1,
					original_title: 1,
					genres: {
						name: 1
					},
					title: 1,
					videos: 1
			  };
	api_requests
		.multiquery({
			queries: [
				{
					queryType: 'get',
					params: {
						_type: mediaType,
						_id: id,

						movie: {
							title: 1,
							vote_average: 1,
							poster_path: 1,

							runtime: 1,
							'watch-list': {
								_id: 1,
								_where: {
									'watched-movies._id:': '*movie._id',
									_id: utils._retrieveData('id')
								}
							},

							genres: {
								name: 1
							}
						},
						joins: searchObj
					}
				},
				{
					queryType: 'search',
					params: {
						_type: 'user-media-rating',
						query: {
							bool: {
								filter: [
									{
										match: {
											[`${relationshipFromUserMediaRating}._id`]: id
										}
									}
									// {
									// 	match: {
									// 		'user._id': utils._retrieveData('id')
									// 	}
									// }
								]
							}
						},
						joins: {
							// [`${mediaType}s._id`]: id,
							user: { _id: 1 },
							rating: 1,
							_createdAt: 1,
							_updatedAt: 1
						}
					}
				},
				{
					queryType: 'search',

					params: {
						_type: 'userpost',

						query: {
							bool: {
								filter: [
									{
										match: {
											[`${mediaType}s._id`]: id
										}
									}
								]
							}
						},

						joins: {
							text: 1,

							_createdAt: 1,

							user: {
								email: 1,

								name: 1,

								generatedUsername: 1
							},

							likedBy: {
								email: 1,

								name: 1,

								_id: 1,

								generatedUsername: 1
							},

							comments: {
								text: 1,

								commentBy: {
									name: 1,

									email: 1
								}
							}
						}
					}
				}
			]
		})
		.then((res) => {
			const movie = res.data.response[0]; //Response of the first request of multiquery
			const userMediaRating = _.get(res.data.response[1], 'hits.hits[0]'); //Second response. This may be undefined, if not exists already
			//In all related entities follow the norm that its data will be in fields key,
			const mediaComments = res.data?.response[2];
			PageTrackingWithPath(`/${mediaType}/${id}/${movie?._source?.title}`);
			if (userMediaRating) {
				userMediaRating.fields = userMediaRating._source;
				delete userMediaRating._source;
				movie._source['user-media-ratings'] = [userMediaRating];
			}
			dispatch(media_comments_recevied(mediaComments?.hits));
			dispatch(media_detail_recevied(movie._source));
			dispatch(toggle_loader());
		});
};

export const upsert_user_rating = (media, mediaType, mediaId, rating) => (dispatch) => {
	let promise;
	if (!media['user-media-ratings']) {
		promise = createNewUserMediaRating(media, mediaType, mediaId, rating);
	} else {
		const userMediaRatingId = media['user-media-ratings'][0]._id;
		promise = updateExistingUserMediaRating(media, userMediaRatingId, rating);
	}
	promise.then(() => {
		dispatch(media_detail_recevied(media));
	});
};

const createNewUserMediaRating = async (media, mediaType, mediaId, rating) => {
	const body = {
		[mediaType]: {
			_id: mediaId,
			own: true
		},
		user: {
			_id: utils._retrieveData('id'),
			own: true
		},
		rating: rating
	};
	const res = await api_requests.create({
		_type: `user-media-rating`,
		body
	});
	if (res.data.status == 201) {
		media['user-media-ratings'] = [];
		media['user-media-ratings'].push({
			_id: res.data.response._id,
			fields: body
		});
	} else {
		//'Alert' status + could not submit rating
		console.log('err inn create', res);
	}
};
export const updateExistingUserMediaRating = async (media, userMediaRatingId, newRating) => {
	const res = await api_requests.update({
		_type: `user-media-rating`,
		_id: userMediaRatingId,
		update: {
			set: {
				rating: newRating
			}
		}
	});
	if ([200, 201].includes(res.data.status)) {
		media['user-media-ratings'][0].fields.rating = newRating;
	} else {
		//'Alert' status + could not submit rating
	}
};

export const get_profile_details = (id) => (dispatch) => {
	dispatch(toggle_loader());
	dispatch(profile_detail_recevied());
	Promise.all([
		api_requests.read_data({
			_type: 'auth_user',
			_id: id
		}),
		api_requests.get_user_posts(id)
	]).then((res) => {
		dispatch(profile_detail_recevied(res[0].data.response._source));
		dispatch(user_posts_received(res[1].data.data));
		dispatch(toggle_loader());
	});
};

export const show_alert = (title = '', message) => ({
	type: ALERT_OPEN,
	title,
	message
});

export const hide_alert = () => ({
	type: ALERT_CLOSE
});

export const toggle_loader = () => ({
	type: TOGGLE_LOADER
});

export const logout = () => ({
	type: LOGOUT_USER
});

export const user_info_received = (user_info) => ({
	type: USER_INFO_RECEIVED,
	user_info
});

export const user_info_updated = (userInfo) => ({
	type: USER_INFO_UPDATED,
	userInfo
});

export const media_detail_recevied = (media_detail) => ({
	type: MEDIA_DETAIL_RECIEVED,
	media_detail
});

export const media_comments_recevied = (media_comments) => ({
	type: MEDIA_COMMENTS_RECIEVED,
	media_comments
});

export const profile_detail_recevied = (profile_detail) => ({
	type: PROFILE_DETAIL_RECEIVED,
	profile_detail
});

export const user_posts_received = (user_posts) => ({
	type: USER_POSTS_RECEIVED,
	user_posts
});

export const toggle_error = (err, title) => (dispatch) => {
	dispatch(
		show_alert(
			title || 'Error',
			_.isString(err)
				? err
				: !_.isEmpty(_.get(err, 'response.data.message')) && _.isString(_.get(err, 'response.data.message'))
				? _.get(err, 'response.data.message')
				: 'Uh oh! Something went wrong!'
		)
	);
};

export const get_now_playing_media = (mediaType, pageNumber, size, joins) => (dispatch, getState) => {
	const preferred_providers = _.get(getState(), 'App.user_info.preferred_providers');
	get_special_collection('now-playing', mediaType, pageNumber, size, joins, dispatch, [
		{
			bool: {
				should: _.map(preferred_providers, (v) => {
					return {
						match_phrase: {
							'watch_providers._id': v._id
						}
					};
				})
			}
		}
	]);
};

export const get_trending = (mediaType, pageNumber, size, joins) => (dispatch, getState) => {
	const preferred_providers = _.get(getState(), 'App.user_info.preferred_providers');
	get_special_collection('trending-media', mediaType, pageNumber, size, joins, dispatch, [
		{
			bool: {
				should: _.map(preferred_providers, (v) => {
					return {
						match_phrase: {
							'watch_providers._id': v._id
						}
					};
				})
			}
		}
	]);
};

//Now playing and trending are considered special collections for now.
const get_special_collection = async (collection_type, mediaType, pageNumber = 0, size = 20, joins = 'read', dispatch, filters) => {
	const query = {
		_type: collection_type,
		query: {
			exists: {
				field: `${mediaType}._id`
			}
		},
		joins: joins,
		from: pageNumber * size,
		size: size,
		filters
	};
	await get_media_list(query, collection_type, mediaType, pageNumber, dispatch);
};
export const search_media = (mediaType, query, pageNumber) => (dispatch) => {
	get_media_list(query, 'search', mediaType, pageNumber, dispatch);
};

export const get_media_list = async (query, collection_type, mediaType, pageNumber, dispatch) => {
	try {
		dispatch(toggle_loader());
		const res = await api_requests.multiList(query);
		if (res.data.status === 200) {
			const hits = res.data.response;

			dispatch(media_list_recieved(hits));
		} else {
			show_alert('Error', `Something went wrong in getting ${collection_type} ${mediaType}`);
		}
	} catch (err) {
		show_alert('Error', `Something went wrong in getting ${collection_type} ${mediaType}`);
		// throw err;
	} finally {
		dispatch(toggle_loader());
	}
};

const media_list_recieved = (mediaList, pageNumber) => ({
	type: MEDIA_LIST_RECIEVED,
	mediaList,
	pageNumber
});

export const set_selected_media = (selectedMedia) => ({
	type: SET_SELECTED_MEDIA,
	selectedMedia
});
