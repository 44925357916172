import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paper, withStyles } from '@material-ui/core';
import Header from 'components/Header';
import Feed from './Feed/Feed';
import Footer from 'components/Footer';
import Profile from './Profile/Profile';
import CreatePost from './CreatePost/CreatePost';
import Media from './Media/Media';
import Watchlist from './Watchlist/Watchlist';
import Recommendation from './Recommendation/Recommendation';
import Second_Tab from './Second_Tab/Second_Tab';
import Search from './Search/Search';
import Activities from './Activities/Activities';
import { openLoginModal } from '../actions/loginModal_actions';

class App_With_Bottom_Menu extends Component {
	scrollView = null;

	handleChange = (e, new_value) => {
		console.log(new_value !== 'feed' || new_value !== 'recommendation', this.props.guestUser);
		if (new_value !== 'feed' && new_value !== 'recommendation' && this.props.guestUser) {
			return this.props.popLoginModal();
		}
		switch (new_value) {
			case 'feed':
				this.props.history.push('/');
				break;

			case 'create':
				this.props.history.push('/create-post');
				break;

			case 'explore':
				this.props.history.push('/explore');
				break;

			case 'watchlist':
				this.props.history.push('/watchlist');
				break;

			case 'recommendation':
				this.props.history.push('/recommendation');
				break;

			default:
				break;
		}
	};

	componentDidUpdate (prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			document.getElementById('mainPaper').scrollTo(0, 0);
		}
	}

	scrollToTop = () => {
		this.scrollView.scrollTo(0, 0);
	};

	render () {
		const { classes, user_info, guestUser } = this.props;
		let menu = '';

		if (_.isEmpty(user_info) && guestUser === false) {
			return <div></div>;
		}

		switch (this.props.location.pathname) {
			case '/':
				menu = 'feed';
				break;

			case '/profile':
				menu = 'profile';
				break;

			case '/create-post':
				menu = 'create';
				break;

			case '/watchlist':
				menu = 'watchlist';
				break;

			case '/explore':
				menu = 'explore';
				break;

			case '/recommendation':
				menu = 'recommendation';
				break;
			case '/search':
				menu = 'search';
				break;
			case '/activities':
				menu = 'activities';
				break;
			default:
				menu = 'default';
		}

		return (
			<div className={classes.app_container}>
				<Header menu={menu} guestUser={guestUser} openLoginModal={() => this.props.popLoginModal()} />
				<div
					style={{ overflowY: 'scroll', height: `calc(100% - 140px)` }}
					ref={(ref) => {
						this.scrollView = ref;
					}}>
					<Paper elevation={0} square={true} className={classes.content} id='mainPaper'>
						<Switch>
							<Route path='/' exact component={Feed} />
							<Route path='/profile/:id' exact component={Profile} />
							<Route path='/create-post' exact component={CreatePost} />
							<Route path='/movie/:id' exact component={Media} />
							<Route path='/tv-show/:id' exact component={Media} />
							<Route path='/watchlist' exact component={Watchlist} />
							<Route path='/recommendation' exact>
								<Recommendation scrollToTop={this.scrollToTop} />
							</Route>
							<Route path='/explore' component={Second_Tab} />
							<Route path='/search' component={Search} />
							<Route path='/activities' component={Search} />
						</Switch>
					</Paper>
				</div>
				<Footer
					menu={menu}
					handleChange={this.handleChange}
					guestUser={guestUser}
					openLoginModal={() => this.props.popLoginModal()}
				/>
			</div>
		);
	}
}

const STYLES = (theme) => ({
	app_container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100%',
		position: 'relative'
	},
	content: {
		minHeight: '100%',
		position: 'relative',
		width: 500,
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		},
		background: theme.palette.bg,
		margin: '0 auto',
		borderLeft: `1px solid ${theme.palette.light_grey}`,
		borderRight: `1px solid ${theme.palette.light_grey}`
	}
});

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['user_info', 'guestUser'])
});

const mapDispatchToProps = (dispatch) => ({
	popLoginModal: () => dispatch(openLoginModal())
});

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(App_With_Bottom_Menu));
