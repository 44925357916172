import axios from 'axios';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { logout, toggle_error, toggle_loader } from 'actions/actions';
import { store, store as STORE } from 'App';

const hostname = window && window.location && window.location.hostname;
//dont put slash in end
const cookies = new Cookies();
const env = {
	STAGING: 'Staging',
	PRODUCTION: 'Production'
};

const ENVIRONMENT = (() => {
	if (/localhost/i.test(hostname) || hostname === '127.0.0.1' || /qa.kunbah.com/i.test(hostname) || /vercel.app/i.test(hostname))
		return env.STAGING;
	else return env.PRODUCTION;
})();

const PRODUCTION_URL = 'https://api.kunbah.com/api/v1/';
const STAGING_URL = 'https://api.kunbah.com/api/v1/';

const POSTER_BASE_URL = 'https://image.tmdb.org/t/p/w200';

const utils = {
	//API Related,
	env,
	ENVIRONMENT,
	get_base_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_URL;
			case env.PRODUCTION:
				return PRODUCTION_URL;
		}
	},
	get_poster_base_url: () => {
		return POSTER_BASE_URL;
	},

	//requests
	request: async ({ config, show_error = true, hide_loader = true }) => {
		try {
			let res = await axios.request(config);
			if (res.status !== 200 && res.status !== 201 && res.status !== 202) {
				throw new Error('Something went wrong');
			}
			return Promise.resolve(res);
		} catch (err) {
			if (show_error) {
				STORE.dispatch(toggle_error(err));
			}
			if (hide_loader) {
				STORE.dispatch(toggle_loader());
			}
			return Promise.reject(err);
		}
	},

	//app fns
	setRequestAuthHeader: (accessToken) => {
		axios.defaults.headers.common['Authorization'] = accessToken;
	},
	saveCookies: (accessToken, id) => {
		utils._storeData('Authorization', accessToken);
		utils._storeData('id', id);
		utils.setRequestAuthHeader(accessToken);
	},
	clearCookies: () => {
		utils._removeData('Authorization');
		utils.setRequestAuthHeader('');
	},
	isAuthenticated: () => {
		return !!cookies.get('Authorization');
	},
	logout: () => {
		utils.clearCookies();
		store.dispatch(logout());
	},

	//cookie related
	_storeData: (name, value) => {
		cookies.set(name, value, { path: '/' });
	},
	_retrieveData: (name) => {
		return cookies.get(name);
	},
	_removeData: (name) => {
		return cookies.remove(name, { path: '/' });
	},

	//custom fns
	updateItemList: (list, item, action) => {
		list = list || [];
		let newList = list.slice();
		let itemIndex;
		if (action === 'UPDATE') {
			itemIndex = _.findIndex(newList, { id: item.id });
			if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
			return newList;
		} else if (action === 'ADD') {
			newList.unshift(item);
			return newList;
		} else if (action === 'DELETE') {
			_.remove(newList, item);
			return newList;
		}
		return newList;
	}
};

let accessToken = cookies.get('Authorization');
if (accessToken) {
	utils.setRequestAuthHeader(accessToken);
}
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';

switch (ENVIRONMENT) {
	case env.STAGING:
		axios.defaults.baseURL = STAGING_URL;
		break;

	case env.PRODUCTION:
		axios.defaults.baseURL = PRODUCTION_URL;
		break;
}

export default utils;
