export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const USER_INFO_RECEIVED = 'USER_INFO_RECEIVED';
export const USER_INFO_UPDATED = 'USER_INFO_UPDATED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ALERT_OPEN = 'ALERT_OPEN';
export const ALERT_CLOSE = 'ALERT_CLOSE';
export const USER_POSTS_RECEIVED = 'USER_POSTS_RECEIVED';
export const USER_POSTS_UPDATED = 'USER_POSTS_UPDATED';
export const POST_DELETED = 'POST_DELETED';

export const POST_CREATED = 'POST_CREATED';
export const POSTS_RECEIVED = 'POSTS_RECEIVED';
export const TOGGLE_POST_LIKED = 'TOGGLE_POST_LIKED';
export const TOGGLE_ADDED_TO_WATCHLIST = 'TOGGLE_ADDED_TO_WATCHLIST';
export const FEED_UPDATED = 'FEED_UPDATED';

export const WATCHLIST_RECEIVED = 'WATCHLIST_RECEIVED';
export const MEDIA_DETAIL_RECIEVED = 'MEDIA_DETAIL_RECIEVED';
export const MEDIA_COMMENTS_RECIEVED = 'MEDIA_COMMENTS_RECIEVED';
export const PROFILE_DETAIL_RECEIVED = 'PROFILE_DETAIL_RECEIVED';
export const COMMENT_CREATED = 'COMMENT_CREATED';
export const RECOMMENDATION_DATA_RECEIVED = 'RECOMMENDATION_DATA_RECEIVED';
export const PREFERRED_PROVIDERS_UPDATED = 'PREFERRED_PROVIDERS_UPDATED';
export const MEDIA_LIST_RECIEVED = 'MEDIA_LIST_RECIEVED';
export const MEDIA_LIST_RESET = 'MEDIA_LIST_RESET';
export const SET_SELECTED_MEDIA = 'SET_SELECTED_MEDIA';
export const FILTER_RESULTS_RECEIVED = 'FILTER_RESULTS_RECEIVED';
export const EXPLORE_DATA_RECEIVED = 'EXPLORE_DATA_RECEIVED';
export const CLEAR_RECOMMENDATION_DATA = 'CLEAR_RECOMMENDATION_DATA';
export const TOGGLE_FEED_LOADER = 'toggle_feed_loader';
export const SET_MOVIES = 'set_movies';
export const LOADING_MOVIES = 'loading_movies';
export const LOADING_SHOWS = 'loading_shows';
export const SET_SHOWS = 'set_shows';
export const UPDATE_EXPLORE_LIST = 'update_explore_list';
export const SEARCH_UPDATE = 'search_update';
export const REMOVE_FROM_WATCHLIST = 'remove_from_watchlist';

export const OPEN_LOGIN_MODAL = 'open_login_modal';
export const CLOSE_LOGIN_MODAL = 'close_login_modal';

export const GUEST_USER_LOGGED_IN = 'guest_user_logged_in';
export const GUEST_USER_LOGGED_OUT = 'guest_user_logged_out';
