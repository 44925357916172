import React, { Component } from 'react';
import _ from 'lodash';
import Theme from 'resources/Theme';
import { Typography, withStyles } from '@material-ui/core';

class Text extends Component {
	render() {
		const { style, component, children, semi, bold, classes, className, ...rest } = this.props;

		let fontFamily = 'Roboto';

		if (semi) {
			fontFamily = 'Roboto-Medium';
		}
		if (bold) {
			fontFamily = 'Roboto-Bold';
		}

		return (
			<Typography
				component={component || 'span'}
				style={{
					fontFamily,
					...(style || {})
				}}
				className={`${className ? className : ''} ${classes.text}`}
				{...rest}>
				{children}
			</Typography>
		);
	}
}

const STYLES = (theme) => ({
	text: {
		fontSize: theme.font_size.Body,
		color: theme.palette.black
	}
});

export default withStyles(STYLES)(Text);
