import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import { get_profile_details } from 'actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import Post from 'components/Post';
import PageTrackingWithPath from '../../components/pageViewTrackerWithPath';
const profile_bg = '/assets/images/profile.png';
const settings = '/assets/images/settings.svg';

const Profile = (props) => {
	const { classes } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(get_profile_details(props.match.params.id));
	}, [props.match.params.id]);

	useEffect(() => {
		PageTrackingWithPath(`/profile/${props.match.params.id}`);
	}, []);
	const { profile_detail, user_info, user_posts, loader } = useSelector((state) => ({
		..._.pick(state.App, ['profile_detail', 'user_info', 'user_posts', 'loader'])
	}));

	if (_.isEmpty(profile_detail)) {
		return null;
	}
	const isMyProfile = profile_detail?.email === user_info?.email;

	return (
		<div>
			<img src={profile_bg} style={{ width: '100%' }} loading='lazy' />
			<div className={classes.profile_container}>
				<div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
					<div style={{ position: 'absolute', top: -35, margin: '0 auto' }}>
						<Avatar
							fullname={profile_detail.name}
							style={{ height: 60, width: 60, borderRadius: 30 }}
							textStyles={{ fontSize: 20 }}
						/>
					</div>
				</div>
				<div style={{ width: '100%', textAlign: 'center', marginTop: 25 }}>
					<div style={{ position: 'relative' }}>
						<Text style={{ fontSize: 30 }}>{_.capitalize(profile_detail.name)}</Text>
						{/* <IconButton style={{ position: 'absolute', right: '7%' }}>
							<img src={settings} />
						</IconButton> */}
					</div>
					{/* <Text  semi component={'p'} className={classes.follow_text_title}>
						Brad Pitt movie fan
					</Text> */}
				</div>
				<div className={classes.follow_container}>
					<div className={classes.follow_text_container}>
						<Text className={classes.follow_text_title}>Post</Text>
						<Text className={classes.follow_text_content}>{user_posts.length}</Text>
					</div>
					<div className={classes.follow_text_container}>
						<Text className={classes.follow_text_title}>Followers</Text>
						<Text className={classes.follow_text_content}>0</Text>
					</div>
					<div className={classes.follow_text_container}>
						<Text className={classes.follow_text_title}>Following</Text>
						<Text className={classes.follow_text_content}>0</Text>
					</div>
				</div>
			</div>
			{_.map(user_posts, (v, i) => {
				return <Post data={v} key={`post${i}`} profile={true} isMyProfile={isMyProfile} />;
			})}
		</div>
	);
};

const STYLES = (theme) => ({
	profile_container: {
		background: theme.palette.white,
		borderBottom: `1px solid ${theme.palette.light_grey}`
	},
	follow_container: {
		width: '90%',
		margin: '0 auto',
		marginTop: 10,
		borderTop: `1px solid ${theme.palette.light_grey}`,
		paddingTop: 10,
		paddingBottom: 10,
		display: 'flex',
		justifyContent: 'space-between'
	},
	follow_text_container: {
		display: 'flex',
		alignItems: 'center'
	},
	follow_text_title: {
		color: theme.palette.light_grey2,
		fontSize: theme.font_size.Subtitle
	},
	follow_text_content: {
		fontSize: theme.font_size.H5,
		marginLeft: 10
	}
});

export default withStyles(STYLES)(Profile);
