import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles, Card } from '@material-ui/core';
import PageTrackingWithPath from 'components/pageViewTrackerWithPath';

class Terms extends Component {
	componentDidMount () {
		PageTrackingWithPath('/policy');
	}
	render () {
		const { classes } = this.props;
		return (
			<div className={classes.container}>
				<Card className={classes.card} elevation={0}>
					<b>Terms and conditions of use</b>
					<br />
					<br />
					By accessing and using Kunbah.com and any other site, application, API or embedded content owned or operated by
					Kunbah Technologies LLP (the “Service”), you accept and agree to be bound by the following terms and conditions
					(“Terms”):
					<br />
					<br />
					1. Use: You may only use the Service in accordance with these Terms. All rights not expressly granted to you in
					these Terms are reserved by us.
					<br />
					<br />
					2. Responsibility: You will be responsible for all activity that occurs as a result of your use of the Service. We
					disclaim any and all liability (including for negligence) for the content, opinions, statements or other
					information posted to, or use of, the Service by its users.
					<br />
					<br />
					3. Provision of information: In order to use the services provided on the Service, you must be at least 18 years
					of age. When you register to use the Service, you agree to provide true, accurate, current and complete
					information about yourself as prompted by the Service (“Registration Information”), and to maintain and promptly
					update your Registration Information in order to ensure that it remains true, accurate, current and complete.
					<br />
					<br />
					4. Community policy: You must be courteous and respectful of others’ opinions, and you must not post unwelcome,
					aggressive, suggestive or otherwise inappropriate remarks directed at another member of the Service.
					<br /> <br />
					5. Conduct: You must not use the Service to promote, engage in or incite hate, violence, discrimination or
					intolerance, including based on race, age, gender, gender identity, ethnicity, religion, disability, sexual
					orientation or other protected attribute. We reserve the right to remove content that has the potential to harm
					communities we consider worthy of protection. You must not use the Service (or encourage others to use the
					Service) to create multiple accounts, deceive or mislead other users, disrupt discussions, circumvent account
					blocks, game the Service’s mechanics, alter consensus, participate in orchestrated attacks against films or
					filmmakers, post spam or otherwise violate our  
					<u>
						<a href={'https://letterboxd.com/legal/community-policy/'}>community policy</a>
					</u>
					.<br />
					<br />
					6. No malicious use: You agree to access the Service through the interface we provide. You must not use the
					Service for any malicious means, or abuse, harass, threaten, intimidate or impersonate any other user of the
					Service. You must not request or attempt to solicit personal or identifying information from another member of the
					Service.
					<br />
					<br />
					7. No illegal use: You must not use the Service for any unlawful purpose, or post any information that is in
					breach of any confidentiality obligation, copyright, trade mark or other intellectual property or proprietary
					rights of any person.
					<br />
					<br />
					8. Removal of content: We reserve the right to remove any content posted to the Service that we consider (in our
					absolute discretion) to be offensive, objectionable, unlawful, explicit, graphic or otherwise in breach of these
					Terms, including content that expressly (or implicitly, through coded language, symbol, keywords or tags) praises,
					supports, promotes or represents radical ideologies. We reserve the right to remove any content posted to the
					Service that disseminates misinformation and its related manipulations, including viral sloganeering.
					<br />
					<br />
					9. Fees: We may charge subscription fees for the use of certain services offered on the Service (“Fees”). We may
					change the amount of Fees payable from time to time. We will communicate any changes in Fees, and any changes will
					only take effect at the end of your current subscription period. If you cancel your account, you will not be
					entitled to a refund for any Fees you have already paid.
					<br />
					<br />
					10. Intellectual property: You agree that we own all of the intellectual property rights in the Service. You grant
					us a non-exclusive, royalty-free, irrevocable, perpetual and sub-licensable right to use, reproduce, distribute
					and display (including for commercial purposes) on the Service and in other media any content or material that you
					post on the Service, and any name(s) and/or avatar under which you post such content. Other than this right, we
					claim no intellectual property rights in relation to the information or content that you upload onto the Service.
					Any content you post to the Service should be original (not plagiarized from another person’s writing) and must
					not infringe anyone else’s intellectual property rights. Where you are referencing someone else’s intellectual
					property, you must make this clear by naming the quoted material’s author and through the use of identifiers such
					as quotation marks, links to source material, or other means.
					<br />
					<br />
					11. Indemnity: You indemnify, and will keep indemnified, us against all forms of liability, actions, proceedings,
					demands, costs, charges and expenses which we may howsoever incur or be subject to or suffer as a result of the
					use by you of the Service.
					<br />
					<br />
					12. Amendments: We reserve the right to amend these Terms at any time, including by changing the amount of any
					Fees payable for any of our services, and may also add new features that will be subject to these Terms. If these
					changes are material we will communicate the changes to users, and by continuing to use the Service, you will be
					taken to have agreed to the changes.
					<br />
					<br />
					13. Third-party applications: We may provide a platform for third parties’ applications, websites and services to
					make products and services available to you (“Third Party Applications”) and your use of any Third-Party
					Applications will be subject to their terms of use. You agree that we will not be liable for the behaviour,
					features or content of any Third-Party Applications.
					<br />
					<br />
					14. Termination or suspension of accounts: If you do not abide by these Terms, we may suspend or terminate your
					account.
					<br />
					<br />
					15. Technical support and malfunctions: We will try to promptly address (during normal business hours) all
					technical issues that arise on the Service. However, we will not be liable for any loss suffered as a result of
					any partial or total breakdown of the Service or any technical malfunctions.
					<br />
					<br />
					16. Governing law and jurisdiction: Our Services and Terms are compliant with the Indian Penal Code, 1860, and
					Information Technology Act, 2000, including all amendments made to it and rules framed under it. When you create
					an account on our Platform or use our Platform or any of our Services, you accept and agree to these Terms.
					However, please note that we do not represent that we are compliant with laws of any country apart from India. If
					you wish to use our Services, please ensure that you are permitted to do so, in your jurisdiction. All users of
					the Service agree that the laws of India shall govern and apply to these Terms and each user’s use of the Service,
					and all users submit to the exclusive jurisdiction of the Indian courts for any matter or dispute arising in
					relation to these Terms.
					<br />
					<br />
				</Card>
			</div>
		);
	}
}

const STYLES = (theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		justifyContent: 'space-between',
		flexDirection: 'column',
		overflow: 'scroll',
		letterSpacing: 0.3,
		textAlign: 'left'
	},
	card: {
		width: 380,
		[theme.breakpoints.down('xs')]: {
			width: '90%'
		},
		margin: '0 auto',
		borderRadius: 0
	}
});

export default withStyles(STYLES)(Terms);
