import ReactGA from 'react-ga';
ReactGA.initialize('UA-221444152-1', {
	titleCase: false
});

const PageTracking = (props) => {
	const pathName = props.match.path;

	let pageView;
	if (pathName === '*') pageView = '/not-found';
	else pageView = pathName;
	console.log(pathName);
	ReactGA.pageview(pageView);
};

export default PageTracking;
