import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { search_media, set_selected_media } from 'actions/actions';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import utils from 'resources/utils.js';
import Youtube from 'react-youtube';
import { makeStyles } from '@material-ui/core';
import IMAGES from 'resources/images';
import PageTracking from 'components/pageViewTracker';

const useStyles = makeStyles((theme) => ({
	videoContainer: {
		height: '50%',
		'&  >div': {
			height: '100%'
		},
		'&  iframe': {
			width: '100%',
			height: '100%'
		}
	}
}));

/**
 *
 * @param {query, size, joins} props
 */
export default function Second_Tab (props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	useEffect(() => {
		PageTracking(props);
	}, []);
	const { mediaList, pageNumber, selectedMedia, loader, total = 50 } = useSelector((state) => ({
		..._.pick(state.App.secondTab, ['mediaList', 'pageNumber', 'selectedMedia']),
		..._.pick(state.App, 'loader')
	}));

	const size = props.size || 40;
	const joins = props.joins || {
		'videos.site': 1,
		'videos.key': 1,
		'videos.id': 1,
		'videos.name': 1,
		poster_path: 1
	};

	const hasMorePosts = mediaList.length !== total;
	const query = props.query || { ...defaultQuery, from: mediaList.length };

	const [sentryRef, { rootRef }] = useInfiniteScroll({
		loading: !hasMorePosts ? false : loader,
		hasNextPage: hasMorePosts,
		onLoadMore: () => {
			// props.get_posts(mediaList.length);

			dispatch(search_media('movie', query));
		},
		disabled: !hasMorePosts,
		rootMargin: '0px 0px 4px 0px'
	});

	const videoToShow = selectedMedia?._source.videos.filter((value, index) => {
		if (value.type === 'Trailer') {
			return value;
		}
	});
	//Function to change the selected media to next media on current video end
	function shiftSelectedMedia () {
		for (let i = 0; i < mediaList.length; i++) {
			const media = mediaList[i];
			if (media._id === selectedMedia._id) {
				if (i < mediaList.length - 1) {
					dispatch(set_selected_media(mediaList[i + 1]));
				} else {
					dispatch(set_selected_media(null));
				}
				break;
			}
		}
	}
	const ytOpts = {
		height: 390,
		width: 640,
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 1,
			controls: 1,
			autohide: 1
		}
	};

	return (
		<div style={{ height: '100%' }}>
			<div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
				{videoToShow && (
					<div className={classes.videoContainer}>
						<Youtube onError={shiftSelectedMedia} videoId={videoToShow[0].key} onEnd={shiftSelectedMedia} opts={ytOpts} />
					</div>
				)}

				<div
					ref={rootRef}
					style={{
						textAlign: 'center',
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						height: '50%',
						overflow: 'scroll'
					}}>
					{mediaList.map((media) => {
						if (!media) {
							return;
						}
						const poster_path = _.get(media, '_source.poster_path');
						const posterUrl = poster_path ? `${utils.get_poster_base_url()}/${poster_path}` : IMAGES.default_poster;
						const handleClick = (e) => {
							e.preventDefault();
							//Set selectedMedia to this media
							dispatch(set_selected_media(media));
						};
						const isSelectedMedia = selectedMedia && media._id === selectedMedia._id;
						const style = { width: '24%', height: 140, borderRadius: 2, padding: 1 };
						if (isSelectedMedia) {
							style.border = '1px solid red';
						}
						return <img src={posterUrl} style={style} onClick={handleClick} loading='lazy' />;
					})}
					<div ref={sentryRef} className='feed-loader'>
						{hasMorePosts ? <span>Loading...</span> : <span>No More Posts</span>}
					</div>
				</div>
			</div>
		</div>
	);
}

const handleYTError = (err) => {
	if ([101, 150].includes(err.data)) {
	}
};

// Default query and joins for loading the page if no query is passed
const joins = {
	popularity: 1,
	vote_average: 1,
	poster_path: 1,
	title: 1,
	videos: 1,
	runtime: 1,
	genres: {
		name: 1
	}
};
const defaultQuery = {
	queries: [
		{
			_type: 'movie',
			query: {
				bool: {
					must: [
						{
							exists: {
								field: 'videos.id'
							}
						},
						{
							exists: {
								field: 'trending-now._id'
							}
						}
					]
				}
			},
			sort: {
				popularity: 'desc'
			},
			size: 5,
			joins: joins
		},
		{
			_type: 'tv-show',
			query: {
				bool: {
					must: [
						{
							exists: {
								field: 'videos.id'
							}
						},
						{
							exists: {
								field: 'trending-now._id'
							}
						}
					]
				}
			},
			sort: {
				popularity: 'desc'
			},
			size: 5,
			joins: joins
		},
		{
			_type: 'movie',
			query: {
				bool: {
					must: [
						{
							exists: {
								field: 'videos.id'
							}
						},
						{
							exists: {
								field: 'now-playing._id'
							}
						}
					]
				}
			},

			size: 5,
			joins: joins
		},
		{
			_type: 'movie',
			sort: {
				popularity: 'desc'
			},
			query: {
				exists: {
					field: 'videos.id'
				}
			},
			size: 5,
			joins: joins
		},
		{
			_type: 'tv-show',
			query: {
				exists: {
					field: 'videos.id'
				}
			},
			sort: {
				popularity: 'desc'
			},
			size: 5,
			joins: joins
		}
	],
	merging: {
		algorithm: 'algo_name',
		params: {
			ratio: '1:1:1:1:1'
		}
	},

	from: 0,
	size: 25
};
