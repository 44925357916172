import React, { Component } from 'react';
import Text from './Text';
import utils from 'resources/utils';

export default class Qa_Env_Header extends Component {
	render() {
		const env = utils.ENVIRONMENT;

		if (env === utils.env.STAGING) {
			return (
				<div
					style={{
						backgroundColor: 'orange',
						height: 30,
						zIndex: 100,
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}>
					<Text style={{ color: 'white' }}>QA Build</Text>
				</div>
			);
		} else {
			return null;
		}
	}
}
