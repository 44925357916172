import { IconButton, makeStyles } from '@material-ui/core';
import { get_media_details } from 'actions/actions';
import Star_Rating_Toggle from 'components/Star_Rating_Toggle';
import Text from 'components/Text';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IMAGES from 'resources/images';
import utils from 'resources/utils';
import MediaCard from 'screens/Recommendation/MediaCard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Theme from 'resources/Theme';
import { toggle_add_to_watchlist } from 'actions/watchlist_actions';
import Seasons from './Seasons/seasons';
import { connect } from 'react-redux';
import moment from 'moment';
import Avatar from 'components/Avatar';
import { openLoginModal } from 'actions/loginModal_actions';
import PageTrackingWithPath from '../../components/pageViewTrackerWithPath';
const MediaView = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const mediaType = props.location.pathname.split('/')[1];
	const { media_detail, loader, media_comments, watchlist_movies } = useSelector((state) => ({
		..._.pick(state.App, ['media_detail', 'loader', 'media_comments']),
		..._.pick(state.Watchlist, ['watchlist_movies'])
	}));
	const [playTrailer, setPlayTrailer] = useState(false);
	const [showMenu, setShowMenu] = useState(true);

	const mediaId = props.match.params.id;

	useEffect(() => {
		dispatch(get_media_details(mediaType === 'movie' ? 'movie' : mediaType, mediaId, mediaType));
	}, [mediaId]);

	// useEffect(() => {
	// 	PageTrackingWithPath(`/${mediaType}/${mediaId}`);
	// }, []);

	const handle_add_to_wishlist = () => {
		if (props.guestUser) {
			return props.openLoginModal();
		} else {
			const added_to_wishlist = _.findIndex(watchlist_movies, { _id: mediaId }) > -1;
			dispatch(
				toggle_add_to_watchlist(
					{ _id: mediaId, _source: media_detail },
					added_to_wishlist,
					media_detail._type === 'movie' ? 'movie' : 'tv-show'
				)
			);
		}
	};

	const myRef = useRef(null);

	if (_.isEmpty(media_detail) || loader) {
		return null;
	}

	let genre = '';

	_.map(media_detail.genres, (v, i) => {
		if (i !== 0) {
			genre += ',';
		}
		genre += _.get(v, 'fields.name');
	});

	const poster = _.get(media_detail, 'poster_path')
		? utils.get_poster_base_url() + _.get(media_detail, 'poster_path')
		: IMAGES.default_poster;

	return (
		<div className={classes.container}>
			<div style={{ position: 'relative' }}>
				{!playTrailer && (
					<div
						style={{
							backgroundImage: `url(${poster}`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							filter: 'blur(4px)',
							height: 250
						}}
					/>
				)}
				{!playTrailer && (
					<div style={{ display: 'flex' }}>
						<img
							src={poster}
							style={{ height: 248, position: 'absolute', top: 0, left: 0, right: 0, margin: '0 auto' }}
							loading='lazy'
						/>
						<button
							style={{
								position: 'absolute',
								top: 120,
								left: 220,
								right: 0,
								height: 34,
								width: 34,
								borderWidth: 0,
								zIndex: 100,
								background: 'transparent'
							}}
							onClick={() => setPlayTrailer(!playTrailer)}>
							<img src={IMAGES.playBtn} style={{ height: 34, width: 34, background: 'transparent' }}></img>
						</button>
					</div>
				)}
				{playTrailer && (
					<div style={{ width: '105%', marginLeft: '-2.5%', marginTop: 10 }}>
						<iframe
							width='100%'
							height='200'
							src={`https://www.youtube.com/embed/${_.get(media_detail, 'videos[0].key')}`}></iframe>
					</div>
				)}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
				{_.map(media_detail.genres, (v, i) => {
					return (
						<button
							style={{
								paddingRight: 3,
								paddingLeft: 3,
								paddingTop: 1,
								paddingBottom: 1,
								borderRadius: 16,
								borderWidth: 0.8,
								borderColor: 'black',
								backgroundColor: '#f5f5f5',
								marginLeft: 8,
								fontSize: 10,
								width: 60
							}}>
							{_.get(v, 'fields.name')}
						</button>
					);
				})}
				{media_detail?.vote_average && (
					<button
						style={{
							paddingRight: 3,
							paddingLeft: 3,
							paddingTop: 1,
							paddingBottom: 1,
							borderRadius: 16,
							borderWidth: 0.8,
							borderColor: 'black',
							backgroundColor: '#f5f5f5',
							marginLeft: 8,
							fontSize: 10,
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'space-evenly',
							alignItems: 'center',
							width: 60
						}}
						disabled={true}>
						<img src={IMAGES.star_filled} style={{ width: 12, height: 12 }} />
						<Text style={{ maringRight: 8 }}>{media_detail?.vote_average}</Text>
					</button>
				)}
			</div>
			<Text semi component='p' style={{ fontSize: 28, marginTop: 10 }}>
				{media_detail.original_title || media_detail.title}
			</Text>
			<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', marginTop: 16 }}>
				<button
					style={{
						width: '50%',
						backgroundColor: 'white',
						borderWidth: 0,
						fontSize: 24,
						fontWeight: '500',
						borderBottomWidth: 3,
						borderBottomColor: showMenu ? 'red' : 'transparent',
						borderBottomLeftRadius: 4,
						borderBottomRightRadius: 4,
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'row',
						display: 'flex'
					}}
					onClick={() => setShowMenu(true)}>
					<div>Activity</div>
					<button
						style={{
							fontSize: 14,
							borderWidth: 0.7,
							borderColor: 'black',
							backgroundColor: 'transparent',
							borderRadius: 18,
							width: 62,
							paddingRight: 10,
							paddingLeft: 10,
							paddingTop: 4,
							paddingBottom: 4,
							marginLeft: 10
						}}>
						{media_comments?.hits?.length}
					</button>
				</button>
				<button
					style={{
						width: '50%',
						backgroundColor: 'white',
						borderWidth: 0,
						fontSize: 24,
						fontWeight: '500',
						borderBottomWidth: 3,
						borderBottomColor: !showMenu ? 'red' : 'transparent',
						borderBottomLeftRadius: 4,
						borderBottomRightRadius: 4,
						padding: 10
					}}
					onClick={() => setShowMenu(false)}>
					Details
				</button>
			</div>
			{!showMenu && (
				<div style={{ width: '95%', justifyContent: 'center', alignItems: 'center' }}>
					<div style={{ width: '100%', margin: '0 auto' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
							{_.map(
								[
									{
										image: IMAGES.wishlist,
										checkedImage: IMAGES.wishlist_filled,
										data: (media_detail.watchList || []).length,
										checked: _.findIndex(watchlist_movies, { _id: mediaId }) !== -1
									},
									{
										image: IMAGES.eye,
										data: 0
									},

									{
										component: Star_Rating_Toggle
									}
								],
								(v, i) => {
									return (
										<div
											key={`item122${i}`}
											style={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
												alignItems: 'center'
											}}>
											{v.component ? (
												<Star_Rating_Toggle
													mediaName={media_detail.original_title || media_detail.title}
													mediaId={mediaId}
													mediaType={mediaType}
													media_detail={media_detail}
													guestUser={props.guestUser}
													handleGuestLoginModal={() => props.openLoginModal()}
												/>
											) : (
												<IconButton onClick={handle_add_to_wishlist}>
													<img
														src={v.checked ? v.checkedImage : v.image}
														style={{ width: 30, height: 30 }}
														loading='lazy'
													/>
												</IconButton>
											)}
											<Text>{v.data}</Text>
										</div>
									);
								}
							)}
						</div>
					</div>
					<div style={{ marginTop: 20, justifyContent: 'center', alignContent: 'center' }}>
						<Text component='p' style={{ marginTop: 10, alignSelf: 'center' }}>
							{media_detail.overview}
							{console.log(media_detail)}
						</Text>
					</div>
					<div style={{ marginTop: 20 }}>
						<Text bold>Actor</Text>
						<div style={{ display: 'flex', justifyContent: 'space-between', overflowX: 'scroll', marginTop: 10 }}>
							{_.map(media_detail.cast, (v, i) => {
								console.log(v);
								if (_.get(v, 'fields.profile_path'))
									return (
										<div
											key={`item2323${i}`}
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												flexShrink: 0,
												paddingRight: 20
											}}>
											<div>
												<img
													src={
														_.get(v, 'fields.profile_path')
															? utils.get_poster_base_url() + _.get(v, 'fields.profile_path')
															: ''
													}
													style={{ width: 120, borderRadius: 8 }}
												/>
												<Text semi component='p' style={{ color: '#DE0418', alignSelf: 'center' }}>
													{_.get(v, 'fields.name')}
												</Text>
											</div>
											{/* <div>
										<Text semi component='p'>
											{_.get(v, 'fields.name')}
										</Text>
										
									</div> */}
										</div>
									);
							})}
						</div>
					</div>
					{media_detail.watch_providers && (
						<div
							style={{
								background: '#F5F5F5',
								padding: 20,
								marginTop: 10,
								width: '100%',
								paddingLeft: '5%'
							}}>
							{<Text>WATCH NOW</Text>}
							<div style={{ display: 'flex', marginTop: 10 }}>
								{media_detail.watch_providers.map((provider, i) => {
									const srcPath = provider?.fields?.logo_path
										? utils.get_poster_base_url() + provider?.fields?.logo_path
										: '';
									return (
										<img
											src={srcPath}
											onClick={() => {
												window.open(
													`https://www.primevideo.com/region/eu/search/ref=atv_nb_sr?phrase=${media_detail.original_title ||
														media_detail.title}&ie=UTF8`
												);
											}}
											style={{ width: 50, marginLeft: i && 15 }}
										/>
									);
								})}
							</div>
						</div>
					)}
					{_.get(media_detail, 'seasons') ? <Seasons seasonsMetaData={{ data: _.get(media_detail, 'seasons') }} /> : <></>}
					<div style={{ width: '95%', margin: '0 auto', marginTop: 20 }}>
						{_.map(
							[
								{
									title: 'Rating',
									data: (
										<div style={{ display: 'flex', justifyContent: 'space-between', width: 80 }}>
											<img src={IMAGES.tmdb} width='50' />
											<Text>{media_detail?.vote_average}</Text>
										</div>
									)
								},
								{
									title: 'Story',
									data: genre
								},
								{
									title: 'Runtime',
									data:
										media_detail.runtime &&
										`${Math.trunc(media_detail.runtime / 60)}h ${media_detail.runtime % 60}mins`
								},
								{
									title: 'Director',
									data: _.get(media_detail, 'crew[0].original_name', null)
								}
							],
							(v, i) => {
								if (!v.data) {
									return null;
								}
								return (
									<div
										key={`item2${i}`}
										style={{
											display: 'flex',
											marginTop: 10
										}}>
										<Text semi style={{ width: 100 }}>
											{v.title}
										</Text>
										<Text>{v.data}</Text>
									</div>
								);
							}
						)}

						{!_.isEmpty(media_detail[`similar-${mediaType}s`]) && (
							<div style={{ marginTop: 20, marginBottom: 20 }}>
								<Text bold>More like this</Text>
								<div style={{ marginTop: 10, position: 'relative' }}>
									<div style={{ display: 'flex', overflowX: 'scroll', scrollBehavior: 'smooth' }} ref={myRef}>
										{_.map(media_detail[`similar-${mediaType}s`], (v, i) => {
											return (
												<MediaCard
													key={`item4${i}`}
													media={{
														_id: v._id,
														_source: v.fields,
														_type: mediaType
													}}
													style={{ ...(i !== 0 && { marginLeft: 10 }) }}
												/>
											);
										})}
									</div>
									<div
										style={{
											position: 'absolute',
											left: 0,
											top: 0,
											bottom: 0,
											zIndex: 1000,
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer'
										}}
										onClick={() => (myRef.current.scrollRight += 150)}>
										<ChevronLeftIcon style={{ color: Theme.palette.primary.main, fontSize: 40 }} />
									</div>
									<div
										style={{
											position: 'absolute',
											right: 0,
											top: 0,
											bottom: 0,
											zIndex: 1000,
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer'
										}}
										onClick={() => {
											myRef.current.scrollLeft += 150;
										}}>
										<ChevronRightIcon style={{ color: Theme.palette.primary.main, fontSize: 40 }} />
									</div>
								</div>
							</div>
						)}

						{/* <div style={{ marginTop: 20 }}>
					<Text bold >
						Top 5 movies
					</Text>
					{_.map(['Tenet', 'Charlie', 'Wonder Woman 1984', 'Godzilla: King of the Monsters Master'], (v, i) => {
						return (
							<Text semi component='p' style={{ marginTop: 10, color: '#DE0418' }} key={`item7${i}`}>
								{v}
							</Text>
						);
					})}
				</div>

				<div style={{ marginTop: 30 }}>
					<Text bold >
						Top 5 TV Shows
					</Text>
					{_.map(['WandaVision', 'Scam 1992', 'Attack on Titan', 'Gullak', 'Seinfeld'], (v, i) => {
						return (
							<Text semi component='p' style={{ marginTop: 20, color: '#DE0418' }} key={`item8${i}`}>
								{v}
							</Text>
						);
					})}
				</div>

				<div style={{ marginTop: 30, paddingBottom: 40 }}>
					<Text bold >
						Top 5 providers
					</Text>
					{_.map(['Netflix', 'Amazon Prime Video', 'Hotstar', 'Voot', 'Jio Cinema'], (v, i) => {
						return (
							<Text semi component='p' style={{ marginTop: 20, color: '#DE0418' }} key={`item9${i}`}>
								{v}
							</Text>
						);
					})}
				</div> */}
					</div>
				</div>
			)}
			{showMenu &&
				(!_.isEmpty(media_comments) ? (
					<>
						{_.map(media_comments?.hits, (v, i) => {
							if (i > 2) {
								return true;
							}
							const getDuration = (time) => {
								const diff = moment(time).fromNow();
								return diff;
							};

							let name = v._source?.user?.fields?.name;
							let commentText = v?._source?.text;
							const now = moment(new Date());
							const commentAt = v?._source?._createdAt ? getDuration(v?._source?._createdAt, now) : '';

							return (
								<div key={`item${i}`} style={{ marginTop: 5, width: '95%' }}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Avatar
											fullname={name}
											style={{ height: 28, width: 28, borderRadius: 14 }}
											textStyles={{ fontSize: 20 }}
										/>
										<Text bold style={{ whiteSpace: 'nowrap', paddingLeft: 5 }}>
											{name}:
										</Text>
									</div>
									<div style={{ marginLeft: 5, paddingLeft: 32 }}>
										<Text style={{ wordBreak: 'break-word', color: 'grey' }}>{commentText ?? ''}</Text>
										{/* {commentText.length > 50 && showFullCommentText !== i && (
												<Text className={classes.showMore} onClick={() => setShowFullCommentText(i)}>
													more
												</Text>
											)} */}
									</div>
									<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 8 }}>
										<Text
											component='p'
											style={{ color: 'grey', paddingRight: 28 }}
											className={classes.commentTime}>
											{commentAt}
										</Text>
									</div>
								</div>
							);
						})}
						{media_comments.length > 2 && (
							<Text component='p' className={classes.showMore} onClick={() => history.push('/activities')}>
								See all activities
							</Text>
						)}
					</>
				) : (
					<div>No activities</div>
				))}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		background: theme.palette.white
	}
}));

const mapStateToProps = (state) => {
	const { guestUser } = state.App;
	return {
		guestUser
	};
};
const mapDispatchToProps = {
	openLoginModal
};
const Media = connect(mapStateToProps, mapDispatchToProps)(MediaView);

export default Media;
