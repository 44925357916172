import React from 'react';
import _ from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Text from 'components/Text';
import MediaCard from './MediaCard';

const MovieList = (props) => {
	const { title, list, viewAll, setViewAll, index } = props;

	return (
		<div style={{ marginTop: 10 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Text bold style={{ color: '#4D536F' }}>
					{title}
				</Text>
				<Text semi style={{ color: '#DE0418', cursor: 'pointer' }} onClick={() => setViewAll(viewAll ? false : index)}>
					{viewAll ? 'Clear' : 'View all'}
				</Text>
			</div>
			<div
				style={{
					display: 'flex',
					...(viewAll ? { flexWrap: 'wrap', justifyContent: 'space-between' } : { overflow: 'scroll' }),
					marginTop: 10,
				}}>
				{_.map(list, (v, i) => {
					
					return (
						<MediaCard
							key={`item${i}`}
							media={v}
							viewAll={viewAll}
							style={{
								...(viewAll ? { marginTop: 10, flex: '0 0 45%', height: 220 } : i !== 0 && { marginLeft: 20 })
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default MovieList;
