import utils from 'resources/utils';

export default {
	request_login: (form_data) => {
		return utils.request({
			config: {
				url: `users/login`,
				method: 'POST',
				data: {
					email: form_data.username,
					password: form_data.password
				}
			}
		});
	},
	request_signup: (form_data) => {
		return utils.request({
			config: {
				url: `users/register`,
				method: 'POST',
				data: {
					...form_data,
					// inviteCode: parseInt(form_data.inviteCode),
					username: form_data.name + Math.round(Math.random() * 1000)
				}
			}
		});
	},
	request_welcome_info: (welcome_data) => {
		return utils.request({
			config: {
				url: `users/subscribe`,
				method: 'POST',
				data: {
					email: welcome_data.email
				}
			}
		});
	},
	get_user_details: () => {
		return utils.request({
			config: {
				url: `users/details`,
				method: 'GET'
			}
		});
	},
	search: (data) => {
		return utils.request({
			config: {
				url: 'entity/search',
				method: 'POST',
				data
			},

			hide_loader: false
		});
	},
	multiList: (data) => {
		return utils.request({
			config: {
				url: 'entity/multi-list-merge',
				method: 'POST',
				data
			}
		});
	},
	get_recommendation: (data) => {
		return utils.request({
			config: {
				url: 'entity/multi-list-recommendation',
				method: 'POST',
				data
			}
		});
	},
	get_posts: (from) => {
		return utils.request({
			config: {
				url: 'entity/search',
				method: 'POST',
				data: {
					_type: 'userpost',
					joins: {
						text: 1,
						_createdAt: 1,
						movies: {
							title: 1,
							poster_path: 1,

							original_language: 1,
							original_title: 1,
							overview: 1,
							tagline: 1,
							vote_average: 1,
							release_date: 1,
							runtime: 1,
							genres: {
								name: 1
							}
						},
						'tv-shows': {
							title: 1,
							poster_path: 1,
							backdrop_path: 1,
							original_language: 1,
							original_title: 1,
							overview: 1,
							tagline: 1,
							vote_average: 1,
							release_date: 1,
							runtime: 1,
							genres: {
								name: 1
							}
						},
						user: { email: 1, name: 1, generatedUsername: 1 },
						likedBy: { email: 1, name: 1, _id: 1, generatedUsername: 1 },
						comments: {
							text: 1,
							commentBy: {
								name: 1,
								email: 1
							},
							_createdAt: 1
						}
					},
					from: from || 0,
					size: 10,
					sort: {
						_createdAt: 'desc'
					}
				}
			}
		});
	},
	create: (data) => {
		return utils.request({
			config: {
				url: 'entity/create',
				method: 'POST',
				data
			}
		});
	},
	update: (data) => {
		return utils.request({
			config: {
				url: 'entity/update',
				method: 'PUT',
				data
			}
		});
	},
	create_post: (data) => {
		return utils.request({
			config: {
				url: 'entity/create',
				method: 'POST',
				data
			}
		});
	},
	create_comment: (data) => {
		return utils.request({
			config: {
				url: 'entity/create',
				method: 'POST',
				data
			},
			hide_loader: false
		});
	},
	post_link_activity: (data, hide_loader = true) => {
		return utils.request({
			config: {
				url: 'entity/link',
				method: 'POST',
				data
			},
			hide_loader
		});
	},
	post_unlink_activity: (data) => {
		return utils.request({
			config: {
				url: 'entity/unlink',
				method: 'POST',
				data
			}
		});
	},
	get_watchlist: (id) => {
		return utils.request({
			config: {
				url: `users/list/${id}/watchList`,
				method: 'GET',
				params: {
					joins: {
						name: 1
					}
				}
			}
		});
	},
	read_data: (data) => {
		return utils.request({
			config: {
				url: 'entity/read',
				method: 'POST',
				data
			}
		});
	},
	get_user_posts: (id) => {
		return utils.request({
			config: {
				url: `users/list/${id}/userpost`,
				method: 'GET',
				params: {
					joins: 'read'
				}
			}
		});
	},
	multiquery: (data, hide_loader = true) => {
		return utils.request({
			config: {
				url: `entity/multiquery`,
				method: 'POST',
				data
			},
			hide_loader
		});
	},
	updateQuery: (data) => {
		return utils.request({
			config: {
				url: `entity/update`,
				method: 'PUT',
				data
			}
		});
	},
	deleteQuery: (data) => {
		return utils.request({
			config: {
				url: `entity/delete`,
				method: 'DELETE',
				data
			}
		});
	},
	getNotifications: (data) => {
		return utils.request({
			config: {
				url: 'entity/notification-tab',
				method: 'POST',
				data
			}
		});
	}
};
