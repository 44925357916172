import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import Text from 'components/Text';
import MovieList from './MovieList';
import { useSelector } from 'react-redux';
import { getMovies, getShows } from '../../actions/recommendation_actions';

import SingleItemList from './SingleItemList';

const ChefSpecialView = (props) => {
	const [viewAll, setViewAll] = React.useState(null);

	const { chef_special_movies, chef_special_shows, loadingMovies, totalMovies, loadingShows, totalShows } = props;

	switch (viewAll) {
		case 1:
			return (
				<SingleItemList
					title='Movies'
					list={chef_special_movies}
					viewAll={viewAll}
					setViewAll={setViewAll}
					index={1}
					loading={loadingMovies}
					total={totalMovies}
					getMoreData={props.getMovies}
				/>
			);
		case 2:
			return (
				<SingleItemList
					title='Shows'
					list={chef_special_shows}
					viewAll={viewAll}
					setViewAll={setViewAll}
					index={2}
					loading={loadingShows}
					total={totalShows}
					getMoreData={props.getShows}
				/>
			);
		default:
			return (
				<div>
					<MovieList
						title='Movies'
						list={chef_special_movies}
						viewAll={viewAll}
						setViewAll={setViewAll}
						index={1}
						loading={loadingMovies}
						total={totalMovies}
						getMovies={props.getMovies}
					/>
					<MovieList title='Shows' list={chef_special_shows} viewAll={viewAll} setViewAll={setViewAll} index={2} />
				</div>
			);
	}
};

const mapStateToProps = (state) => {
	const { chef_special_shows, chef_special_movies, loadingMovies, totalMovies, loadingShows, totalShows } = state.Recommendation;

	return {
		chef_special_movies,
		chef_special_shows,
		loadingMovies,
		totalMovies,
		totalShows,
		loadingShows
	};
};

const ChefSpecial = connect(mapStateToProps, {
	getMovies,
	getShows
})(ChefSpecialView);

export default ChefSpecial;
