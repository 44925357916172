import { createMuiTheme } from '@material-ui/core';

const quicksand = 'Quicksand';

let Theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: '#DE0418',
			contrastText: '#ffffff'
		},
		secondary: {
			main: '#FFD15C',
			contrastText: '#ffffff'
		}
	},
	typography: {
		fontFamily: ['Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(',')
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: 'initial',
				fontWeight: 400,
				letterSpacing: 1
			}
		}
	}
});

Theme = {
	...Theme,
	palette: {
		...Theme.palette,

		light_grey: '#EBEBEB',
		light_grey2: '#7A7A7A',
		light_grey3: '#7E7E7E',

		tertiary: '#4D536F',
		disable: '#BEC0CA',
		yellow: '#FDB62F',
		bg: '#F5F6FF',
		black: '#000000',
		white: 'white',
		pink: '#DE0418'
	},
	font_family: {
		quicksand
	},
	font_size: {
		Overline: 10,
		Caption: 12,
		Body: 14,
		Subtitle: 16,
		title: 18,
		H6: 20,
		H5: 24,
		H4: 34,
		H3: 48,
		H2: 60,
		H1: 95
	}
};

export default Theme;
