import React from 'react';
import { IconButton, Menu, MenuItem, Paper, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Text from './Text';
import Avatar from './Avatar';
import utils from 'resources/utils';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setSearch_data } from 'actions/search_actions';
const search = '/assets/images/search.svg';
const logo = '/assets/images/logo.svg';
const back_icon = '/assets/images/back-icon.svg';
import api_requests from 'resources/api_requests';
const searchIcon = '../assets/images/searchIcon.png';
let debounced;

const Header = ({ classes, menu, history, guestUser, openLoginModal }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const dispatch = useDispatch();
	const [searchInput, setSearchInput] = React.useState('');
	const { user_info } = useSelector((state) => ({
		..._.pick(state.App, ['user_info'])
	}));

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handle_menu_click = (menu) => {
		switch (menu) {
			case 'profile':
				history.push(`/profile/${user_info.username}`);
				break;

			case 'Logout':
				utils.logout();
				history.push('/');
				break;
			case 'search':
				history.push('/search');
				break;
			case 'Login':
				openLoginModal();

				break;
		}
		handleClose();
	};

	const handle_search_movies = (e) => {
		let search_text = e.target.value;

		if (debounced) {
			debounced.cancel();
		}

		debounced = _.debounce(() => {
			api_requests
				.search({
					_type: ['movie', 'tv-show'],
					joins: {
						title: 1,
						name: 1,
						original_title: 1,
						vote_average: 1,
						poster_path: 1,
						release_date: 1,
						original_language: 1,
						first_air_date: 1,
						spoken_languages: 1
					},
					q: search_text,
					suggest: true,
					scoring_script:
						"Math.log(2 + _score + (doc['vote_average'].size() != 0 ? doc['vote_average'].value : 0) +  (doc['popularity'].size() !=  0 ? doc['popularity'].value : 0))",
					size: 10
				})
				.then((res) => {
					if (e.target.value.length > 0) {
						dispatch(setSearch_data(_.get(res.data, 'response.hits.hits', [])));
					} else {
						dispatch(setSearch_data([]));
					}
				});
		}, 500);

		setSearchInput(search_text);
		debounced();
	};

	const go_back = () => {
		history.goBack();
	};

	let middle;
	let back_icon_show = false;

	switch (menu) {
		// case 'feed':
		// 	middle = <img src={logo} />;
		// 	break;

		// case 'profile':
		// 	back_icon_show = true;
		// 	middle = (
		// 		<Text semi style={{ fontSize: 26 }}>
		// 			Profile
		// 		</Text>
		// 	);
		// 	break;

		// case 'watchlist':
		// 	back_icon_show = true;
		// 	middle = (
		// 		<Text semi style={{ fontSize: 26 }}>
		// 			My Watchlist
		// 		</Text>
		// 	);
		// 	break;

		default:
			middle = <img src={logo} width='128' loading='lazy' />;
			back_icon_show = true;
			break;
	}

	return (
		<Paper elevation={1} className={classes.header_container}>
			{history.location.pathname !== '/search' ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '96%',
						margin: '0 auto',
						height: '100%'
					}}>
					<div style={{ width: '50%', flexDirection: 'row', display: 'flex' }}>
						{back_icon_show && (
							<IconButton onClick={go_back}>
								<img style={{ width: 24 }} src={back_icon} loading='lazy' />
							</IconButton>
						)}
						{middle}
					</div>
					<div
						style={{
							width: '50%',
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center'
						}}>
						<div
							onClick={() => handle_menu_click('search')}
							style={{ width: 34, height: 34, marginRight: 18, cursor: 'pointer' }}>
							<img src={searchIcon} style={{ width: 34, height: 34, opacity: 0.82 }} className={classes.search_icon} />
						</div>
						{user_info && (
							<div onClick={handleClick} className={classes.avatar_container}>
								<Avatar />
							</div>
						)}
						{!user_info && (
							<span
								onClick={() => handle_menu_click('Login')}
								style={{
									color: 'white',
									backgroundColor: 'red',
									width: 60,
									height: 30,
									borderRadius: 20,
									alignContent: 'center',
									alignItems: 'center',
									justifyContent: 'center',
									display: 'flex'
								}}>
								Log in
							</span>
						)}
					</div>

					<Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
						{user_info && <MenuItem onClick={() => handle_menu_click('profile')}>Profile</MenuItem>}
						<MenuItem onClick={() => handle_menu_click(guestUser ? 'Login' : 'Logout')}>
							{guestUser ? 'Login' : 'Logout'}
						</MenuItem>
					</Menu>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						width: '96%',
						margin: '0 auto',
						height: '100%'
					}}>
					<div style={{ width: '80%', flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
						<div
							style={{
								border: '1px solid black',
								padding: '5px 8px',
								display: 'flex',
								alignItems: 'center',
								borderRadius: 50,
								width: '100%',
								cursor: 'pointer',
								padding: 10,
								alignSelf: 'center'
							}}>
							<img src={searchIcon} style={{ width: 20, height: 20 }} />
							<input
								style={{ width: '100%', marginLeft: 10, fontSize: 18, borderColor: 'transparent' }}
								placeholder='Search Movies / TV Shows'
								onChange={handle_search_movies}
								onBlur={_.debounce(() => {
									setSearchInput('');
								}, 500)}
								value={searchInput}
							/>
						</div>
					</div>
					<div
						style={{
							width: '20%',
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center'
						}}>
						<div onClick={handleClick} className={classes.avatar_container}>
							<Avatar />
						</div>
					</div>

					<Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
						{user_info && <MenuItem onClick={() => handle_menu_click('profile')}>Profile</MenuItem>}
						<MenuItem onClick={() => handle_menu_click('logout')}>Logout</MenuItem>
					</Menu>
				</div>
			)}
		</Paper>
	);
};

const STYLES = (theme) => ({
	header_container: {
		width: '100%',
		height: 70,
		borderRadius: 0
	},
	avatar_container: {
		display: 'flex',

		cursor: 'pointer'
	}
});

export default withRouter(withStyles(STYLES)(Header));
