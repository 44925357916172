import _ from 'lodash';
import {
	COMMENT_CREATED,
	FEED_UPDATED,
	LOGOUT_USER,
	POSTS_RECEIVED,
	POST_CREATED,
	POST_DELETED,
	TOGGLE_POST_LIKED,
	TOGGLE_FEED_LOADER,
} from 'resources/redux_constants';

const initialState = {
	posts: [],
	loading: false,
	total: null,
};

const Feed = (state = { ...initialState }, action) => {
	switch (action.type) {
		case POSTS_RECEIVED:
			return { ...state, posts: action.posts, total: action.total };

		case POST_CREATED:
			return { ...state, posts: [action.post, ...state.posts] };

		case COMMENT_CREATED: {
			const posts = state.posts;
			const post_index = _.findIndex(posts, (v) => {
				return action.post_id === v._id;
			});
			posts[post_index]._source.comments = [
				...(posts[post_index]._source.comments || []),
				{ new_comment: true, commentText: action.commentText }
			];
			return { ...state, posts };
		}

		case TOGGLE_POST_LIKED: {
			const posts = JSON.parse(JSON.stringify(state.posts));
			const post_index = _.findIndex(posts, { _id: action.post_id });
			const likedBy_index = _.findIndex(posts[post_index]._source.likedBy, { _id: action.username });

			if (likedBy_index === -1) {
				posts[post_index]._source.likedBy = [
					...(posts[post_index]._source.likedBy || []),
					{ _id: action.username, fields: { name: action.name } }
				];
			} else {
				posts[post_index]._source.likedBy.splice(likedBy_index, 1);
			}
			return { ...state, posts };
		}

		case POST_DELETED: {
			const posts = JSON.parse(JSON.stringify(state.posts));
			const post_index = _.findIndex(posts, { _id: action.post_id });

			posts.splice(post_index, 1);

			return { ...state, posts };
		}

		case LOGOUT_USER: {
			return { ...initialState };
		}

		case TOGGLE_FEED_LOADER: {
			return {
				...state,
				loading: action.value,
			}
		}

		default:
			return state;
	}
};

export default Feed;
