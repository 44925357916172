import React, { useEffect } from 'react';
import _ from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Text from 'components/Text';
import ItemCard from './ItemCard';
import { useHistory } from 'react-router';
import { Button, withStyles } from '@material-ui/core';
const chef_icon_selected = '/assets/images/bottom-menu/chef-selected.svg';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
const RecommendedList = (props) => {
	const history = useHistory();

	const { title, list, viewAll, setViewAll, index, classes, guestUser, handleLoginModal } = props;
	useEffect(() => {
		if (props.feed) {
			props.getMoreData();
		}
	}, []);
	return (
		<div style={{ marginTop: 10, backgroundColor: props.feed && 'white' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center', paddingLeft: 14 }}>
					<img src={chef_icon_selected} className={classes.tab5} loading='lazy' />
					<Text bold style={{ paddingLeft: 10, color: '#4D536F' }}>
						{title}
					</Text>
				</div>
				<button
					style={{
						color: 'grey',
						backgroundColor: 'white',
						borderWidth: 1,
						borderColor: 'grey',
						borderRadius: 20,
						width: 30,
						height: 30,
						marginRight: 14
					}}
					onClick={() => {
						if (guestUser) {
							handleLoginModal();
						} else {
							history.push('/recommendation');
						}
					}}>
					<ArrowForwardIcon fontSize='small' />
				</button>
			</div>
			<div
				style={{
					display: 'flex',
					...(viewAll ? { flexWrap: 'wrap', justifyContent: 'space-between' } : { overflow: 'scroll' }),
					marginTop: 10,
					marginLeft: 10
				}}>
				{_.map(list, (v, i) => {
					return (
						<ItemCard
							key={`item${i}`}
							media={v}
							viewAll={viewAll}
							style={{
								...(viewAll ? { marginTop: 10, flex: '0 0 45%', height: 220 } : i !== 0 && { marginLeft: 20 })
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};

const STYLES = (theme) => ({
	tab5: {
		[theme.breakpoints.down('xs')]: {
			width: '70% !important',
			marginTop: -5
		},
		width: 38,
		height: 38
	}
});
export default withStyles(STYLES)(RecommendedList);
