import React, { useEffect } from 'react';
import IMAGES from 'resources/images';
import { Button, Checkbox, ListItem, makeStyles, Menu } from '@material-ui/core';
import Text from 'components/Text';
import ChefSpecial from './ChefSpecial';
import Explore from './Explore';
import Theme from 'resources/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { clear_recommendation_data, getRecommendationData, updateProviders } from 'actions/recommendation_actions';
import _ from 'lodash';
import utils from 'resources/utils';
import { useHistory } from 'react-router';
import PageTrackingWithPath from '../../components/pageViewTrackerWithPath';
import { connect } from 'react-redux';
import { openLoginModal } from 'actions/loginModal_actions';

const Recommendation = (props) => {
	const [tab, setTab] = React.useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const { providers, user_info } = useSelector((state) => ({
		..._.pick(state.Recommendation, ['providers']),
		..._.pick(state.App, ['user_info'])
	}));

	const [checkedProviders, setCheckedProviders] = React.useState(user_info?.preferred_providers || []);

	useEffect(() => {
		dispatch(getRecommendationData());
		return () => {
			dispatch(clear_recommendation_data());
		};
	}, [user_info?.preferred_providers]);
	useEffect(() => {
		PageTrackingWithPath('/recommendation');
	}, []);
	const handleOpenMenu = (event) => {
		setCheckedProviders(
			user_info.preferred_providers || [
				{
					_id: '8',
					_index: 'providers_17824',
					_score: 1,
					_source: { logo_path: '/9A1JSVmSxsyaBK4SUFsYVqbAYfW.jpg', provider_name: 'Netflix' }
				},
				{
					_id: '122',
					_index: 'providers_17824',
					_score: 1,
					_source: { logo_path: '/eApzJtzOngfBlEC3lCjuAtzsOTf.jpg', provider_name: 'Hotstar' }
				},
				{
					_id: '119',
					_index: 'providers_17824',
					_score: 1,
					_source: { logo_path: '/68MNrwlkpF7WnmNPXLah69CR5cb.jpg', provider_name: 'Amazon Prime Video' }
				}
			]
		);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleCheckProvider = (provider) => {
		let index = _.findIndex(checkedProviders, function (o) {
			return o._id === provider._id;
		});
		if (index > -1) {
			let newProviders = [...checkedProviders];
			newProviders.splice(index, 1);
			setCheckedProviders(newProviders);
			return;
		}

		setCheckedProviders([...checkedProviders, provider]);
	};

	const handleSubmitProviders = () => {
		dispatch(updateProviders(checkedProviders));
		handleCloseMenu();
	};

	if (_.isEmpty(providers)) {
		return null;
	}

	return (
		<div style={{ width: '95%', margin: '0 auto', paddingTop: 10 }}>
			<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
				<div
					style={{
						backgroundColor: 'red',
						width: 27,
						height: 27,
						display: 'flex',
						borderRadius: 8,
						marginTop: 10,
						marginRight: 10,
						fontSize: 30,
						color: 'white',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					onClick={handleOpenMenu}>
					+
				</div>
				{_.map(
					user_info?.preferred_providers?.length > 0
						? user_info.preferred_providers
						: [
								{ fields: { logo_path: '/9A1JSVmSxsyaBK4SUFsYVqbAYfW.jpg' } },
								{ fields: { logo_path: '/eApzJtzOngfBlEC3lCjuAtzsOTf.jpg' } },
								{ fields: { logo_path: '/68MNrwlkpF7WnmNPXLah69CR5cb.jpg' } }
						  ],
					(v, i) => {
						return (
							<img
								key={`movie${i}`}
								src={utils.get_poster_base_url() + v.fields.logo_path}
								width='40'
								style={{ marginRight: 10, borderRadius: 5, marginTop: 10 }}
								loading='lazy'
							/>
						);
					}
				)}

				<Menu
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
					PaperProps={{ style: { width: 250, paddingTop: 10 } }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginBottom: 10,
							paddingLeft: 10,
							paddingRight: 10
						}}>
						<Text semi>Streaming Services</Text>
						<div
							style={{
								marginLeft: 10,
								border: `1.2px solid ${Theme.palette.primary.main}`,
								background: 'white',
								borderRadius: 20,
								padding: '1px 15px'
							}}>
							<Text
								bold
								style={{ color: Theme.palette.primary.main, fontSize: 10, cursor: 'pointer' }}
								onClick={handleSubmitProviders}>
								Done
							</Text>
						</div>
					</div>
					{_.map(providers, (v, i) => {
						const checked =
							_.findIndex(checkedProviders, function (o) {
								return o._id === v._id;
							}) > -1;
						return (
							<ListItem
								button
								key={`item${i}`}
								onClick={() => handleCheckProvider(v)}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									paddingTop: 5,
									paddingBottom: 5,
									letterSpacing: 1.1,
									...(i !== 0 && { borderTop: '1px solid lightgrey' })
								}}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img src={utils.get_poster_base_url() + v._source.logo_path} width='32' loading='lazy' />
									<Text style={{ marginLeft: 5, fontSize: 12 }}>{_.get(v, '_source.provider_name', '')}</Text>
								</div>
								<Checkbox color='primary' checked={checked} />
							</ListItem>
						);
					})}
				</Menu>
			</div>
			{/* <div style={{ display: 'flex', marginTop: 5 }}>
				<div
					style={{ padding: 8, cursor: 'pointer', ...(tab === 0 && { borderBottom: '3px solid #DE0418' }) }}
					onClick={() => setTab(0)}>
					<Text bold>Chef’s Special</Text>
				</div>
				<div
					style={{
						padding: 8,
						marginLeft: 10,
						cursor: 'pointer',
						...(tab === 1 && { borderBottom: '3px solid #DE0418' })
					}}
					onClick={() => setTab(1)}>
					<Text bold>Explore</Text>
				</div>
			</div> */}

			{/* <div style={{ display: tab === 0 ? 'block' : 'none' }}> */}

			{/* </div> */}
			{/* <div style={{ display: tab === 1 ? 'block' : 'none' }}> */}
			<Explore visible={true} scrollToTop={props.scrollToTop} />
			{/* </div> */}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	btnContainer: {}
}));

const mapStateToProps = (state) => {
	const { guestUser } = state.App;
	return {
		guestUser
	};
};

const mapDispatchToProps = {
	openLoginModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommendation);
