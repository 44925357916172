import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { withStyles, Card } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { signup } from 'actions/actions';
import TextInput from 'components/TextInput';
import Text from 'components/Text';
import Contained_Button from 'components/Contained_Button';
import Theme from 'resources/Theme';
import Link_Button from 'components/Link_Button';
import PageTrackingWithPath from '../../components/pageViewTrackerWithPath';
const logo = '/assets/images/logo.svg';

class Signup extends Component {
	state = {
		form_data: {
			name: '',
			email: '',
			dob: '',
			password: '',
			inviteCode: ''
		},
		passwordCondition: false
	};
	componentDidMount () {
		PageTrackingWithPath('/signup');
	}
	handle_change = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		const { form_data } = this.state;

		// if (name === 'email') {
		// 	let format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// 	this.state.emailCondition = format.test(_.trim(value));
		// 	form_data[name] = _.trim(value);
		// }

		if (name === 'password') {
			this.state.passwordCondition = value.length >= 8;
		}

		this.setState({
			form_data: { ...form_data, [name]: value }
		});
	};

	handle_signup = () => {
		const { form_data, passwordCondition } = this.state;
		if (!passwordCondition) {
			return;
		}
		this.props.signup(form_data, this.props.history);
	};

	render () {
		const { classes } = this.props;
		const { form_data } = this.state;

		return (
			<div className={classes.container}>
				<Card className={classes.card} elevation={0}>
					<div style={{ marginTop: 40, textAlign: 'center' }}>
						<img src={logo} loading='lazy' />
					</div>
					<div style={{ marginTop: 40 }}>
						<Text semi style={{ fontSize: Theme.font_size.H5 }}>
							Create your account
						</Text>
					</div>
					<div style={{ height: 3, borderRadius: 25, width: 40, background: Theme.palette.yellow, marginBottom: 30 }}></div>
					<ValidatorForm onSubmit={this.handle_signup}>
						<TextInput
							type='text'
							label='Name'
							placeholder='Enter you name'
							value={form_data.name}
							onChange={this.handle_change}
							name='name'
							validators={['required']}
						/>
						<TextInput
							type='email'
							label='Email'
							placeholder='Enter you email'
							value={form_data.email}
							onChange={this.handle_change}
							style={{ marginTop: 20 }}
							name='email'
							validators={['required']}
						/>
						<TextInput
							type='number'
							placeholder='YYYY'
							min='1900'
							max='2100'
							label='Year of birth'
							value={form_data.dob}
							onChange={this.handle_change}
							name='dob'
							validators={['required']}
							style={{ marginTop: 20 }}
						/>
						<TextInput
							type='password'
							label='Password'
							placeholder='Enter your password (Minimum 8 Characters)'
							value={form_data.password}
							onChange={this.handle_change}
							name='password'
							validators={['required', 'matchRegexp:^.{8,40}$']}
							style={{ marginTop: 20 }}
						/>

						{/* <TextInput
							type='number'
							label='Invite Code'
							placeholder='Enter your invite code'
							value={form_data.inviteCode}
							onChange={this.handle_change}
							name='inviteCode'
							validators={['required']}
							style={{ marginTop: 20 }}
						/> */}
						<div style={{ marginBottom: 18, marginTop: 18, color: 'grey' }}>
							By continuing, I agree to the{' '}
							<Link to='/terms' style={{ color: 'red', fontWeight: 'bold' }}>
								Terms of Use
							</Link>{' '}
							&{' '}
							<Link to='/policy' style={{ color: 'red', fontWeight: 'bold' }}>
								Privacy Policy
							</Link>
						</div>
						<div style={{ textAlign: 'center', marginTop: 40 }}>
							<Contained_Button type='submit' color='primary' type='submit' style={{ width: 180 }}>
								Next
							</Contained_Button>
						</div>
						<div style={{ textAlign: 'center', marginTop: 20 }}>
							<Link_Button href='/' style={{ width: 180, height: 40, color: Theme.palette.black }}>
								Back
							</Link_Button>
						</div>
					</ValidatorForm>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	signup: (form_data, history) => dispatch(signup(form_data, history))
});

const STYLES = (theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		overflow: 'scroll',
		paddingBottom: 18
	},
	card: {
		width: 350,
		[theme.breakpoints.down('xs')]: {
			width: '90%'
		},
		margin: '0 auto',
		borderRadius: 0
	}
});

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Signup));
