import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@material-ui/core';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex'}}>
      <CircularProgress {...props} size={30}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex:1000
        }}
      >

        <Typography variant="caption" component="div" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
export default CircularProgressWithLabel
// import React from 'react';
// import './Circular_progress.css';
// class CircularProgressBar extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = {};
//     }
  
//     render() {
//       // Size of the enclosing square
//       const sqSize = this.props.sqSize;
//       // SVG centers the stroke width on the radius, subtract out so circle fits in square
//       const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
//       // Enclose cicle in a circumscribing square
//       const viewBox = `0 0 ${sqSize} ${sqSize}`;
//       // Arc length at 100% coverage is the circle circumference
//       const dashArray = radius * Math.PI * 2;
//       // Scale 100% coverage overlay with the actual percent
//       const dashOffset = dashArray - dashArray * this.props.percentage / 100;
  
//       return (
//         <svg
//             width={this.props.sqSize}
//             height={this.props.sqSize}
//             viewBox={viewBox}>
//             <circle
//               className="circle-background"
//               cx={this.props.sqSize / 2}
//               cy={this.props.sqSize / 2}
//               r={radius}
//               strokeWidth={`${this.props.strokeWidth}px`} />
//             <circle
//               className="circle-progress"
//               cx={this.props.sqSize / 2}
//               cy={this.props.sqSize / 2}
//               r={radius}
//               strokeWidth={`${this.props.strokeWidth}px`}
//               // Start progress marker at 12 O'Clock
//               transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
//               style={{
//                 strokeDasharray: dashArray,
//                 strokeDashoffset: dashOffset
//               }} />
//             <text
//               className="circle-text"
//               x="50%"
//               y="50%"
//               dy=".3em"
//               textAnchor="middle">
//               {`${this.props.percentage}%`}
//             </text>
//         </svg>
//       );
//     }
//   }
  
//   CircularProgressBar.defaultProps = {
//     sqSize: 100,
//     percentage: 25,
//     strokeWidth: 5
//   };