import React, { Component } from 'react';
import { BottomNavigation, BottomNavigationAction, Button, Paper, withStyles } from '@material-ui/core';

const feed_icon = '/assets/images/bottom-menu/home.svg';
const feed_icon_selected = '/assets/images/bottom-menu/home1.svg';
import { Link } from 'react-router-dom';
const search_icon = '/assets/images/bottom-menu/trendingIcon.svg';
const search_icon_selected = '/assets/images/bottom-menu/trendingIcon2.svg';
const add_post_icon = '/assets/images/bottom-menu/add-post.svg';
const add_post_icon_selected = '/assets/images/bottom-menu/create_post.svg';

const watchlist_icon = '/assets/images/bottom-menu/watchListIcon.svg';
const watchlist_icon_selected = '/assets/images/bottom-menu/watchListIcon1.svg';

const chef_icon = '/assets/images/bottom-menu/chefIcon.svg';
const chef_icon_selected = '/assets/images/bottom-menu/chefIcon1.svg';

class Footer extends Component {
	render () {
		const { menu, classes, handleChange, history, guestUser, openLoginModal } = this.props;

		return (
			<Paper elevation={5} className={classes.footer_container}>
				<div style={{ position: 'fixed', bottom: 100, right: 14, zIndex: 100 }}>
					{guestUser ? (
						<Button onClick={() => openLoginModal()}>
							<img src={add_post_icon_selected} style={{ width: 60 }} loading='lazy' />
						</Button>
					) : (
						<Link to='/create-post'>
							<Button>
								<img src={add_post_icon_selected} style={{ width: 60 }} loading='lazy' />
							</Button>
						</Link>
					)}
				</div>
				<BottomNavigation value={menu} onChange={handleChange} className={classes.bottom_navigation}>
					<BottomNavigationAction
						value='feed'
						icon={
							menu === 'feed' ? <img src={feed_icon_selected} loading='lazy' /> : <img src={feed_icon} loading='lazy' />
						}
						style={{
							backgroundColor: menu === 'feed' ? 'red' : 'transparent',
							borderTopRightRadius: 10,
							borderBottomRightRadius: 10
						}}
					/>
					<BottomNavigationAction
						value='explore'
						icon={
							menu === 'explore' ? (
								<img src={search_icon_selected} className={classes.add_post} loading='lazy' />
							) : (
								<img src={search_icon} className={classes.add_post} loading='lazy' />
							)
						}
						style={{ backgroundColor: menu === 'explore' ? 'red' : 'transparent', borderRadius: 10 }}
					/>
					{/* <BottomNavigationAction
						value='create'
						icon={
							menu === 'create' ? (
								<img src={add_post_icon_selected} className={classes.add_post} loading='lazy' />
							) : (
								<img src={add_post_icon} className={classes.add_post} loading='lazy' />
							)
						}
					/> */}
					<BottomNavigationAction
						value='watchlist'
						icon={
							menu === 'watchlist' ? (
								<img src={watchlist_icon_selected} className={classes.add_post} loading='lazy' />
							) : (
								<img src={watchlist_icon} className={classes.add_post} loading='lazy' />
							)
						}
						style={{ backgroundColor: menu === 'watchlist' ? 'red' : 'transparent', borderRadius: 10 }}
					/>
					<BottomNavigationAction
						value='recommendation'
						icon={
							menu === 'recommendation' ? (
								<img src={chef_icon_selected} className={classes.add_post} loading='lazy' />
							) : (
								<img src={chef_icon} className={classes.add_post} loading='lazy' />
							)
						}
						style={{
							backgroundColor: menu === 'recommendation' ? 'red' : 'transparent',
							borderTopLeftRadius: 10,
							borderBottomLeftRadius: 10
						}}
					/>
				</BottomNavigation>
			</Paper>
		);
	}
}

const STYLES = (theme) => ({
	bottom_navigation: {
		'& >button': {
			maxWidth: 'unset',
			minWidth: 'unset',
			cursor: 'pointer'
		},
		'& img': {
			[theme.breakpoints.down('xs')]: {
				width: '30%'
			}
		},
		height: 70
	},
	add_post: {
		width: 35,
		[theme.breakpoints.down('xs')]: {
			width: '30% !important'
		}
	},
	tab5: {
		[theme.breakpoints.down('xs')]: {
			width: '80% !important',
			marginTop: -5
		}
	},
	footer_container: {
		width: '100%'
	}
});

export default withStyles(STYLES)(Footer);
