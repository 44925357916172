import _ from 'lodash';
import {
	CLEAR_RECOMMENDATION_DATA,
	EXPLORE_DATA_RECEIVED,
	FILTER_RESULTS_RECEIVED,
	LOGOUT_USER,
	RECOMMENDATION_DATA_RECEIVED,
	SET_MOVIES,
	LOADING_MOVIES,
	LOADING_SHOWS,
	SET_SHOWS,
	UPDATE_EXPLORE_LIST,
} from 'resources/redux_constants';

const initialState = {
	genres: [],
	providers: [],
	chef_special_movies: [],
	chef_special_shows: [],
	exploreList: {},
	age_ratings: [],
	filterResults: null,
	totalMovies: null,
	loadingMovies: false,
	totalShows: null,
};

const Recommendation = (state = { ...initialState }, action) => {
	switch (action.type) {
		case RECOMMENDATION_DATA_RECEIVED: {
			return {
				...state,
				genres: action.genres,
				providers: action.providers,
				chef_special_movies: action.chef_special_movies,
				chef_special_shows: action.chef_special_shows,
				age_ratings: action.age_ratings
			};
		}
		case LOADING_MOVIES:
			return {
				...state,
				loadingMovies: action.value,
			};
		case LOADING_SHOWS:
			return {
				...state,
				loadingShows: action.value,
			};
		case SET_MOVIES: {
			return {
				...state,
				chef_special_movies: [
					...state.chef_special_movies,
					...action.movies
				],
				totalMovies: action.total,
			};
		}
		case SET_SHOWS: {
			return {
				...state,
				chef_special_shows: [
					...state.chef_special_shows,
					...action.shows
				],
				totalShows: action.total,
			};
		}

		case EXPLORE_DATA_RECEIVED: {
			return { ...state, exploreList: action.exploreList };
		}

		case FILTER_RESULTS_RECEIVED: {
			return { ...state, filterResults: action.filterResults };
		}

		case UPDATE_EXPLORE_LIST:
			
			return {
				...state,
				exploreList: {
					...state.exploreList,
					...action.exploreList,
				}
			}

		case CLEAR_RECOMMENDATION_DATA:
		case LOGOUT_USER: {
			return { ...initialState };
		}

		default:
			return state;
	}
};

export default Recommendation;
