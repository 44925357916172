import React, { Component } from 'react';
import Login from './Login';
import WelcomeScreen from './WelcomeScreen';

const Auth = () => {
	const [showLogin, setShowLogin] = React.useState(true);

	const goToLogin = () => {
		setShowLogin(true);
	};

	if (showLogin) {
		return <Login />;
	}

	return <WelcomeScreen goToLogin={goToLogin} />;
};

export default Auth;
