import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles, Card } from '@material-ui/core';
import PageTrackingWithPath from 'components/pageViewTrackerWithPath';

class Policy extends Component {
	componentDidMount () {
		PageTrackingWithPath('/policy');
	}
	render () {
		const { classes } = this.props;
		return (
			<div className={classes.container}>
				<Card className={classes.card} elevation={0}>
					<b>Privacy Policy</b>
					<br />
					<br />
					This Policy describes how we treat personal information on the websites where it is located.
					<br />
					<br />
					We collect information from and about you.
					<br />
					<br />
					<b>Contact information.</b> For example, we might collect your email address, phone number if you sign up for an
					account via email, sign up for our emails or leave feedback in our forms.
					<br />
					<br />
					Information you submit or post. For example, we collect the information you post in a public space on our website.
					We also collect information if you respond to a survey.
					<br />
					<br />
					Demographic information. We may collect your pin code to help you find sales relevant to you.
					<br />
					<br />
					Other information. If you use our website, we may collect information about your IP address and the browser you’re
					using. We might look at what site you came from, or what site you visit when you leave us.
					<br />
					<br />
					We may share information with third parties.
					<br />
					<br />
					We will share information with our business partners. For example, we may share information with third parties who
					operate our “find ads near me” feature. We may also share information with third parties who co-sponsor a
					promotion. We may also share information with our retail partners. This might include those whose circulars and
					ads appear on our platforms.
					<br />
					<br />
					We will share information if we think we have to in order to comply with the law or to protect ourselves. For
					example, we will share information to respond to a court order or subpoena. We may share it if a government agency
					or investigatory body requests. We might share information when we are investigating potential fraud.
					<br />
					<br />
					We may share information with any successor to all or part of our business.For example, if part of our business
					was sold we may give our customer list as part of that transaction.
					<br />
					<br />
					We may share information for other reasons we may describe to you
					<br />
					<br />
					We use standard security measures.
					<br />
					<br />
					The Internet is not 100% secure. We cannot promise that your use of our sites will be completely safe. We
					encourage you to use caution when using the Internet. This includes not sharing your passwords. We keep personal
					information as long as it is necessary or relevant for the practices described in this Policy. We also keep
					information as otherwise required by law.
					<br />
					<br />
					We may link to other sites or have third party services on our site we don’t control.
					<br />
					<br />
					If you click on a link to a third party site, you will be taken to websites we do not control. This includes
					social media sites. This policy does not apply to the privacy practices of these websites. Read the privacy policy
					of other websites and insurance carriers carefully. We are not responsible for these third party practices.
					<br />
					<br />
					Our site and apps may also link to and/or serve third party ads or other content that contains their own cookies
					or tracking technologies.
					<br />
					<br />
					We use tracking technologies for many reasons.
					<br />
					<br />
					To understand the activities and behaviours of our consumers and website visitors.
					<br />
					<br />
					To recognize new visitors to our websites and apps.
					<br />
					<br />
					To recognize past customers.
					<br />
					<br />
					To serve you with customized or interest-based advertising. These ads may appear on our website or others you
					visit.
					<br />
					<br />
					So we can better understand our audience, our customers, our website visitors, and their respective interests.
					<br />
					<br />
					We engage in interest-based advertising.
					<br />
					<br />
					We may serve interest-based advertising using personal and other information gathered about you over time across
					multiple websites or other platforms. This might include apps. These ads are served on websites or apps. They
					might also be served in emails. Interest-based or “online behavioral advertising” includes ads that are served to
					you after you leave our website, encouraging you to return. They also include ads we think are relevant to you
					based on your shopping habits or online activities.
					<br />
					<br />
					How do we gather relevant information for interest-based advertising?
					<br />
					<br />
					To decide what is relevant to you, we might look at your purchases, collect certain mobile unique operating system
					IDs or browsing behaviors. We might look at these activities on our platforms or the platforms of others. This
					information is gathered using the tracking tools described above. We work with third parties who help gather this
					information. These third parties might link your name or email address to other information they collect. That
					might include past purchases made offline or online. Or, it might include online usage information.
					<br />
					<br />
					How to control tracking technologies.
					<br />
					<br />
					You can control certain tracking tools. Many browsers give you the ability to delete or reject cookies. How you do
					so depends on the type of cookie. You can configure your browser to reject browser cookies. To control flash
					cookies go to this page. Why? Because flash cookies do not reside in your browser, and thus your browser settings
					will not affect them. Not all of the tracking described here will stop if cookies are deleted or blocked. You can
					also control certain tracking tools on your mobile devices. These opportunities are subject to the offerings made
					available by the third-party operating system providers and subject to their privacy policies, and not any
					offerings by or the privacy policy of Kunbah. NOTE: If you configure your computer to block cookies, you may not
					be able to access certain functionality on our site or the toolbar. If you block or delete cookies, not all of the
					tracking activities we have described here will stop. Choices you make are both browser and device-specific.
					<br />
					<br />
					For convenience, the following are instructions that may apply to your mobile device, though please note that the
					setting features may vary from device to device.
					<br />
					<br />
					You can also opt out of targeted ads delivered by or on Kunbah. by visiting the following links. Please note that
					these platforms allow you to opt out of interest-based advertising delivered by registered members on the current
					browser you are using.
					<br />
					<br />
					If you have any questions about this Policy, please email us at info@kunbah.com.
					<br />
					<br />
					We may update this Policy.
					<br />
					<br />
					From time to time we may change our privacy policies. We will notify you of any material changes to our Policy as
					required by law. We will also post an updated copy on our website. Please check our site periodically for updates.
					<br />
					<br />
					<b></b>Address
					<br />
					<br />
					<b>Kunbah Technologies LLP</b>
					<br />
					<br />
					33 A Shakti Nagar Tonk Road, Jaipur India 302018
					<br />
					<br />
					<b>Contact</b>
					<br />
					<br />
					Other: info@kunbah.com
					<br />
					<br />
					<br />
					<b>Responsible Disclosure Policy</b>
					<br />
					<br />
					We are dedicated to maintaining the security and privacy of the Kunbah platform. We welcome security researchers
					from the community who want to help us improve our services.If you discover a security vulnerability, please give
					us the chance to fix it by emailing us at security@kunbah.com. Publicly disclosing a security vulnerability
					without informing us first puts the rest of the community at risk. When you notify us of a potential problem, we
					will work with you to make sure we understand the scope and cause of the issue.Thank you for your work and
					interest in making our service safer and more secure!
					<br />
					<br />
					<b>Content & Copyright</b>
					<br />
					<br />
					Kunbah is using the following sources: This product uses the TMDb API but is not endorsed or certified by 
					<a href={'https://www.themoviedb.org/'}>TMDb</a>.
					<br />
					<br />
					<b>Community policy</b>
					<br />
					<br />
					By accessing and using kunbah.com or its associated apps (the “Service”), you accept and agree to be bound by
					these terms and conditions (“Terms”), taken from our wider 
					<a href={'https://letterboxd.com/legal/terms-of-use/'}>terms of use</a>:<br />
					<br />
					<span>&#8226;</span> The Kunbah community is united by a love of film & OTT Content —but we all have different
					tastes, backgrounds and lived experiences. We expect members of our community to be courteous and respectful of
					others’ opinions and to refrain from posting unwelcome, aggressive, suggestive or otherwise inappropriate remarks
					directed at another member.
					<br />
					<br />
					<span>&#8226;</span> You must not use the Service for any malicious means, or to abuse, harass, threaten,
					intimidate or impersonate any other member. You must not request or attempt to solicit personal or identifying
					information from another member.
					<br />
					<br />
					<span>&#8226;</span> Creativity of expression has a home on Kunbah, hate does not. You must not use the Service to
					promote, engage in or incite hate, violence, discrimination or intolerance, including based on race, age, gender,
					gender identity, ethnicity, religion, disability, sexual orientation or other protected attribute. We reserve the
					right to remove content that has the potential to harm values or communities we consider worthy of protection.
					<br />
					<br />
					<span>&#8226;</span> You must set the spoiler flag on any review that contains significant plot spoilers, or we
					will. You must not remove spoiler flags set on your content by our moderators. You must not post significant plot
					spoilers in list descriptions/notes or comment threads. This is non-negotiable. People watch films at their own
					pace.
					<br />
					<br />
					<span>&#8226;</span> You must not plagiarize anyone else’s writing. Write your own reviews; name, quote and link
					(where available) your sources.
					<br />
					<br />
					<span>&#8226;</span> We reserve the right to remove any content that we consider to be offensive, objectionable,
					unlawful, explicit, graphic or otherwise in breach of our 
					<a href={'https://letterboxd.com/legal/terms-of-use/'}>terms</a>. We reserve the right to remove content that
					expressly (or implicitly, such as through coded language, symbols, keywords or tags) praises, supports, promotes
					or represents radical ideologies. We reserve the right to remove any content that disseminates misinformation,
					disinformation or related manipulations, including viral sloganeering.
					<br />
					<br />
					<span>&#8226;</span> If you do not abide by our Terms, we may terminate or suspend your account.
					<br />
					<br />
					<span>&#8226;</span> This policy is a living document: it is constantly updated to reflect new trends and
					evidence. Our moderating team of humans strives to understand evolving behaviour, terms, stereotypes, context and
					forms of expression (including language related to empowerment and counter-speech in marginalized communities)
					<br />
					<br />
				</Card>
			</div>
		);
	}
}

const STYLES = (theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		justifyContent: 'space-between',
		flexDirection: 'column',
		overflow: 'scroll',
		letterSpacing: 0.3,
		textAlign: 'left'
	},
	card: {
		width: 400,
		[theme.breakpoints.down('xs')]: {
			width: '90%'
		},
		margin: '0 auto',
		borderRadius: 0
	}
});

export default withStyles(STYLES)(Policy);
