import React, { Component } from 'react';
// import { Avatar as MIAvatar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Theme from 'resources/Theme';
import Text from './Text';

const Avatar = ({ fullname, style = {}, textStyles = {} }) => {
	if (!fullname) {
		const { user_info } = useSelector((state) => ({
			..._.pick(state.App, ['user_info'])
		}));
		fullname = user_info?.name ?? 'Guest';
	}

	const split = _.split(fullname, ' ');
	const initials = split[0][0].toUpperCase() + (split[1] ? split[1][0].toUpperCase() : '');

	return (
		<div
			style={{
				background: Theme.palette.primary.main,
				width: 40,
				height: 40,
				borderRadius: 20,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				...style
			}}>
			<Text semi style={{ color: 'white', ...textStyles }}>
				{initials}
			</Text>
		</div>
	);
};

export default Avatar;
