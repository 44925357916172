import { combineReducers } from 'redux';
import _ from 'lodash';
import {
	TOGGLE_LOADER,
	ALERT_OPEN,
	ALERT_CLOSE,
	USER_INFO_RECEIVED,
	USER_INFO_UPDATED,
	LOGOUT_USER,
	MEDIA_DETAIL_RECIEVED,
	PROFILE_DETAIL_RECEIVED,
	USER_POSTS_RECEIVED,
	MEDIA_LIST_RECIEVED,
	MEDIA_LIST_RESET,
	SET_SELECTED_MEDIA,
	POST_DELETED,
	PREFERRED_PROVIDERS_UPDATED,
	MEDIA_COMMENTS_RECIEVED,
	GUEST_USER_LOGGED_IN,
	GUEST_USER_LOGGED_OUT
} from 'resources/redux_constants';
import Feed from './feed_reducer';
import Watchlist from './watchlist_reducer';
import Recommendation from './recommendation_reducer';
import Search from './search_reducer';
import LoginModal from './loginModal_reducer';
const initialState = {
	loader: false,
	user_info: {},
	guestUser: true,
	media_detail: {},
	profile_detail: {},
	user_posts: [],
	alert: { open: false, title: '', message: '' },
	secondTab: {
		mediaList: [],
		pageNumber: 0, //Current page needed for pagination on any paginating query
		selectedMedia: null
	}
};

const App = (state = { ...initialState }, action) => {
	switch (action.type) {
		case TOGGLE_LOADER:
			return { ...state, loader: !state.loader };
		case GUEST_USER_LOGGED_IN: {
			return { ...state, guestUser: true };
		}
		case GUEST_USER_LOGGED_OUT: {
			return { ...state, guestUser: false };
		}
		case USER_INFO_RECEIVED: {
			return { ...state, user_info: action.user_info };
		}

		case USER_INFO_UPDATED: {
			return { ...state, user_info: { ...state.user_info, ...action.user_info } };
		}

		case MEDIA_DETAIL_RECIEVED: {
			return { ...state, media_detail: action.media_detail };
		}

		case MEDIA_COMMENTS_RECIEVED: {
			return { ...state, media_comments: action.media_comments };
		}

		case PROFILE_DETAIL_RECEIVED: {
			return { ...state, profile_detail: action.profile_detail };
		}

		case USER_POSTS_RECEIVED: {
			return { ...state, user_posts: action.user_posts };
		}

		case PREFERRED_PROVIDERS_UPDATED: {
			return { ...state, user_info: { ...state.user_info, preferred_providers: action.providers } };
		}

		case POST_DELETED: {
			const posts = JSON.parse(JSON.stringify(state.user_posts));
			const post_index = _.findIndex(posts, { _id: action.post_id });

			posts.splice(post_index, 1);

			return { ...state, user_posts: posts };
		}

		case ALERT_OPEN:
			return { ...state, alert: { open: true, title: action.title, message: action.message } };

		case ALERT_CLOSE:
			return { ...state, alert: { ...state.alert, open: false } };

		case LOGOUT_USER: {
			return { ...initialState, user_info: null };
		}
		case MEDIA_LIST_RECIEVED: {
			return {
				...state,
				secondTab: {
					mediaList: state.secondTab.mediaList.concat(action.mediaList),
					// pageNumber: action.pageNumber,
					selectedMedia: state.secondTab.selectedMedia || action.mediaList[0]
				}
			};
		}
		case SET_SELECTED_MEDIA: {
			return {
				...state,
				secondTab: {
					mediaList: state.secondTab.mediaList,
					pageNumber: state.secondTab.pageNumber,
					selectedMedia: action.selectedMedia
				}
			};
		}
		case MEDIA_LIST_RESET: {
			return {
				...state,
				secondTab: {
					mediaList: [],
					pageNumber: 0,
					selectedMedia: null
				}
			};
		}

		default:
			return state;
	}
};

export default combineReducers({
	App,
	Feed,
	Watchlist,
	Recommendation,
	Search,
	LoginModal
});
