import React, { useEffect } from 'react';
import { CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core';
import Text from 'components/Text';
import IMAGES from 'resources/images';
import _ from 'lodash';
import utils from 'resources/utils';
import { toggle_add_to_watchlist } from 'actions/watchlist_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api_requests from 'resources/api_requests';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './style.scss';
import CircularProgressWithLabel from 'components/Circular_Progress';
import { connect } from 'react-redux';

const MediaCard = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { guestUser, style = {}, media = {}, viewAll } = props;
	const [fav, setFav] = React.useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	useEffect(() => {
		if (media?._source?.[`watch-list`]) {
			setFav(true);
		} else {
			setFav(false);
		}
	}, [media]);
	const { watchlist_movies } = useSelector((state) => ({
		..._.pick(state.Watchlist, ['watchlist_movies'])
	}));

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	const rating = Math.round(_.get(media, '_source.vote_average') * 10) / 10 ?? '';
	const added_to_wishlist = _.findIndex(watchlist_movies, { _id: media._id }) > -1;

	const poster = _.get(media, '_source.poster_path')
		? utils.get_poster_base_url() + _.get(media, '_source.poster_path')
		: IMAGES.default_poster;

	const mediaType = media._type;

	const bookMark = () => {
		api_requests[fav ? 'post_unlink_activity' : 'post_link_activity']({
			e1: {
				_type: 'auth_user',
				_id: utils._retrieveData('id')
			},
			e2: {
				_type: media?._type,
				_id: media?._id
			},
			e1ToE2Relation: media?._type === 'tv-show' ? 'watched-tv-shows' : 'watched-movies'
		});
		setFav(!fav);
	};

	return (
		<div
			style={{
				height: 230,
				flex: '0 0 120px',
				display: 'flex',
				position: 'relative',
				borderRadius: 5,
				width: '100%',
				marginRight: 20
			}}>
			<div
				style={{
					height: 190,
					flex: '0 0 125px',
					position: 'relative',
					borderRadius: 5,
					width: '100%'
				}}>
				<div
					style={{ cursor: 'pointer', height: '100%' }}
					onClick={() => {
						history.push(`/${mediaType === 'movie' ? 'movie' : mediaType}/${media._id}`);
					}}>
					<div
						style={{
							background: `url(${poster})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							borderRadius: 5,
							filter: 'blur(8px)',
							width: '95%',
							height: '100%',
							margin: '0 auto'
						}}
					/>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,

							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}>
						<img
							src={poster}
							style={{
								...(viewAll ? { height: '100%' } : { width: '100%' }),
								borderRadius: 5,
								maxWidth: '100%'
							}}
						/>
					</div>
					{/* <div
					style={{
						
						position: 'absolute',
						zIndex: 101,
						bottom: 0,
						width: '100%',
						paddingTop: 5,
						paddingLeft: 8,
						paddingBottom: 5,
						borderRadius: 5,
						display: 'flex',
						justifyContent: 'space-between'
					}}>
					<div style={{ height: 70, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
						
						<div
							style={{
								display: 'flex',
								marginTop: 10,
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%'
							}}>
							<div style={{ display: 'flex' }}>
								<img src={IMAGES.tmdb} width='30' loading='lazy' />
								
							</div>
						</div>
					</div>
				</div> */}
				</div>
				{rating?.toFixed(1) !== 'NaN' && (
					<div style={{ position: 'absolute', left: 8, bottom: -14 }}>
						<div
							style={{
								display: 'flex',
								position: 'relative',
								borderRadius: 30,
								height: 30,
								width: 30,
								backgroundColor: '#041a1f',
								fontSize: 12,
								color: 'white',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							{/* <div style={{position: 'absolute',
  width: '50%',
  height: '50%',
  borderRadius: '50%',
  backgroundColor:'#ffffff',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  transition: 'all 0.5s ease-out'}}>
		 
		  </div> */}
							{/* <Circular */}
							{/* <div style={{position:'absolute', top:0,left:0,zIndex:1000}}>
		  <CircularProgressWithLabel variant="determinate" value={90} />
		  </div> */}
							{rating?.toFixed(1)}
						</div>
					</div>
				)}
				<div style={{ position: 'absolute', right: 0, bottom: 0 }}>
					<IconButton
						onClick={handleOpenMenu}
						style={{
							width: 9,
							height: 9,
							borderRadius: 16,
							backgroundColor: 'rgba(255,255,255, 0.7)',
							marginRight: 5,
							marginBottom: 5
						}}>
						<img src={IMAGES.menuWhite} width='9' height='9' loading='lazy' style={{ transform: 'rotateZ(90deg)' }} />
					</IconButton>
					<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
						{_.map(
							[
								// {
								// 	name: 'Add to watchlist',
								// 	icon: added_to_wishlist ? IMAGES.wishlist_filled : IMAGES.wishlist,
								// 	onClick: () => {
								// 		dispatch(toggle_add_to_watchlist(media, added_to_wishlist, media._type));
								// 		handleCloseMenu();
								// 	}
								// },
								{ name: 'Watch now', icon: IMAGES.play, onClick: handleCloseMenu },
								{ name: 'Cancel', icon: IMAGES.cancel, onClick: handleCloseMenu }
							],
							(v, i) => {
								return (
									<MenuItem onClick={v.onClick} key={`item${i}`} style={{ minHeight: 35 }}>
										<img src={v.icon} width='14' loading='lazy' />
										<Text style={{ marginLeft: 15 }}>{v.name}</Text>
									</MenuItem>
								);
							}
						)}
					</Menu>
				</div>
			</div>
			{!props.guestUser && (
				<div
					class='ribbon up'
					style={{ color: '#911750', zIndex: 100, position: 'absolute', right: 3 }}
					onClick={() => bookMark()}>
					<div class='content' style={{ backgroundColor: fav ? 'red' : 'rgba(255,255,255, 0.7)' }}></div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { guestUser } = state.App;
	return {
		guestUser
	};
};

export default connect(mapStateToProps, null)(MediaCard);
