import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import Text from './Text';

class TextInput extends Component {
	render() {
		const {
			classes,
			value,
			onChange,
			name,
			label,
			type,
			variant,
			style,
			label_style = {},
			validators = [],
			errorMessages = [],
			...rest
		} = this.props;

		let final_error_messages = [];
		
		_.map(validators, (v, i) => {
			
			if (!_.isEmpty(errorMessages[i])) {
				final_error_messages.push(errorMessages[i]);
			}
			

			switch (v) {
				case 'required':
					final_error_messages.push('This field is required');
					break;
				case 'matchRegexp:^.{8,40}$':{
					final_error_messages.push('Min 8 character');
					break;
				}
				
				default:
					break;
			}
		});

		return (
			<div style={{ ...style }}>
				{label && (
					<Text semi tag='p' style={{ marginBottom: 7, ...label_style }}>
						{label}
					</Text>
				)}

				<TextValidator
					type={type || 'text'}
					InputProps={{
						classes: {
							root: classes.input_container
						}
					}}
					variant={variant || 'outlined'}
					value={value}
					onChange={onChange}
					name={name}
					fullWidth
					minlength={type==="password"??8}
					validators={validators}
					errorMessages={final_error_messages}
					{...rest}
				/>
			</div>
		);
	}
}

const STYLES = (theme) => ({
	input_container: {
		borderRadius: 30,
		borderColor: theme.palette.black
	}
});

export default withStyles(STYLES)(TextInput);
