import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Rating from '@material-ui/lab/Rating';
import { upsert_user_rating } from 'actions/actions';
import IMAGES from 'resources/images';

const labels = {
	1: 'Why did they make it!',
	2: 'Time pass!',
	3: 'One time watch!',
	4: 'Must watch!',
	5: 'How dare you miss this one!'
};
const useStyles = makeStyles({
	iconHover: {
		color: '#DE0418'
	},
	sizeLarge: {
		size: '500px'
	},
	closeButton: {
		bottom: '10px',
		color: 'rgba(27, 28, 18,0.8)',
		zIndex: '2'
	}
});

function RatingDialog (props) {
	const { onClose, selectedValue, open, media_detail, mediaType, mediaId } = props;

	const [value, setValue] = useState(selectedValue);
	const [hover, setHover] = React.useState(selectedValue > 0 ? selectedValue : -2);
	const dispatch = useDispatch();

	const classes = useStyles();

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};

	return (
		<div>
			<Dialog
				fullWidth='true'
				maxWidth='xs'
				border={0}
				borderRadius={16}
				className={classes.root}
				aria-labelledby='simple-dialog-title'
				open={open}
				onClose={handleClose}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<DialogTitle style={{ marginTop: '10px' }} id='simple-dialog-title'>
						<p style={{ letterSpacing: '0.09em', fontSize: '20px', fontWeight: '700' }}>{props.mediaName}?</p>
					</DialogTitle>
					<IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
						<CloseIcon fontSize='medium' />
					</IconButton>
				</div>

				<div style={{ textAlign: 'center' }}>
					<Rating
						name='hover-feedback'
						size='large'
						className={classes.iconHover}
						value={value}
						onClick={(e) => handleListItemClick(e.target.value)}
						style={{ marginBottom: 10, fontSize: '3.6em', padding: '4px' }}
						onChange={(event, newValue) => {
							setValue(newValue);
							dispatch(upsert_user_rating(media_detail, mediaType, mediaId, newValue));
						}}
						onChangeActive={(event, newHover) => {
							setHover(newHover);
						}}
					/>
					<Typography fontWeight='fontWeightBold'>
						{value !== null && (
							<p
								className={classes.iconHover}
								style={{ letterSpacing: '0.09em', fontSize: '14px', margin: '15px auto', fontWeight: '700' }}>
								{labels[hover !== -1 ? hover : value]}
							</p>
						)}
					</Typography>
				</div>
			</Dialog>
		</div>
	);
}

RatingDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedValue: PropTypes.string.isRequired
};

export default function Star_Rating_Toggle (props) {
	const { media_detail, mediaName, mediaId, mediaType, guestUser, handleGuestLoginModal } = props;

	let existingRating = media_detail['user-media-ratings'] && media_detail['user-media-ratings'][0];
	existingRating = _.get(existingRating, 'fields.rating');

	const initialValue = [existingRating || 0];

	const [open, setOpen] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState(initialValue);

	const handleClickOpen = () => {
		if (guestUser) {
			handleGuestLoginModal();
		} else {
			setOpen(true);
		}
	};

	const handleClose = (value) => {
		setOpen(false);
		setSelectedValue(value);
	};

	return (
		<div style={{ marginBottom: '2em' }}>
			<br />
			{console.log(guestUser)}
			<IconButton>
				<img
					src={selectedValue > 0 ? IMAGES.star_filled : IMAGES.star}
					style={{ width: 30, height: 30 }}
					onClick={handleClickOpen}
				/>
			</IconButton>
			<div style={{ textAlign: 'center' }}>
				<p>{selectedValue == 0 ? 'My Rating' : selectedValue}</p>
			</div>
			<RatingDialog
				media_detail={media_detail}
				mediaName={mediaName}
				mediaId={mediaId}
				mediaType={mediaType}
				selectedValue={selectedValue}
				open={open}
				onClose={handleClose}
			/>
		</div>
	);
}
