import React, { Component,useEffect,useState,useStyles } from 'react';
import _ from 'lodash';
import IMAGES from 'resources/images';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const Seasons = ({ seasonsMetaData, style = {}, textStyles = {} }) => {
	
    const [noOfSeasons,setNoOfSeasons] = useState([]);
    const [currentSeason,setCurrentSeason] = useState(0);
    const handleChange = (e) =>{
        
      setCurrentSeason(e.target.value)
    }
    useEffect(() =>{
     
      if(seasonsMetaData?.data){
          if(noOfSeasons.length ===0){
            const data =  seasonsMetaData.data;
            data.shift()
          
            setNoOfSeasons(data)
          }
      }
    },[noOfSeasons]);

	return (
        <>
	{noOfSeasons?.length > 0 ?<div style={{marginTop:'4%'}}>
      <FormControl variant="outlined" style={{border:'1.2px solid red',borderRadius:18,marginLeft:'4%'}} >
        <Select
          labelId="demo-simple-select-error-label"
          id="demo-simple-select-error-label"
          value={currentSeason}
          onChange={handleChange}
          style={{borderRadius:16,color:'red',borderWidth:2,height:32,}}
        >
          {noOfSeasons.length > 0 && noOfSeasons.map((val,index) =>
           <MenuItem value={index}>Season {index+1}</MenuItem>)}
        </Select>
      </FormControl>
      {
       <ul style={{marginTop:'4%',listStyleType:'none',height:300,overflow:'scroll'}} >
           {noOfSeasons[currentSeason]?.fields?.episodes?.map((val,index) =>{
            {
            return(
            <li index={index} style={{height:60,backgroundColor:'#f6f6f6',paddingLeft:'4%',marginBottom:4}}>
                <div style={{flexDirection:'row',display:'flex',height:'100%'}}>
                    <div style={{display:'flex',alignItems:'center'}}><img src={IMAGES.play}/></div>
                
                <div style={{display:'flex',flexDirection:'column',paddingLeft:18,justifyContent:'center'}}>
                    <span style={{letterSpacing:1,fontSize:14}}>
                        Episode {index+1}
                    </span>
                    <span style={{color:'grey',fontSize:12,paddingTop:2}}>
                    {val?.fields?.name}
                    </span>
                </div>
                </div>
                
                </li>)}
           })}
        </ul> }
          

    </div>:<></>}
    </>
	);
};

export default Seasons;
