import React from 'react';
import _ from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Text from 'components/Text';
import MediaCard from './MediaCard';

const SingleItemList = (props) => {
	const { title, list, viewAll, setViewAll, index, total, loading } = props;
	const hasMorePosts = list?.length !== total;

	const [sentryRef] = useInfiniteScroll({
		loading: !hasMorePosts ? false : loading,
		hasNextPage: hasMorePosts,
		onLoadMore: () => {
			props.getMoreData(list.length);
		},
		disabled: !hasMorePosts,
		rootMargin: '0px 0px 40px 0px'
	});

	return (
		<div style={{ marginTop: 10 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Text bold style={{ color: '#4D536F' }}>
					{title}
				</Text>
				<Text semi style={{ color: '#DE0418', cursor: 'pointer' }} onClick={() => setViewAll(null)}>
					Clear
				</Text>
			</div>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					marginTop: 10
				}}>
				{_.map(list, (v, i) => {
					return (
						<MediaCard
							key={`item${i}`}
							media={v}
							viewAll={viewAll}
							style={{
								marginTop: 10,
								flex: '0 0 45%',
								height: 220
							}}
						/>
					);
				})}
			</div>
			<div ref={sentryRef} className='feed-loader'>
				{hasMorePosts ? <span>Loading...</span> : <span>No More Posts</span>}
			</div>
		</div>
	);
};

export default SingleItemList;
