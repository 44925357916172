import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import Text from 'components/Text';
import IMAGES from 'resources/images';
import _ from 'lodash';
import utils from 'resources/utils';
import { toggle_add_to_watchlist } from 'actions/watchlist_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const ItemCard = ({ style = {}, media = {}, viewAll }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const dispatch = useDispatch();
	const history = useHistory();

	const { watchlist_movies } = useSelector((state) => ({
		..._.pick(state.Watchlist, ['watchlist_movies'])
	}));

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const added_to_wishlist = _.findIndex(watchlist_movies, { _id: media._id }) > -1;

	const poster = _.get(media, '_source.poster_path')
		? utils.get_poster_base_url() + _.get(media, '_source.poster_path')
		: IMAGES.default_poster;

	const mediaType = media._type;

	return (
		<div
			style={{
				height: 156,
				flex: '0 0 120px',
				position: 'relative',
				borderRadius: 1,
				width: '90%',
				marginLeft:10,
				...style
			}}>
			<div
				style={{ cursor: 'pointer', height: '100%' }}
				onClick={() => history.push(`/${mediaType === 'movie' ? 'movie' : mediaType}/${media._id}`)}>
				<div
					style={{
						background: `url(${poster})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						borderRadius: 1,
						filter: 'blur(8px)',
						width: '90%',
						height: '100%',
						margin: '0 auto'
					}}
				/>
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 0,
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center'
					}}>
					<img
						src={poster}
						style={{
							...(viewAll ? { height: '100%' } : { width: '100%' }),
							borderRadius: 2,
							maxWidth: '100%'
						}}
					/>
				</div>
				
			</div>
			
		</div>
	);
};

export default ItemCard;
