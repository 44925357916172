import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Theme from 'resources/Theme';

class ContainedButton extends Component {
	render() {
		const { classes, container = '', label = '', color = 'primary', style, children, href, ...rest } = this.props;

		let custom_color = null;

		switch (color) {
			case 'primary':
				break;
			case 'black':
				custom_color = Theme.palette.black;
				break;
		}

		return (
			<Button
				variant='contained'
				{...((color === 'default' || color === 'primary' || color === 'secondary') && { color })}
				style={{ ...(custom_color && { backgroundColor: custom_color }), ...style }}
				classes={{
					root: `${classes.container} ${container}`,
					label: `${classes.label} ${label}`
				}}
				{...(href && { component: Link, to: href })}
				{...rest}
				fullWidth>
				{children}
			</Button>
		);
	}
}

const STYLES = (theme) => ({
	container: {
		width: 100,
		height: 40,
		borderRadius: 100,
		color: Theme.palette.white
	},
	label: {
		fontSize: 14,
		fontWeight: 'bold',
		letterSpacing: 0
	}
});

export default withStyles(STYLES)(ContainedButton);
