import Text from 'components/Text';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IMAGES from 'resources/images';
import utils from 'resources/utils';
import api_requests from 'resources/api_requests';
import { remove_item_from_watchlist } from 'actions/watchlist_actions';
import { get_user_details } from 'actions/actions';
import PageTracking from 'components/pageViewTracker';

class Watchlist extends Component {
	componentDidMount () {
		this.props.get_user_details();
		PageTracking(this.props);
	}

	render () {
		const { watchlist_movies } = this.props;

		if (_.isEmpty(watchlist_movies)) {
			return null;
		}

		const unLink = (mediaType, mediaId) => {
			console.log(mediaType, mediaId);
			api_requests['post_unlink_activity']({
				e1: {
					_type: 'auth_user',
					_id: utils._retrieveData('id')
				},
				e2: {
					_type: mediaType,
					_id: mediaId
				},
				e1ToE2Relation: mediaType === 'tv-show' ? 'watched-tv-shows' : 'watched-movies'
			});
			this.props.remove_item_from_watchlist(mediaId);
		};
		return (
			<div style={{ background: 'white', minHeight: '100%' }}>
				{console.log(this.props.watchlist_movies)}
				<div style={{ width: '90%', margin: '0 auto', paddingTop: 20, paddingBottom: 20 }}>
					{_.map(watchlist_movies, (v, i) => {
						let genre = '';

						let movie = v._source || v.fields;

						if (!movie) {
							return null;
						}

						_.map(movie.genres, (v2, i2) => {
							if (i2 !== 0) {
								genre += '/';
							}
							genre += v2.fields.name;
						});

						return (
							<div
								key={`index${i}`}
								style={{
									background: '#F9F9F9',
									borderRadius: 10,
									width: '100%',
									height: 150,
									position: 'relative',
									...(i !== 0 && { marginTop: 30 }),
									display: 'flex',
									cursor: 'pointer'
								}}>
								<div
									style={{
										height: '100%',
										width: 120,
										borderRadius: 10,
										background: `url(${utils.get_poster_base_url() + _.get(movie, 'poster_path')}) no-repeat`,
										backgroundSize: 'contain'
									}}
									onClick={() => this.props.history.push(`/movie/${v._id}`)}></div>
								<div
									style={{
										marginTop: 10,
										marginBottom: 10,
										width: '60%',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between'
									}}
									onClick={() => this.props.history.push(`/movie/${v._id}`)}>
									<Text bold style={{ fontSize: 20, color: '#323232' }}>
										{_.get(movie, 'original_title') || _.get(movie, 'title')}
									</Text>
									<div>
										<Text component='p'>Rating - {_.get(movie, 'vote_average')} </Text>
										<Text>{genre} </Text>
										<hr style={{ marginTop: 5 }} />
										<Text>
											{movie.runtime && `${Math.floor(movie.runtime / 60)}h ${movie.runtime % 60}m  ‧ `}U/A
										</Text>
									</div>
								</div>
								<div style={{ position: 'relative', top: 0, right: '-3%', zIndex: 100, width: 50 }}>
									<img
										src={IMAGES.wishlist_filled}
										style={{ position: 'absolute', top: 0, zIndex: 100 }}
										loading='lazy'
										onClick={() => unLink(v._type, v._id)}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Watchlist, ['watchlist_movies'])
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, { remove_item_from_watchlist, get_user_details })(Watchlist);
